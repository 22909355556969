import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// Context
import {AuthContext} from './context/AuthContext';

// APIs
import {getUserByToken} from '../../pages/auth/_api/auth';

// Components
import SplashScreen from '../_components/SplashScreen';

interface AuthInitProps {
  children: React.ReactNode;
}

const AuthInit = ({children}: AuthInitProps) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // Context
  const {
    auth,
    logout,
    setAuthUser,
    setRoutes,
    setWebAccess,
    setNotificationsCount,
  } = useContext(AuthContext);

  // Refs
  const didRequest = useRef(false);

  const requestUser = useCallback(async (apiToken: string) => {
    try {
      if (!didRequest.current) {
        const {status, data} = await getUserByToken(apiToken);
        if (status === 200) {
          setAuthUser(data.data.user);
          setWebAccess(data.data.webAccess);
          setRoutes(data.data.routes);
          setNotificationsCount(data.data.notificationsCount);
        }
      }
    } catch (error) {
      if (!didRequest.current) {
        logout();
        window.location.href = '/';
      }
    } finally {
      setShowSplashScreen(false);
    }

    return () => (didRequest.current = true);
  }, [logout, setAuthUser, setWebAccess, setRoutes, setNotificationsCount]);

  useEffect(() => {
    if (auth && auth.apiToken) {
      requestUser(auth.apiToken);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, [auth, logout, requestUser]);

  if (showSplashScreen) {
    return <SplashScreen />;
  }

  return (
    <div>
      {children}
    </div>
  );
};

export default AuthInit;
