import {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {useDraggable} from '@dnd-kit/core';
import {Form, Formik, FormikErrors} from 'formik';
import {isAxiosError} from 'axios';
import {enqueueSnackbar} from 'notistack';
import dayjs from 'dayjs';

// Types
import {RawMaterialFormProps, RawMaterialProps} from '../../../../../../models/Product';
import {CurrencyProps} from '../../../../../../models/Currency';

// Context
import {HomeContext} from '../../_context/HomeContext';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import SwapVertIcon from '@mui/icons-material/SwapVert';

// APIs
import {
  submitRawMaterialForm,
  getRawMaterials,
} from '../../../../_api/mobile/home/costings/costings';

// Utils
import {searchInputCheck} from '../../../../../../utils/utils';

interface RawMaterialsComponentProps {
  isAdmin: boolean;
  rawMaterials: RawMaterialProps[];
  setRawMaterials: React.Dispatch<React.SetStateAction<RawMaterialProps[]>>;
}

type FilterTypeUnitCost = 'standard' | 'between';
type SortType = 'ascending' | 'descending';

const RawMaterials = ({
  isAdmin,
  rawMaterials,
  setRawMaterials,
}: RawMaterialsComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [arrayCount, setArrayCount] = useState<number>(20);
  const [openRawMaterialFormDialog, setOpenRawMaterialFormDialog] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<CurrencyProps[]>([]);
  const [openFilterUnitCost, setOpenFilterUnitCost] = useState<boolean>(false);
  const [filterTypeUnitCost, setFilterTypeUnitCost] = useState<FilterTypeUnitCost>('standard');
  const [filterValuesUnitCost, setFilterValuesUnitCost] = useState<number[]>([]);
  const [filterSelectionUnitCost, setFilterSelectionUnitCost] = useState<number[]>([]);
  const [filterSelectionSearchUnitCost, setFilterSelectionSearchUnitCost] = useState<string>('');
  const [openFilterCurrency, setOpenFilterCurrency] = useState<boolean>(false);
  const [filterValuesCurrency, setFilterValuesCurrency] = useState<string[]>([]);
  const [filterSelectionSearchCurrency, setFilterSelectionSearchCurrency] = useState<string>('');
  const [openFilterSuppliers, setOpenFilterSuppliers] = useState<boolean>(false);
  const [filterValuesSuppliers, setFilterValuesSuppliers] = useState<string[]>([]);
  const [filterSelectionSuppliers, setFilterSelectionSuppliers] = useState<string[]>([]);
  const [filterSelectionSearchSuppliers, setFilterSelectionSearchSuppliers] = useState<string>('');
  const [openFilterKanban, setOpenFilterKanban] = useState<boolean>(false);
  const [filterValuesKanban, setFilterValuesKanban] = useState<number[]>([]);
  const [sortKey, setSortKey] = useState<keyof RawMaterialProps>();
  const [sortType, setSortType] = useState<SortType>();
  const [swapName, setSwapName] = useState<boolean>(false);
  const [selectedRawMaterialId, setSelectedRawMaterialId] = useState<number>();

  const selectedRawMaterial = selectedRawMaterialId
    ? rawMaterials.find(
        indivRawMaterial => indivRawMaterial.id === selectedRawMaterialId,
      )
    : undefined;

  // Variables
  const initialValues: RawMaterialFormProps = {
    id: selectedRawMaterial?.id || 0,
    code: selectedRawMaterial?.code || '',
    name: selectedRawMaterial?.name || '',
    cost: selectedRawMaterial?.cost || '0.00' as unknown as number,
    rate: selectedRawMaterial?.rate || '1.00' as unknown as number,
    currencyId: selectedRawMaterial?.currency?.id || 1,
    fxNetPrice: selectedRawMaterial?.fxNetPrice || '0.00' as unknown as number,
    fxFreight: selectedRawMaterial?.fxFreight || '0.00' as unknown as number,
    supplier: selectedRawMaterial?.supplier || selectedRawMaterial?.materialUsageSupplier || '',
    isLocalData: selectedRawMaterial ? false : true,
    materialUsageSupplierCode: selectedRawMaterial?.materialUsageSupplierCode || '',
    isCostComputed: selectedRawMaterial?.isCostComputed || false,
    materialUsageTriggerQty: selectedRawMaterial?.materialUsageTriggerQty || '',
    materialUsageOrderQty: selectedRawMaterial?.materialUsageOrderQty || '',
    materialUsageLeadTime: selectedRawMaterial?.materialUsageLeadTime || 0,
    materialUsageLeadTimeUnit: selectedRawMaterial?.materialUsageLeadTimeUnit || 'days',
    materialUsageKanban: Boolean(selectedRawMaterial?.materialUsageKanban) || false,
  };
  const leadTimeUnitSelection = [
    {value: 'days', label: 'day/s'},
    {value: 'weeks', label: 'week/s'},
  ];

  // Context
  const {updateCount} = useContext(HomeContext);

  // Refs
  const searchInputRef = useRef<HTMLDivElement>(null);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getRawMaterials();

      if (status === 200) {
        const unitCostCache: number[] = [];
        const suppliersCache: string[] = [];

        data.data.rawMaterials.forEach(indivRawMaterial => {
          if (!unitCostCache.includes(indivRawMaterial.cost)) {
            unitCostCache.push(indivRawMaterial.cost);
          } else if (
            indivRawMaterial.supplier &&
            !suppliersCache.includes(indivRawMaterial.supplier)
          ) {
            suppliersCache.push(indivRawMaterial.supplier);
          }
        });

        unitCostCache.sort((currentUnitCost, nextUnitCost) => {
          return currentUnitCost - nextUnitCost;
        });
        setFilterSelectionUnitCost(unitCostCache);
        
        suppliersCache.sort((currentSuppliers, nextSuppliers) => {
          return currentSuppliers.localeCompare(nextSuppliers);
        });
        suppliersCache.unshift('Blank');
        setFilterSelectionSuppliers(suppliersCache);

        setRawMaterials(data.data.rawMaterials);
        setCurrencies(data.data.currencies);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [setRawMaterials]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
    searchInputRef.current?.focus();
  };

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  const handleToggleFilterUnitCost = useCallback(() => {
    setOpenFilterUnitCost(currentValue => !currentValue);
    setFilterSelectionSearchUnitCost('');
  }, []);

  const handleToggleFilterCurrency = useCallback(() => {
    setOpenFilterCurrency(currentValue => !currentValue);
    setFilterSelectionSearchCurrency('');
  }, []);
  
  const handleToggleFilterSuppliers = useCallback(() => {
    setOpenFilterSuppliers(currentValue => !currentValue);
    setFilterSelectionSearchSuppliers('');
  }, []);
  
  const handleToggleFilterKanban = useCallback(() => {
    setOpenFilterKanban(currentValue => !currentValue);
  }, []);

  const handleResetFilterUnitCost = useCallback(() => {
    if (filterTypeUnitCost === 'between') {
      setFilterValuesUnitCost([0, filterSelectionUnitCost[filterSelectionUnitCost.length - 1]]);
    } else {
      setFilterValuesUnitCost([]);
    }
  }, [filterTypeUnitCost, filterSelectionUnitCost]);

  const handleResetFilterCurrency = useCallback(() => {
    setFilterValuesCurrency([]);
    setFilterSelectionSearchCurrency('');
  }, []);

  const handleResetFilterKanban = useCallback(() => {
    setFilterValuesKanban([]);
  }, []);

  const handleResetFilterSuppliers = useCallback(() => {
    setFilterValuesSuppliers([]);
    setFilterSelectionSearchSuppliers('');
  }, []);

  const handleClickSort = useCallback((key: keyof RawMaterialProps) => {
    if (sortKey === key) {
      if (sortType !== 'descending') {
        setSortKey(key);
      }

      switch (sortType) {
        case undefined: {
          setSortType('ascending');
          break;
        }
        case 'ascending': {
          setSortType('descending');
          break;
        }
        default: {
          setSortType(undefined);
          setSortKey(undefined);
          break;
        }
      }
    } else {
      setSortKey(key);
      setSortType('ascending');
    }
  }, [sortType, sortKey]);

  const handleSwapNameToggle = useCallback(() => {
    setSwapName(currentValue => !currentValue);
  }, []);

  const renderSelectCurrencyValue = useCallback((value: number) => {
    const currency = currencies.find(indivCurrency => indivCurrency.id === value);
    return currency?.name;
  }, [currencies]);

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [updateCount, refreshHandler]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (arrayCount < rawMaterials.length) {
          setArrayCount(arrayCount + 10);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [arrayCount, rawMaterials]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <Fragment>
      <div className="p-2 md:p-5">
        <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Raw Materials
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>

          <div className="flex flex-row gap-3 md:min-w-[500px]">
            <TextField
              autoCorrect="off"
              autoComplete="off"
              ref={searchInputRef}
              value={searchInput}
              onChange={searchInputChangeHandler}
              variant="standard"
              placeholder="Search raw material"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      type="button"
                      onClick={searchInputIconClickHandler}>
                      {searchInput ? (
                        <CancelIcon color="error" />
                      ) : (
                        <SearchIcon />
                      )}
                    </button>
                  </InputAdornment>
                ),
              }}
            />
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenRawMaterialFormDialog(true);
                  setSelectedRawMaterialId(undefined);
                }}>
                Add
              </Button>
            )}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={250}>
                  <div className="flex flex-row gap-1 items-center">
                    <IconButton
                      size="small"
                      color={swapName ? 'primary' : 'inherit'}
                      onClick={handleSwapNameToggle}>
                      
                      <SwapVertIcon
                        color={swapName ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>
                    
                    <Typography fontSize="inherit" fontWeight="inherit">
                      {swapName ? 'Name & Code' : 'Code & Name'}
                    </Typography>

                    <IconButton
                      size="small"
                      color={sortKey === 'name' ? 'primary' : 'inherit'}
                      onClick={() => {
                        handleClickSort('name');
                      }}>
                      
                      {sortKey === 'name' ? (
                        sortType === 'ascending' ? (
                          <ArrowDropUpIcon color="primary" fontSize="inherit" />
                        ) : (
                          <ArrowDropDownIcon color="primary" fontSize="inherit" />
                        )
                      ) : (
                        <SortIcon
                          color="inherit"
                          fontSize="inherit"
                        />
                      )}
                    </IconButton>

                    
                  </div>
                </TableCell>
                <TableCell width={175}>
                  <div className="flex flex-row gap-1 items-center">
                    <Typography fontSize="inherit" fontWeight="inherit">
                      Unit Cost
                    </Typography>

                    <IconButton
                      size="small"
                      color={filterValuesUnitCost.length ? 'primary' : 'inherit'}
                      onClick={handleToggleFilterUnitCost}>
                      <FilterListIcon
                        color={filterValuesUnitCost.length ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>

                    <IconButton
                      size="small"
                      color={sortKey === 'cost' ? 'primary' : 'inherit'}
                      onClick={() => {
                        handleClickSort('cost');
                      }}>
                      {sortKey === 'cost' ? (
                        sortType === 'ascending' ? (
                          <ArrowDropUpIcon color="primary" fontSize="inherit" />
                        ) : (
                          <ArrowDropDownIcon color="primary" fontSize="inherit" />
                        )
                      ) : <SortIcon color="inherit"  fontSize="inherit" />}
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell width={100}>
                  <div className="flex flex-row gap-1 items-center">
                    <Typography fontSize="inherit" fontWeight="inherit">
                      Currency
                    </Typography>

                    <IconButton
                      size="small"
                      color={filterValuesCurrency.length ? 'primary' : 'inherit'}
                      onClick={handleToggleFilterCurrency}>
                      <FilterListIcon
                        color={filterValuesCurrency.length ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell width={100}>
                  Rate
                </TableCell>
                <TableCell width={125}>
                  FX Net Price
                </TableCell>
                <TableCell width={125}>
                  FX Freight
                </TableCell>
                <TableCell width={200}>
                  Qty
                </TableCell>
                <TableCell width={250}>
                  <div className="flex flex-row gap-1 items-center">
                    <Typography fontSize="inherit" fontWeight="inherit">
                      Supplier
                    </Typography>

                    <IconButton
                      size="small"
                      color={filterValuesSuppliers.length ? 'primary' : 'inherit'}
                      onClick={handleToggleFilterSuppliers}>
                      <FilterListIcon
                        color={filterValuesSuppliers.length ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>

                    <IconButton
                      size="small"
                      color={sortKey === 'supplier' ? 'primary' : 'inherit'}
                      onClick={() => {
                        handleClickSort('supplier');
                      }}>

                      {sortKey === 'supplier' ? (
                        sortType === 'ascending' ? (
                          <ArrowDropUpIcon color="primary" fontSize="inherit" />
                        ) : (
                          <ArrowDropDownIcon color="primary" fontSize="inherit" />
                        )
                      ) : <SortIcon color="inherit" fontSize="inherit" />}
                    </IconButton>

                    
                  </div>
                </TableCell>
                <TableCell>
                  Lead Time
                </TableCell>
                <TableCell>
                  <div className="flex flex-row gap-1 items-center">
                    <Typography fontSize="inherit" fontWeight="inherit">
                      Kanban
                    </Typography>

                    <IconButton
                      size="small"
                      color={filterValuesKanban.length ? 'primary' : 'inherit'}
                      onClick={handleToggleFilterKanban}>
                      <FilterListIcon
                        color={filterValuesKanban.length ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell>
                  Last Updated
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rawMaterials.length ? rawMaterials
                .sort((currentRawMaterial, nextRawMaterial) => {
                  switch (sortKey) {
                    case 'name': {
                      if (swapName) {
                        switch (sortType) {
                          case 'ascending': {
                            return currentRawMaterial.name.localeCompare(nextRawMaterial.name);
                          }
                          case 'descending': {
                            return nextRawMaterial.name.localeCompare(currentRawMaterial.name);
                          }
                          default: {
                            return 0;
                          }
                        }
                      } else {
                        switch (sortType) {
                          case 'ascending': {
                            return currentRawMaterial.code.localeCompare(nextRawMaterial.code);
                          }
                          case 'descending': {
                            return nextRawMaterial.code.localeCompare(currentRawMaterial.code);
                          }
                          default: {
                            return 0;
                          }
                        }
                      }
                    }

                    case 'cost': {
                      switch (sortType) {
                        case 'ascending': {
                          return currentRawMaterial.cost - nextRawMaterial.cost;
                        }
                        case 'descending': {
                          return nextRawMaterial.cost - currentRawMaterial.cost;
                        }
                        default: {
                          return 0;
                        }
                      }
                    }

                    case 'supplier': {
                      let currentSupplier = currentRawMaterial.supplier;
                      if (!currentSupplier) {
                        currentSupplier = currentRawMaterial.materialUsageSupplier;
                      }
                      let nextSupplier = nextRawMaterial.supplier;
                      if (!nextSupplier) {
                        nextSupplier = nextRawMaterial.materialUsageSupplier;
                      }

                      switch (sortType) {
                        case 'ascending': {
                          return currentSupplier?.localeCompare(nextSupplier ?? '') ?? 0;
                        }
                        case 'descending': {
                          return nextSupplier?.localeCompare(currentSupplier ?? '') ?? 0;
                        }
                        default: {
                          return 0;
                        }
                      }
                    }


                    default: {
                      return currentRawMaterial.id - nextRawMaterial.id;
                    }
                  }
                })
                .filter(indivRawMaterial => {
                  if (searchInput) {
                    if (
                      searchInputCheck(searchInput, indivRawMaterial.name) ||
                      searchInputCheck(searchInput, indivRawMaterial.code)
                    ) {
                      return indivRawMaterial;
                    }
                  } else {
                    return indivRawMaterial;
                  }

                  return null;
                })
                .filter(indivRawMaterial => {
                  if (!filterValuesUnitCost.length) {
                    return indivRawMaterial;
                  }

                  if (filterTypeUnitCost === 'between') {
                    if (indivRawMaterial.cost >= filterValuesUnitCost[0] && indivRawMaterial.cost <= filterValuesUnitCost[1]) {
                      return indivRawMaterial;
                    }
                  } else {
                    if (filterValuesUnitCost.includes(indivRawMaterial.cost)) {
                      return indivRawMaterial;
                    }
                  }

                  return null;
                })
                .filter(indivRawMaterial => {
                  if (!filterValuesCurrency.length) {
                    return indivRawMaterial;
                  }
                  
                  if (filterValuesCurrency.includes(indivRawMaterial.currency?.name!)) {
                    return indivRawMaterial;
                  }

                  return null;
                })
                .filter(indivRawMaterial => {
                  if (!filterValuesSuppliers.length) {
                    return indivRawMaterial;
                  }

                  let supplier = indivRawMaterial.supplier;
                  if (!supplier) {
                    supplier = indivRawMaterial.materialUsageSupplier;
                  }

                  if (supplier) {
                    if (filterValuesSuppliers.includes(supplier)) {
                      return indivRawMaterial;
                    }
                  } else {
                    if (filterValuesSuppliers.includes('Blank')) {
                      return indivRawMaterial;
                    }
                  }

                  return null;
                })
                .filter(indivRawMaterial => {
                  if (!filterValuesKanban.length) {
                    return indivRawMaterial;
                  }

                  const kanbanValue = Boolean(indivRawMaterial.materialUsageKanban) ? 1 : 0;

                  if (filterValuesKanban.includes(kanbanValue)) {
                    return indivRawMaterial;
                  }

                  return null;
                })
                .map((indivRawMaterial, indivRawMaterialIndex) => {
                  if (indivRawMaterialIndex > arrayCount) {
                    return null;
                  }

                  const lastUpdate = indivRawMaterial.rawMaterialUpdates[0];

                  const lastUpdatedAtDateTimeString =
                    lastUpdate
                      ? dayjs(lastUpdate.date).format('ddd. MMM. DD, YYYY \n@ hh:mm A')
                      : undefined;

                  let currency: string | undefined = '';
                  if (indivRawMaterial.currency?.id !== 1) {
                    currency = indivRawMaterial.currency?.name;
                  }

                  return (
                    <DraggableComponent
                      key={indivRawMaterial.id}
                      rawMaterialId={indivRawMaterial.id!}
                      disabled={!isAdmin}
                      handleClick={() => {
                        setSelectedRawMaterialId(indivRawMaterial.id);
                        setOpenRawMaterialFormDialog(true);
                      }}>
                      <TableCell className="!align-top">
                        <Typography fontSize="inherit" fontWeight="bold">
                          {swapName ? indivRawMaterial.name : indivRawMaterial.code}
                        </Typography>
                        <Typography variant="body2">
                          {swapName ? indivRawMaterial.code : indivRawMaterial.name}
                        </Typography>
                      </TableCell>
                      <TableCell className="!align-top">
                        $
                        {' '}
                        {parseFloat(indivRawMaterial.cost.toString()).toFixed(2)}
                      </TableCell>
                      <TableCell className="!align-top">
                        {currency}
                      </TableCell>
                      <TableCell className="!align-top">
                        $
                        {' '}
                        {parseFloat(indivRawMaterial.rate.toString()).toFixed(2)}
                      </TableCell>
                      <TableCell className="!align-top">
                        {Boolean(indivRawMaterial.fxNetPrice) && `$ ${
                          indivRawMaterial.fxNetPrice.toFixed(2)
                        }`}
                      </TableCell>
                      <TableCell className="!align-top">
                        {Boolean(indivRawMaterial.fxFreight) && `$ ${
                          indivRawMaterial.fxFreight.toFixed(2)
                        }`}
                      </TableCell>
                      <TableCell className="!align-top">
                        {Boolean(indivRawMaterial.materialUsageTriggerQty) && (
                          <Typography fontSize="inherit">
                            Trigger: {indivRawMaterial.materialUsageTriggerQty || '-'}
                          </Typography>
                        )}
                        {Boolean(indivRawMaterial.materialUsageOrderQty) && (
                          <Typography fontSize="inherit">
                            Order: {indivRawMaterial.materialUsageOrderQty || '-'}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell className="!align-top">
                        <Typography fontSize="inherit">
                          {indivRawMaterial.supplier || indivRawMaterial.materialUsageSupplier}
                        </Typography>
                        {Boolean(indivRawMaterial.materialUsageSupplierCode) && (
                          <Typography variant="caption">
                            Supplier Code: {indivRawMaterial.materialUsageSupplierCode}
                          </Typography>
                        )}
                        
                      </TableCell>
                      <TableCell className="!align-top">
                        {Boolean(
                          indivRawMaterial.materialUsageLeadTime
                        ) &&
                          `${
                            indivRawMaterial.materialUsageLeadTime
                          } ${
                            indivRawMaterial.materialUsageLeadTimeUnit?.replace(/.$/, '/s')
                          }`}
                      </TableCell>
                      <TableCell className="!align-top">
                        {indivRawMaterial.materialUsageKanban ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell className="!align-top">
                        {Boolean(lastUpdate) && (
                          <Fragment>
                            <Typography
                              className="whitespace-pre-wrap"
                              variant="body2"
                              fontSize="small"
                              fontWeight="bold">
                              {lastUpdatedAtDateTimeString}
                            </Typography>
                            <Typography variant="caption">
                              {lastUpdate.user.firstname} {lastUpdate.user.lastname}
                            </Typography>
                          </Fragment>
                        )}
                      </TableCell>
                    </DraggableComponent>
                  );
                }) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Typography className="text-gray-500" variant="caption">
                        Nothing to display
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {isAdmin && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={values => {
            const errors: FormikErrors<RawMaterialFormProps> = {};
            
            if (!values.code) {
              errors.code = 'Code is required';
            }

            if (!values.name) {
              errors.name = 'Name is required';
            }

            if (values.cost < 0 || values.cost.toString() === '') {
              errors.cost = 'Invalid cost';
            }

            if (values.rate < 0 || values.rate.toString() === '') {
              errors.rate = 'Invalid rate';
            }

            if (values.fxNetPrice < 0 || values.fxNetPrice.toString() === '') {
              errors.fxNetPrice = 'Invalid price';
            }

            if (values.fxFreight < 0 || values.fxFreight.toString() === '') {
              errors.fxFreight = 'Invalid value';
            }

            return errors;
          }}
          onSubmit={async (values, {resetForm, setErrors, setSubmitting}) => {
            setSubmitting(true);
            try {
              const {status, data} = await submitRawMaterialForm(values);
              const newRawMaterials = [...rawMaterials];

              switch (status) {
                case 201: {
                  newRawMaterials.push(data.data.rawMaterial);
                  resetForm();
                  setOpenRawMaterialFormDialog(false);
  
                  enqueueSnackbar(
                    'Raw material created',
                    {
                      autoHideDuration: 3000,
                      variant: 'success',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                  setSearchInput(data.data.rawMaterial.code);
                  break;
                }

                case 200: {
                  const rawMaterialIndex = newRawMaterials.findIndex(indivRawMaterial => {
                    return indivRawMaterial.id === data.data.rawMaterial.id;
                  });
                  newRawMaterials.splice(rawMaterialIndex, 1, data.data.rawMaterial);
                  break;
                }
              }
              setRawMaterials(newRawMaterials);
              setOpenRawMaterialFormDialog(false);
              enqueueSnackbar(
                data.message ?? 'Success.',
                {
                  autoHideDuration: 3000,
                  variant: 'success',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            } catch (err) {
              let message = 'Something went wrong, please contact your administrator.'
              if (isAxiosError(err)) {
                const response = err.response;
                if (response) {
                  message = response.data.message;
                  const data = response.data.data;
                  switch (response.status) {
                    case 400: {
                      setErrors({code: data.code[0]});
                    }
                  }
                }
              }
        
              enqueueSnackbar(
                message,
                {
                  autoHideDuration: 3000,
                  variant: 'error',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            } finally {
              setSubmitting(false);
            }
          }}>
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <Dialog
                fullWidth
                disableScrollLock={true}
                onClose={() => {
                  setOpenRawMaterialFormDialog(false);
                  setSelectedRawMaterialId(undefined);
                  handleReset();
                }}
                open={openRawMaterialFormDialog}>
                <DialogTitle>{selectedRawMaterialId ? 'Update' : 'Create'} raw material</DialogTitle>
                <DialogContent>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-3">
                      <TextField
                        autoComplete="off"
                        variant="standard"
                        value={values.code}
                        name="code"
                        label="Code"
                        className="flex-1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.code && errors.code)}
                        helperText={touched.code && errors.code}
                      />

                      <TextField
                        disabled={Boolean(values.isCostComputed)}
                        type="number"
                        variant="standard"
                        value={values.cost}
                        name="cost"
                        label={`Cost${values.isCostComputed ? ' (Auto-calculate)' : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.cost && errors.cost)}
                        helperText={touched.cost && errors.cost}
                        InputProps={{
                          startAdornment: <span className="pr-1">$</span>,
                        }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      />
                    </div>
                    
                    <TextField
                      variant="standard"
                      value={values.name}
                      name="name"
                      label="Name"
                      className="flex-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />

                    <div className="flex flex-row gap-3 mt-3">
                      <FormControl
                        sx={{minWidth: 100}}
                        error={Boolean(touched.currencyId && errors.currency)}>
                        <InputLabel>Currency</InputLabel>
                        <Select
                          renderValue={renderSelectCurrencyValue}
                          label="Currency"
                          type="number"
                          name="currencyId"
                          value={values.currencyId}
                          onChange={handleChange}>
                          {currencies.map(indivCurrency => {
                            return (
                              <MenuItem key={indivCurrency.id} value={indivCurrency.id}>
                                {indivCurrency.name} - {indivCurrency.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched.currencyId && errors.currency && (
                          <FormHelperText>{errors.currency}</FormHelperText>
                        )}
                      </FormControl>

                      <TextField
                        type="number"
                        variant="standard"
                        value={values.rate}
                        name="rate"
                        label="Rate"
                        onChange={e => {
                          const rate = parseFloat(e.target.value);
                          if (values.isCostComputed) {
                            const fxNetPrice = parseFloat(values.fxNetPrice.toString());
                            const fxFreight = parseFloat(values.fxFreight.toString());
                            const cost = (fxNetPrice + fxFreight) * rate;
                            setFieldValue('cost', cost.toFixed(2));
                          }
                          setFieldValue('rate', rate);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.rate && errors.rate)}
                        helperText={touched.rate && errors.rate}
                        InputProps={{
                          startAdornment: <span className="pr-1">$</span>,
                        }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      />

                      <TextField
                        type="number"
                        variant="standard"
                        value={values.fxNetPrice}
                        name="fxNetPrice"
                        label="FX Net Price"
                        onChange={e => {
                          const fxNetPrice = parseFloat(e.target.value);
                          if (values.isCostComputed) {
                            const rate = parseFloat(values.rate.toString());
                            const fxFreight = parseFloat(values.fxFreight.toString());
                            const cost = (fxNetPrice + fxFreight) * rate;
                            setFieldValue('cost', cost.toFixed(2));
                          }
                          setFieldValue('fxNetPrice', fxNetPrice);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.fxNetPrice && errors.fxNetPrice)}
                        helperText={touched.fxNetPrice && errors.fxNetPrice}
                        InputProps={{
                          startAdornment: <span className="pr-1">$</span>,
                        }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      />

                      <TextField
                        type="number"
                        variant="standard"
                        value={values.fxFreight}
                        name="fxFreight"
                        label="FX Freight"
                        onChange={e => {
                          const fxFreight = parseFloat(e.target.value);
                          if (values.isCostComputed) {
                            const rate = parseFloat(values.rate.toString());
                            const fxNetPrice = parseFloat(values.fxNetPrice.toString());
                            const cost = (fxNetPrice + fxFreight) * rate;
                            setFieldValue('cost', cost.toFixed(2));
                          }
                          setFieldValue('fxFreight', fxFreight);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.fxFreight && errors.fxFreight)}
                        helperText={touched.fxFreight && errors.fxFreight}
                        InputProps={{
                          startAdornment: <span className="pr-1">$</span>,
                        }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      />
                    </div>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isCostComputed)}
                          name="isCostComputed"
                          onChange={e => {
                            const isCostComputed = e.target.checked;
                            if (isCostComputed) {
                              const rate = parseFloat(values.rate.toString());
                              const fxNetPrice = parseFloat(values.fxNetPrice.toString());
                              const fxFreight = parseFloat(values.fxFreight.toString());
                              const cost = (fxNetPrice + fxFreight) * rate;
                              setFieldValue('cost', cost.toFixed(2));
                            }
                            setFieldValue('isCostComputed', isCostComputed);
                          }}
                        />
                      }
                      label="Compute cost"
                    />

                    <div className="flex flex-row gap-3">
                      <TextField
                        className="flex-1"
                        variant="standard"
                        value={values.materialUsageTriggerQty}
                        name="materialUsageTriggerQty"
                        label="Trigger Qty"
                        onChange={handleChange}
                      />

                      <TextField
                        className="flex-1"
                        variant="standard"
                        value={values.materialUsageOrderQty}
                        name="materialUsageOrderQty"
                        label="Order Qty"
                        onChange={handleChange}
                      />
                    </div>

                    <TextField
                      variant="standard"
                      value={values.supplier}
                      name="supplier"
                      label="Supplier"
                      onChange={handleChange}
                    />

                    <TextField
                      variant="standard"
                      value={values.materialUsageSupplierCode}
                      name="materialUsageSupplierCode"
                      label="Supplier Code"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex flex-row gap-5 mt-4 items-center">
                    <div className="flex flex-row gap-3">
                      <TextField
                        type="number"
                        variant="standard"
                        value={values.materialUsageLeadTime}
                        name="materialUsageLeadTime"
                        label="Lead Time"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.materialUsageLeadTime && errors.materialUsageLeadTime)}
                        helperText={touched.materialUsageLeadTime && errors.materialUsageLeadTime}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        sx={{width: 100}}
                      />
                      
                      <Select
                        name="materialUsageLeadTimeUnit"
                        value={values.materialUsageLeadTimeUnit}
                        onChange={handleChange}>
                        {leadTimeUnitSelection.map(indivLeadTimeSelection => {
                          return (
                            <MenuItem
                              key={indivLeadTimeSelection.value}
                              value={indivLeadTimeSelection.value}>
                              {indivLeadTimeSelection.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="flex flex-row gap-3">
                      <FormControl>
                        <FormLabel>Kanban:</FormLabel>
                        <RadioGroup
                          row
                          value={values.materialUsageKanban}
                          name="materialUsageKanban"
                          onChange={handleChange}>
                          <FormControlLabel value={true} control={<Radio />} label="Yes" />
                          <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    color="error"
                    onClick={() => {
                      setOpenRawMaterialFormDialog(false);
                      setSelectedRawMaterialId(undefined);
                      handleReset();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    color="success"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    {selectedRawMaterialId ? 'Update' : 'Create'}
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}

      {/* Unit Cost Filter */}
      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleToggleFilterUnitCost}
        open={openFilterUnitCost}>
        <DialogTitle>Unit Cost Filter</DialogTitle>
        <DialogContent>
          <div className="grid gap-2 mt-2">
            <div className="flex flex-row gap-2 items-center">
              <Typography fontWeight="bold">
                Filter type:
              </Typography>

              <Select
                size="small"
                value={filterTypeUnitCost}
                onChange={e => {
                  const filterType = e.target.value as FilterTypeUnitCost;
                  setFilterTypeUnitCost(filterType);
                  if (filterType === 'between') {
                    const minValue = Math.min.apply(null, filterSelectionUnitCost)
                    const maxValue = Math.max.apply(null, filterSelectionUnitCost)
                    setFilterValuesUnitCost([minValue, maxValue]); 
                  } else {
                    setFilterValuesUnitCost([]);
                  }
                }}>
                <MenuItem value="standard">
                  Standard
                </MenuItem>
                <MenuItem value="between">
                  Between
                </MenuItem>
              </Select>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <Typography fontWeight="bold">
                  Selection:
                </Typography>
                
                <Button
                  color="error"
                  size="small"
                  onClick={handleResetFilterUnitCost}>
                  (Reset Filter)
                </Button>

                {filterTypeUnitCost === 'standard' && (
                  <div className="ml-auto w-[100px]">
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      size="small"
                      type="number"
                      variant="standard"
                      value={filterSelectionSearchUnitCost}
                      onChange={e => {
                        setFilterSelectionSearchUnitCost(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: <span className="pr-1">$</span>,
                      }}
                      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                  </div>
                )}
              </div>

              {filterTypeUnitCost === 'standard' ? (
                <div className="flex flex-wrap justify-evenly gap-2">
                  {filterSelectionUnitCost
                    .map((indivUnitCost, indivUnitCostIndex) => {
                      if (filterSelectionSearchUnitCost) {
                        const filterValue = parseFloat(filterSelectionSearchUnitCost);
                        if (indivUnitCost !== filterValue) {
                          return null;
                        }
                      }

                      const isSelected = filterValuesUnitCost.includes(indivUnitCost);

                      return (
                        <Chip
                          color="primary"
                          key={indivUnitCostIndex}
                          clickable
                          label={`$${indivUnitCost.toFixed(2)}`}
                          variant={isSelected ? 'filled' : 'outlined'}
                          onClick={() => {
                            const newFilterValuesUnitCost = [...filterValuesUnitCost];

                            if (isSelected) {
                              const filterIndex = newFilterValuesUnitCost.indexOf(indivUnitCost);
                              newFilterValuesUnitCost.splice(filterIndex, 1);
                            } else {
                              newFilterValuesUnitCost.push(indivUnitCost);
                            }

                            setFilterValuesUnitCost(newFilterValuesUnitCost);
                          }}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="flex flex-row gap-5 items-center">
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={filterValuesUnitCost[0]}
                    onChange={e => {
                      let newValue = parseFloat(e.target.value);

                      if (Number.isNaN(newValue)) {
                        newValue = 0;
                      }

                      const newFilterValuesUnitCost = [...filterValuesUnitCost];
                      newFilterValuesUnitCost[0] = newValue;
                      setFilterValuesUnitCost(newFilterValuesUnitCost);
                    }}
                    InputProps={{
                      startAdornment: <span className="pr-1">$</span>,
                    }}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                  />
                  <Slider
                    step={0.01}
                    min={filterSelectionUnitCost[0]}
                    max={filterSelectionUnitCost[filterSelectionUnitCost.length - 1]}
                    value={filterValuesUnitCost}
                    onChange={(_, newValue) => {
                      setFilterValuesUnitCost(newValue as number[]);
                    }}
                    valueLabelDisplay="off"
                  />
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={filterValuesUnitCost[1]}
                    onChange={e => {
                      let newValue = parseFloat(e.target.value);

                      if (Number.isNaN(newValue)) {
                        newValue = 0;
                      }

                      const newFilterValuesUnitCost = [...filterValuesUnitCost];
                      newFilterValuesUnitCost[1] = newValue;
                      setFilterValuesUnitCost(newFilterValuesUnitCost);
                    }}
                    InputProps={{
                      startAdornment: <span className="pr-1">$</span>,
                    }}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={handleToggleFilterUnitCost}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Currency Filter */}
      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleToggleFilterCurrency}
        open={openFilterCurrency}>
        <DialogTitle>Currency Filter</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Typography fontWeight="bold">
                Selection:
              </Typography>
              
              <Button
                color="error"
                size="small"
                onClick={handleResetFilterCurrency}>
                (Reset Filter)
              </Button>

              <div className="ml-auto">
                <TextField
                  placeholder="Search"
                  autoComplete="off"
                  autoCorrect="off"
                  size="small"
                  variant="standard"
                  value={filterSelectionSearchCurrency}
                  onChange={e => {
                    setFilterSelectionSearchCurrency(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap gap-2 mt-5">
            {currencies
              .filter(indivCurrency => {
                if (filterSelectionSearchCurrency) {
                  if (
                    searchInputCheck(filterSelectionSearchCurrency, indivCurrency.name) ||
                    searchInputCheck(filterSelectionSearchCurrency, indivCurrency.label)
                  ) {
                    return indivCurrency;
                  }
                } else {
                  return indivCurrency;
                }

                return null;
              })
              .map((indivCurrency, indivCurrencyIndex) => {
                

                const isSelected = filterValuesCurrency.includes(indivCurrency.name);

                return (
                  <Chip
                    color="primary"
                    key={indivCurrencyIndex}
                    clickable
                    label={`${indivCurrency.name.toUpperCase()} (${indivCurrency.label})`}
                    variant={isSelected ? 'filled' : 'outlined'}
                    onClick={() => {
                      const newFilterValuesCurrency = [...filterValuesCurrency];

                      if (isSelected) {
                        const filterIndex = newFilterValuesCurrency.indexOf(indivCurrency.name);
                        newFilterValuesCurrency.splice(filterIndex, 1);
                      } else {
                        newFilterValuesCurrency.push(indivCurrency.name);
                      }

                      setFilterValuesCurrency(newFilterValuesCurrency);
                    }}
                  />
                );})
              }
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={handleToggleFilterCurrency}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Supplier Filter */}
      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleToggleFilterSuppliers}
        open={openFilterSuppliers}>
        <DialogTitle>Suppliers Filter</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Typography fontWeight="bold">
                Selection:
              </Typography>
              
              <Button
                color="error"
                size="small"
                onClick={handleResetFilterSuppliers}>
                (Reset Filter)
              </Button>

              <div className="ml-auto">
                <TextField
                  placeholder="Search"
                  autoComplete="off"
                  autoCorrect="off"
                  size="small"
                  variant="standard"
                  value={filterSelectionSearchSuppliers}
                  onChange={e => {
                    setFilterSelectionSearchSuppliers(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap gap-2 mt-5">
            {filterSelectionSuppliers
              .filter(indivSupplier => {
                if (filterSelectionSearchSuppliers) {
                  if (
                    searchInputCheck(filterSelectionSearchSuppliers, indivSupplier)
                  ) {
                    return indivSupplier;
                  }
                } else {
                  return indivSupplier;
                }

                return null;
              })
              .map((indivSupplier, indivSupplierIndex) => {
                const isSelected = filterValuesSuppliers.includes(indivSupplier);

                return (
                  <Chip
                    color="primary"
                    key={indivSupplierIndex}
                    clickable
                    label={indivSupplier}
                    variant={isSelected ? 'filled' : 'outlined'}
                    onClick={() => {
                      const newFilterValuesSuppliers = [...filterValuesSuppliers];

                      if (isSelected) {
                        const filterIndex = newFilterValuesSuppliers.indexOf(indivSupplier);
                        newFilterValuesSuppliers.splice(filterIndex, 1);
                      } else {
                        newFilterValuesSuppliers.push(indivSupplier);
                      }

                      setFilterValuesSuppliers(newFilterValuesSuppliers);
                    }}
                  />
                );})
              }
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={handleToggleFilterSuppliers}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Kanban Filter */}
      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleToggleFilterKanban}
        open={openFilterKanban}>
        <DialogTitle>Kanban Filter</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Typography fontWeight="bold">
                Selection:
              </Typography>
              
              <Button
                color="error"
                size="small"
                onClick={handleResetFilterKanban}>
                (Reset Filter)
              </Button>
            </div>
          </div>

          <div className="flex flex-row flex-wrap gap-2 mt-5">
            {[1, 0]
              .map((indivKanban, indivKanbanIndex) => {
                const kanbanValue = indivKanban ? 1 : 0;
                const isSelected = filterValuesKanban.includes(kanbanValue);

                return (
                  <Chip
                    color="primary"
                    key={indivKanbanIndex}
                    clickable
                    label={`${indivKanban ? 'Yes' : 'No'}`}
                    variant={isSelected ? 'filled' : 'outlined'}
                    onClick={() => {
                      const newFilterValuesKanban = [...filterValuesKanban];

                      if (isSelected) {
                        const filterIndex = newFilterValuesKanban.indexOf(indivKanban);
                        newFilterValuesKanban.splice(filterIndex, 1);
                      } else {
                        newFilterValuesKanban.push(indivKanban);
                      }

                      setFilterValuesKanban(newFilterValuesKanban);
                    }}
                  />
                );})
              }
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={handleToggleFilterKanban}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

interface DnDProps {
  children: React.ReactNode;
  rawMaterialId: number;
  disabled: boolean;
  handleClick?: () => void;
}

const DraggableComponent = ({
  children,
  rawMaterialId,
  disabled,
  handleClick,
}: DnDProps) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: rawMaterialId,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    opacity: 0.5,
  } : undefined;

  if (disabled) {
    return (
      <TableRow>
        {children}
      </TableRow>
    );
  }
  
  return (
    <TableRow
      onClick={handleClick}
      hover
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}>
      {children}
    </TableRow>
  );
};

export default RawMaterials;
