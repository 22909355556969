// Dependecies
import axios from 'axios';
import {API_URL} from '../../../../../utils/urls';

// Types
import {NotificationPropsContent} from '../../../../../models/Notification';

interface ResponseGetNotifications {
  message?: string;
  data: {
    notifications: NotificationPropsContent[];
  };
}

export function getNotifications() {
  return axios.get<ResponseGetNotifications>(API_URL + '/mobile/notifications');
}
