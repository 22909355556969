import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';

// Types
import {StaffHandbookProps} from '../../../../../models/StaffHandbook';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';
import StaffHandbookList from './_components/StaffHandbookList';
import SelectedHandbook from './_components/SelectedHandbook';

// APIs
import {
  deleteStaffHandbook,
  getStaffHandbooksListData,
  submitStaffHandbookForm,
} from '../../../_api/mobile/home/staff-handbook/staff-handbook';

const StaffHandbook = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [staffHandbooks, setStaffHandbooks] = useState<StaffHandbookProps[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [selectedHandbook, setSelectedHandbook] = useState<StaffHandbookProps>();
  const [openHandbookFormDialog, setOpenHandbookFormDialog] = useState<boolean>(false);
  const [staffHandbookTitle, setStaffHandbookTitle] = useState<StaffHandbookProps['title']>('');
  const [staffHandbookUpdateType, setStaffHandbookUpdateType] = useState<string>('Create');
  const [staffHandbookId, setStaffHandbookId] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Refs
  const staffHandbooksListContainerRef = useRef<HTMLDivElement>(null);

  // Context
  const {authUser} = useContext(AuthContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getStaffHandbooksListData();

      if (status === 200) {
        setIsAdmin(data.data.isAdmin);
        setStaffHandbooks(data.data.staffHandbooks);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handbookClickHandler = (
    element: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const handbookId = element.currentTarget.value;
    let handbookIndex = -1;
    const newSelectedHandbook = staffHandbooks.find(
      (indivHandbook, indivHandbookIndex) => {
        if (indivHandbook.id?.toString() === handbookId) {
          handbookIndex = indivHandbookIndex;
          return indivHandbook;
        }

        return null;
      }
    );
    
    // Update isSeen
    if (newSelectedHandbook) {
      newSelectedHandbook.isSeen = true;
      if (selectedHandbook && handbookId === selectedHandbook.id?.toString()) {
        // setSelectedHandbook(undefined);
      } else {
        if (!newSelectedHandbook.isSeen && handbookIndex > 0) {
          const newStaffHandbooks = [...staffHandbooks];
          newStaffHandbooks.splice(handbookIndex, 1, newSelectedHandbook);
          setStaffHandbooks(newStaffHandbooks);
        }
        setSelectedHandbook(newSelectedHandbook);
      }
    }
  };

  const handbookUpdateClickHandler = (
    element: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const handbookId = element.currentTarget.value;
    const staffHandbook = staffHandbooks.find(indivHandbook => {
      if (indivHandbook.id?.toString() === handbookId) {
        return indivHandbook;
      }
      return null;
    });
    setOpenHandbookFormDialog(true);
    setStaffHandbookId(parseInt(handbookId));
    setStaffHandbookTitle(staffHandbook?.title ?? '');
    setStaffHandbookUpdateType(element.currentTarget.dataset.buttonType ?? 'Create');
  };

  const handleUpdateHandbookList = (staffHandbook: StaffHandbookProps) => {
    const newStaffHandbooks = [...staffHandbooks];
    const handbookIndex = newStaffHandbooks.findIndex(indivHandbook => {
      return indivHandbook.id === staffHandbook.id;
    });
    newStaffHandbooks.splice(handbookIndex, 1, staffHandbook);
    setStaffHandbooks(newStaffHandbooks);
  };

  const handleCloseStaffHandbookFormDialog = () => {
    setOpenHandbookFormDialog(false);
    setStaffHandbookTitle('');
    setStaffHandbookId(0);
    setStaffHandbookUpdateType('Create');
  };

  const handleSubmitHandbookForm = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const staffHandbook = {id: staffHandbookId, title: staffHandbookTitle};
      const newStaffHandbooks = [...staffHandbooks];

      if (staffHandbookUpdateType === 'Delete') {
        const {status, data} = await deleteStaffHandbook(staffHandbook);

        if (status === 200) {
          const staffHandbookIndex = newStaffHandbooks.findIndex(indivStaffHandbook => {
            return indivStaffHandbook.id === data.data.staffHandbook.id;
          });
          newStaffHandbooks.splice(staffHandbookIndex, 1);

          enqueueSnackbar(
            data.message || 'Handbook saved.',
            {
              autoHideDuration: 3000,
              variant: 'warning',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        }
      } else {
        const {status, data} = await submitStaffHandbookForm(staffHandbook);
        switch (status) {
          case 201: {
            newStaffHandbooks.push(data.data.staffHandbook);
            setSelectedHandbook(data.data.staffHandbook);
            const listsContainer = staffHandbooksListContainerRef.current;
    
            if (listsContainer) {
              listsContainer.scrollTo({
                behavior: 'smooth',
                top: listsContainer.scrollHeight,
              });
            }
            break;
          }
  
          case 200: {
            const staffHandbookIndex = newStaffHandbooks.findIndex(indivStaffHandbook => {
              return indivStaffHandbook.id === data.data.staffHandbook.id;
            });
            newStaffHandbooks.splice(staffHandbookIndex, 1, data.data.staffHandbook);
            break;
          }
        }
  
        enqueueSnackbar(
          data.message || 'Handbook saved.',
          {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );
      }

      setStaffHandbooks(newStaffHandbooks);
      handleCloseStaffHandbookFormDialog();
    } catch (err) {
      console.log(err);

      enqueueSnackbar(
        'Something went wrong, please contact your admin.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [staffHandbookId, staffHandbookTitle, staffHandbookUpdateType, staffHandbooks]);

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (init && !selectedHandbook) {
      let staffHandbookCache: StaffHandbookProps | undefined = undefined;
      staffHandbooks.forEach(indivHandbook => {
        if (!indivHandbook.isHeader && !staffHandbookCache) {
          staffHandbookCache = indivHandbook;
          if (!indivHandbook.isSeen) {
            indivHandbook.isSeen = true;
          }
        }
      });
      setSelectedHandbook(staffHandbookCache);
    }
  }, [staffHandbooks, selectedHandbook, init]);

  if (!init) {
    return <LoadingFetchData />;
  } else if (!authUser) {
    return null;
  }

  return (
    <Fragment>
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
        <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2">
          <div
            ref={staffHandbooksListContainerRef}
            className="border border-blue-500 shadow rounded-lg max-h-[500px] overflow-hidden hover:overflow-y-scroll">
            <StaffHandbookList
              isRefreshing={isRefreshing}
              refreshHandler={refreshHandler}
              staffHandbooks={staffHandbooks}
              setStaffHandbooks={setStaffHandbooks}
              handbookClickHandler={handbookClickHandler}
              handbookUpdateClickHandler={handbookUpdateClickHandler}
              selectedHandbook={selectedHandbook}
              setSelectedHandbook={setSelectedHandbook}
              isAdmin={isAdmin}
              setOpenHandbookFormDialog={setOpenHandbookFormDialog}
            />
            <div className="mb-10" />
          </div>

          <div className="lg:col-span-3 border shadow bg-[#ffffff] rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
            <SelectedHandbook
              staffHandbook={selectedHandbook}
              handleUpdateHandbookList={handleUpdateHandbookList}
            />
          </div>
        </div>
      </div>

      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleCloseStaffHandbookFormDialog}
        open={openHandbookFormDialog}>
        <DialogTitle>{staffHandbookUpdateType} staff handbook</DialogTitle>
        <DialogContent>
          {staffHandbookUpdateType === 'Delete' ? (
            <Typography fontWeight="bold">
              {staffHandbookTitle}
            </Typography>
          ) : (
            <TextField
              variant='standard'
              fullWidth
              value={staffHandbookTitle}
              autoComplete='off'
              autoCorrect='off'
              placeholder='Type the title'
              onChange={e => {
                setStaffHandbookTitle(e.target.value);
              }}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={handleCloseStaffHandbookFormDialog}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            onClick={handleSubmitHandbookForm}>
            {staffHandbookUpdateType === 'Create' ? 'Submit' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default StaffHandbook;
