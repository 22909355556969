import {useCallback, useContext, useEffect, useState} from 'react';
import {CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {ProfileContext} from '../_context/ProfileContext';

// Types
import {ScheduleProps} from '../../../../../models/Schedule';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIss
import {getUserProfile} from '../../../_api/mobile/profile/profile';
import dayjs from 'dayjs';


const EmployeeAgreementHours = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [userSchedules, setUserSchedules] = useState<ScheduleProps[]>([]);

  // Context
  const {authUser} = useContext(AuthContext);
  const {updateCount} = useContext(ProfileContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getUserProfile();

      if (status === 200) {
        setUserSchedules(data.data.schedules);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />
  }

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Employee Agreement Hours
          </Typography>

          {Boolean(authUser?.id) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Day
              </TableCell>
              <TableCell>
                Clock-in
              </TableCell>
              <TableCell>
                Clock-out
              </TableCell>
              <TableCell>
                Break (minutes)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userSchedules.length ? userSchedules.map(indivSchedule => {
              const clockIn = dayjs(indivSchedule.clockIn, 'HH:mm:ss');
              const clockOut = dayjs(indivSchedule.clockOut, 'HH:mm:ss');

              return (
                <TableRow key={indivSchedule.day}>
                  <TableCell>
                    {indivSchedule.day}
                  </TableCell>
                  <TableCell>
                    {indivSchedule.clockIn ? clockIn.format('hh:mm A') : '-'}
                  </TableCell>
                  <TableCell>
                    {indivSchedule.clockOut ? clockOut.format('hh:mm A') : '-'}
                  </TableCell>
                  <TableCell>
                    {indivSchedule.breakMinutes.toFixed(0)}
                  </TableCell>
                </TableRow>
              );
            }) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography className="text-gray-500">
                    Nothing to display.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmployeeAgreementHours;
