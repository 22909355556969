// Images
import FabricarLogo from '../../../assets/images/logos/fabricar-logo.png';

// Components
import {LoadingFetchData} from './Loading';

const SplashScreen = () => {
  return (
    <div className="h-[100vh] flex flex-col justify-center items-center">
      <img
        src={FabricarLogo}
        alt="fabricar-logo"
        className="w-[10%]"
      />

      <LoadingFetchData />
    </div>
  );
};

export default SplashScreen;
