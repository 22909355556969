import {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import {
  ClassicEditor,
  Context,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  ContextWatchdog,
  Link,
  AutoLink,
  List,
  Indent,
  IndentBlock,
  Alignment,
  Font,
} from 'ckeditor5';
import {
  CKEditor,
  CKEditorContext,
} from '@ckeditor/ckeditor5-react';
import {enqueueSnackbar} from 'notistack';
import dayjs from 'dayjs';

// Props
import {
  StaffHandbookProps,
  StaffHandbookViewLogProps,
} from '../../../../../../models/StaffHandbook';

// APIs
import {
  getStaffHandbookData,
  logStaffHandbookView,
  submitStaffHandbookForm,
} from '../../../../_api/mobile/home/staff-handbook/staff-handbook';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

interface StaffHandbookComponentProps {
  staffHandbook?: StaffHandbookProps;
  handleUpdateHandbookList?: (staffHandbook: StaffHandbookProps) => void;
}

interface ViewLogs {
  [key: string]: StaffHandbookViewLogProps[];
}

const SelectedHandbook = ({
  staffHandbook: initialStaffHandbook,
  handleUpdateHandbookList,
}: StaffHandbookComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [staffHandbook, setStaffHandbook] = useState<StaffHandbookProps | undefined>(initialStaffHandbook);
  const [editHandbook, setEditHandbook] = useState<boolean>(false);
  const [viewLogs, setViewLogs] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [staffHandbookViewLogs, setStaffHandbookViewLogs] = useState<
    StaffHandbookViewLogProps[]
  >([]);

  // Variables
  const viewLogsCache: ViewLogs = {};
  let viewLogsEmpty = true;

  staffHandbookViewLogs.forEach(indivStaffHandbookViewLog => {
    const staffHandbookViewLogDateTime = dayjs(
      indivStaffHandbookViewLog.createdAt,
    );
    const dateTimeString = staffHandbookViewLogDateTime.format('YYYY-MM-DD');

    const existingViewLog = viewLogsCache[dateTimeString];

    if (existingViewLog) {
      const existingUser = existingViewLog.find(indivDate => {
        if (indivDate.user.id === indivStaffHandbookViewLog.user.id) {
          return true;
        }

        return null;
      });
      if (!existingUser) {
        viewLogsCache[dateTimeString].push(indivStaffHandbookViewLog);
      }
    } else {
      viewLogsCache[dateTimeString] = [indivStaffHandbookViewLog];
    }
  });

  // Refs
  const staffHandbookContentRef = useRef<string | undefined>();
  const staffHandbookTitleRef = useRef<string | undefined>();

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (initialStaffHandbook) {
        const {status, data} = await getStaffHandbookData(initialStaffHandbook);

        if (status === 200) {
          setStaffHandbook(data.data.staffHandbook);
          setIsAdmin(data.data.isAdmin);
          if (!data.data.isAdmin) {
            setEditHandbook(false);
            setStaffHandbookViewLogs([]);
          } else {
            setStaffHandbookViewLogs(data.data.staffHandbookViewLogs);
          }
        }
        logStaffHandbookView(initialStaffHandbook, 'VIEW-IN');
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [initialStaffHandbook]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const editHandbookClickHandler = () => {
    setEditHandbook(true);
    if (staffHandbook) {
      staffHandbookTitleRef.current = staffHandbook.title;
      staffHandbookContentRef.current = staffHandbook.content;
    }
  };
  
  const viewLogsClickHandler = () => {
    setViewLogs(!viewLogs);
  };

  const cancelHandbookClickHandler = () => {
    staffHandbookContentRef.current = '';
    setEditHandbook(false);
  };

  const saveHandbookClickHandler = async () => {
    setIsSubmitting(true);
    try {
      if (staffHandbook) {
        const cachedStaffHandbook: StaffHandbookProps = {
          ...staffHandbook,
          id: staffHandbook?.id,
          title: staffHandbookTitleRef.current!,
          content: staffHandbookContentRef.current || '',
          isSeen: true,
        };
  
        const {status, data} = await submitStaffHandbookForm(cachedStaffHandbook);
  
        if (status === 200 || status === 201) {
          const staffHandbookQuery = data.data.staffHandbook;
          setStaffHandbook(staffHandbookQuery);
          setEditHandbook(false);
          if (handleUpdateHandbookList) {
            handleUpdateHandbookList(cachedStaffHandbook);
          }
  
          enqueueSnackbar(
            'Changes applied.',
            {
              autoHideDuration: 3000,
              variant: 'success',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
      setViewLogs(false);
    }
  };

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  if (!staffHandbook) {
    return (
      <div className="p-2 md:p-5">
        <Typography variant="caption">
          Please select a handbook.
        </Typography>
      </div>
    );
  }

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
        <div className="flex flex-row flex-1 gap-2">
          {editHandbook ? (
            <TextField
              variant="standard"
              defaultValue={staffHandbookTitleRef.current}
              fullWidth
              onChange={e => {
                staffHandbookTitleRef.current = e.target.value;
              }}
            />
          ) : (
            <Typography fontSize={20} fontWeight="bold">
              {staffHandbook.title}
            </Typography>
          )}

          {Boolean(staffHandbook) && !editHandbook && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>
        {isAdmin && (
          <div className="flex flex-row gap-5">
            {editHandbook ? (
              <Fragment>
                <Button
                  disabled={isSubmitting || isRefreshing}
                  color="error"
                  onClick={cancelHandbookClickHandler}>
                  Cancel
                </Button>

                <Button
                  disabled={isSubmitting || isRefreshing}
                  color="success"
                  variant="contained"
                  onClick={saveHandbookClickHandler}>
                  Save
                </Button>
              </Fragment>
            ) : (
              <div className="flex flex-row gap-3">
                <Button color="primary" onClick={editHandbookClickHandler}>
                  Edit
                </Button>

                <Button variant="contained" onClick={viewLogsClickHandler}>
                  {viewLogs ? 'Content' : 'Logs'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      {editHandbook ? (
        <CKEditorContext
          context={Context}
          contextWatchdog={ContextWatchdog}>
          <CKEditor
            editor={ClassicEditor}
            config={{
              plugins: [
                Essentials,
                Bold,
                Italic,
                Paragraph,
                Link,
                AutoLink,
                List,
                Indent,
                IndentBlock,
                Alignment,
                Font,
              ],
              toolbar: [
                'undo',
                'redo',
                '|',
                'bold',
                'italic',
                '|',
                'fontSize',
                'fontColor',
                '|',
                'alignment',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'link',
              ],
              list: {
                properties: {
                  styles: true,
                  startIndex: true,
                  reversed: true
                },
              },
            }}
            data={staffHandbook.content}
            onChange={(_, editor) => {
              staffHandbookContentRef.current = editor.getData();
            }}
          />
        </CKEditorContext>
      ) : viewLogs ? (
        <Fragment>
          {Object.keys(viewLogsCache).map(indivViewLogDate => {
            const viewLogDateTime = dayjs(indivViewLogDate);
            viewLogsEmpty = false;

            return (
              <div
                key={indivViewLogDate}
                className="border-[0.5px] p-3 mb-5 rounded">
                <div className="mb-3 border-b pb-2">
                  <Typography className="font-bold text-[16px]">
                    {viewLogDateTime.format('ddd. MMMM DD, YYYY')}
                  </Typography>
                </div>
                {viewLogsCache[indivViewLogDate]
                  .sort((currentViewLog, nextViewLog) => {
                    const currentViewLogDateTime = dayjs(
                      currentViewLog.createdAt,
                    );
                    const nextViewLogDateTime = dayjs(nextViewLog.createdAt);
                    return nextViewLogDateTime.diff(currentViewLogDateTime);
                  })
                  .map(indivViewLog => {
                    const indivViewLogDateTime = dayjs(
                      indivViewLog.createdAt,
                    );

                    return (
                      <div key={indivViewLog.id} className="ml-3 mb-2">
                        <Typography>
                          &bull;
                          {` ${indivViewLog.user.firstname} `}
                          {indivViewLog.user.lastname}{' '}
                          {indivViewLogDateTime.format('@ hh:mm A')}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            );
          })}

          {viewLogsEmpty && (
            <Typography className="text-grey-400">
              No logs yet, please try to refresh to check for new logs.
            </Typography>
          )}
        </Fragment>
      ) : (
        <div dangerouslySetInnerHTML={{__html: staffHandbook.content!}} />
      )}
    </div>
  )
}

export default SelectedHandbook;
