import {useContext} from 'react';
import {Button, TextField, Typography} from '@mui/material';
import {Form, Formik} from 'formik';
import {enqueueSnackbar} from 'notistack';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {SafetyContext} from '../_context/SafetyContext';

// APIs
import {createIncidentReport} from '../../../_api/mobile/safety/safety';

const FileReport = () => {
  // Context
  const {authUser} = useContext(AuthContext);
  const {dateToday, safetyReports, setSafetyReports} = useContext(SafetyContext);

  return (
    <Formik
      initialValues={{
        report: '',
      }}
      validate={values => {
        const errors: {report?: string;} = {};

        if (!values.report) {
          errors.report = 'Report is required.'
        }

        return errors;
      }}
      onSubmit={async (values, {resetForm, setSubmitting}) => {
        try {
          const {status, data} = await createIncidentReport(values);

          if (status === 201) {
            const newSafetyReports = [...safetyReports];
            newSafetyReports.push(data.data.safetyReport);
            setSafetyReports(newSafetyReports);

            enqueueSnackbar(
              'Report submitted.',
              {
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
          }
        } catch (err) {
          console.log(err);
          enqueueSnackbar(
            'Something went wrong, please contact your administrator.',
            {
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        } finally {
          setSubmitting(false);
          resetForm();
        }
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <div className="p-2 md:p-5">
            <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
              <div className="flex flex-row gap-2">
                <Typography fontSize={20} fontWeight="bold">
                  File a report
                </Typography>
              </div>
            </div>
            
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-2 border-b pb-2">
                <Typography fontWeight="bold">
                  Date:
                </Typography>

                <Typography>
                  {dateToday.format('ddd. MMM. DD, YYYY')}
                </Typography>
              </div>

              <div className="flex flex-row gap-2 border-b pb-2">
                <Typography fontWeight="bold">
                  Time:
                </Typography>

                <Typography>
                  {dateToday.format('hh:mm A')}
                </Typography>
              </div>

              <div className="flex flex-row gap-2 border-b pb-2">
                <Typography fontWeight="bold">
                  Person:
                </Typography>

                <Typography>
                  {authUser?.firstname} {authUser?.lastname}
                </Typography>
              </div>

              <TextField
                name="report"
                rows={10}
                value={values.report}
                onChange={handleChange}
                fullWidth
                multiline
                variant="outlined"
                placeholder="Please type in detail what happened."
                onBlur={handleBlur}
                error={Boolean(touched.report && errors.report)}
                helperText={touched.report && errors.report}
              />

              <div className="flex flex-row gap-3 justify-end">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="error"
                  onClick={handleReset}>
                  Clear
                </Button>

                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FileReport;
