import axios from 'axios';
// Utils
import {API_URL} from '../../../../utils/urls';

// Constants
export const USER_ACCESS_API_URL = API_URL + '/user-access';

interface ResponseSubmitAuthPin {
  data: {
    message: string;
  };
}

export function submitAuthPin(pin: string) {
  const requestBody = {pin};

  return axios.post<ResponseSubmitAuthPin>(USER_ACCESS_API_URL, requestBody);
}
