import {Outlet} from 'react-router-dom';

// Images
import AuthBackgroundSVG from '../../../assets/svg/backgrounds/authentication-background.svg';

export const Auth = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${AuthBackgroundSVG})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}>
      <Outlet />
    </div>
  );
};

export default Auth;
