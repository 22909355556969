import {useContext, useRef, useState} from 'react';
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';

// Types
import {UserProps} from '../../../../../../models/User';
import {UserFormType} from '../AdministratorContents';

// Context
import {AdministratorContext} from '../_context/AdministratorContext';

// Utils
import {diffForHumans} from '../../../../../../utils/utils';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';

interface UsersListComponentProps {
  isRefreshing: boolean;
  refreshHandler: () => void;
  users: UserProps[];
  handleToggleUserFormDialog: (type: UserFormType) => void;
}

const UsersList = ({
  isRefreshing,
  refreshHandler,
  users,
  handleToggleUserFormDialog,
}: UsersListComponentProps) => {
  const [searchInput, setSearchInput] = useState<string>('');

  // Context
  const {
    selectedUserId,
    setSelectedUserId,
  } = useContext(AdministratorContext);

  // Hooks
  const screenXL = useMediaQuery('(min-width:1280px) and (max-width:1600px)');

  // Refs
  const searchInputRef = useRef<HTMLDivElement>(null);

  // Handlers
  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
    searchInputRef.current?.focus();
  };

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  const userClickHandler = (
    element: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const newUserId = element.currentTarget.value;

    if (selectedUserId && parseInt(newUserId) === selectedUserId) {
      setSelectedUserId(undefined);
    } else {
      setSelectedUserId(parseInt(newUserId));
    }
  };

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Users List
          </Typography>

          <button
            type="button"
            disabled={isRefreshing}
            onClick={refreshHandler}>
            {isRefreshing ? (
              <CircularProgress size={12} />
            ) : (
              <RefreshIcon color="primary" fontSize="small" />
            )}
          </button>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            handleToggleUserFormDialog('create');
          }}>
          Add
        </Button>
      </div>

      <div className="mb-5 px-3">
        <TextField
          autoComplete="off"
          autoCorrect="off"
          ref={searchInputRef}
          value={searchInput}
          onChange={searchInputChangeHandler}
          variant="standard"
          label="Seach name"
          placeholder="Please type in the first or last name."
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  type="button"
                  onClick={searchInputIconClickHandler}>
                  {searchInput ? (
                    <CancelIcon color="error" />
                  ) : (
                    <SearchIcon />
                  )}
                </button>
              </InputAdornment>
            ),
          }}
        />
      </div>

      {users
        .sort((currentUser, nextUser) => {
          const currentName = currentUser.firstname + ' ' + currentUser.lastname;
          const nextName = nextUser.firstname + ' ' + nextUser.lastname;
          return currentName.localeCompare(nextName);
        })
        .map(indivUser => {
          const userFullName = `${indivUser.firstname} ${indivUser.lastname}`;

          if (
            searchInput &&
              !userFullName
                .toLocaleLowerCase()
                .includes(searchInput.toLocaleLowerCase())
          ) {
            return null;
          }

          let userContainerClass ='mb-3 border-blue-500 hover:border hover:shadow-lg p-3 rounded';

          if (indivUser.id === selectedUserId) {
            userContainerClass += ' border';
          }

          const statusColor = indivUser.lastActive
            ? 'text-green-500'
            : 'text-red-500';
          const statusText = indivUser.lastActive
            ? 'Active'
            : 'Logged-out';

          return (
            <button
              key={indivUser.id}
              value={indivUser.id}
              type="button"
              className="text-left block w-full border rounded mb-2"
              onClick={userClickHandler}>
              <div
                className={userContainerClass}>
                <div className="flex flex-row">
                  {!screenXL && (
                    <div className="w-[100px] h-[100px] border-[#f6f6f6] bg-[#ffffff]/[0.2] rounded md:flex lg:hidden xl:flex items-center justify-center mr-2 shadow hidden">
                      <Typography fontWeight="bold" textAlign="center">
                        {indivUser.firstname[0]}{indivUser.lastname[0]}
                      </Typography>
                    </div>
                  )}

                  <div className="flex-1">
                    <div className="border-[#000000] border-b pb-1 mb-2">
                      <Typography fontWeight="bold" fontSize={16}>
                        {indivUser.firstname} {indivUser.lastname}
                      </Typography>
                    </div>

                    <div className="flex items-center gap-1 mb-1">
                      <WorkIcon fontSize="small" />
                      <Typography variant="body2">
                        {indivUser.role}
                      </Typography>
                    </div>

                    <div className="flex items-center gap-1">
                      <EmailIcon fontSize="small" />
                      <Typography variant="body2">
                        {indivUser.email}
                      </Typography>
                    </div>

                    <div className="flex items-center gap-1">
                      <Typography fontWeight="bold" variant="body2">Last Activity:</Typography>
                      <Typography className={statusColor} variant="body2">
                        ({indivUser.isBlocked ? 'User Blocked' : statusText})
                      </Typography>
                    </div>
                    <Typography variant="body2">
                      {indivUser.lastActive
                        ? diffForHumans(dayjs(indivUser.lastActive!))
                        : '-'}
                    </Typography>
                  </div>
                </div>
              </div>
            </button>
          );
        })
      }
    </div>
  );
};

export default UsersList
