import {useContext, useState} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {Button, MenuItem, Select, TextField, Typography} from '@mui/material';

// Types
import {AuthenticationMobileProps} from '../../../models/Auth';

// Images
import FabricarLogo from '../../../assets/images/logos/fabricar-logo.png';

// APIs
import {getUserByToken, mobileLogin, verifyPin} from './_api/auth';

// Context
import {AuthContext} from '../../global/auth/context/AuthContext';

// Components
import {LoginTypeButtons} from './_components/LoginTypeButtons';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

const Mobile = () => {
  const {saveAuth, setAuthUser, setWebAccess} = useContext(AuthContext);

  const [insertPinCode, setInsertPincode] = useState<boolean>(false);
  const [pinCodeMessage, setPincodeMessage] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+64');
  const [mobileNumber, setMobileNumber] = useState<string>('');

  const countryCodes = [
    {
      name: 'Australia',
      dialCode: '+61',
      emoji: '🇦🇺',
      code: 'AU',
    },
    {
      name: 'Philippines',
      dialCode: '+63',
      emoji: '🇵🇭',
      code: 'PH',
    },
    {
      name: 'New Zealand',
      dialCode: '+64',
      emoji: '🇳🇿',
      code: 'NZ',
    },
  ];

  const validationSchemaMobileNumber = Yup.object().shape({
    countryCode: Yup.string().required('Country Code is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
  });

  const initialValues: AuthenticationMobileProps = {
    countryCode,
    mobileNumber,
  };

  // Handlers

  const cancelMobileLoginClickHandler = () => {
    setInsertPincode(false);
    setPincodeMessage('');
  };

  return (
    <div className="lg:flex justify-end">
      <div className="lg:w-1/2 p-5 lg:p-10">
        <div className="border rounded-xl shadow-md bg-[#ffffff]/[0.7] min-h-[90vh] h-full p-5 lg:py-1 xl:w-[80%]">
          <img
            className="object-contain w-[50%] lg:w-[25%] md:w-[30%] block mx-auto mb-5"
            src={FabricarLogo}
            alt="fabricar-logo"
          />

          <h1 className="text-center text-[30px] font-bold text-[#2c63ba] mb-5">
            Hello
          </h1>

          <h1 className="font-thin text-[30px] text-center mb-10">
            {pinCodeMessage ? pinCodeMessage : 'Let\'s get started'}
          </h1>

          {insertPinCode ? (
            <Formik
              enableReinitialize
              initialValues={{
                pinCode: '',
              }}
              validate={values => {
                const errors: {pinCode?: string} = {};

                if (!values.pinCode) {
                  errors.pinCode = 'Please insert 4-digit pin.';
                }

                return errors;
              }}
              onSubmit={async (
                values: {pinCode: string},
                {setErrors},
              ) => {
                try {
                  const {status, data: {data}} = await verifyPin(
                    {countryCode, mobileNumber},
                    values.pinCode,
                  );
            
                  if (status === 200) {
                    saveAuth(data);
                    const {status: userStatus, data: userData} = await getUserByToken(data.apiToken);
            
                    if (userStatus === 200) {
                      setAuthUser(userData.data.user);
                      setWebAccess(userData.data.webAccess);
                    }
                  }
                } catch (err) {
                  let message = 'Something went wrong, please contact your administrator.'
          
                  if (isAxiosError(err)) {
                    const response = err.response;
            
                    if (response) {
                      message = response.data.message;
                      switch (response.status) {
                        case 401: {
                          setErrors({
                            pinCode: 'Invalid pin.'
                          });
                          break;
                        }
                      }
                    }
                  }
            
                  enqueueSnackbar(
                    message,
                    {
                      autoHideDuration: 3000,
                      variant: 'error',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleBlur,
                handleChange,
              }) => (
                <Form className="md:mx-10 lg:mx-4">
                  <div className="mb-5">
                    <TextField
                      fullWidth
                      variant="standard"
                      value={values.pinCode}
                      name="pinCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Pin Code"
                      placeholder="X X X X"
                      error={Boolean(touched.pinCode && errors.pinCode)}
                      inputProps={{
                        className: 'text-center',
                      }}
                    />

                    {
                      Boolean(touched.pinCode && errors.pinCode) && (
                        <Typography variant="caption" className="text-red-600">
                          {errors.pinCode}
                        </Typography>
                      )
                    }
                  </div>

                  <div className="mb-3">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      className="p-3 block bg-[#50C878]! font-bold text-[#ffffff] rounded-full w-full">
                      Login
                    </Button>
                  </div>

                  <div className="mb-3">
                    <Button
                      className="w-full"
                      type="button"
                      color="error"
                      disabled={isSubmitting}
                      onClick={cancelMobileLoginClickHandler}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchemaMobileNumber}
              onSubmit={async (values, {setErrors}) => {
                try {
                  const {status, data} = await mobileLogin(values);

                  if (status === 200) {
                    setInsertPincode(true);
                    setPincodeMessage(data.message);
                  }
                } catch (err) {
                  let message = 'Something went wrong, please contact your administrator.'
          
                  if (isAxiosError(err)) {
                    const response = err.response;
            
                    if (response) {
                      message = response.data.message;
                      setErrors({
                        mobileNumber: 'Invalid mobile number.',
                      });
                    }
                  }
            
                  enqueueSnackbar(
                    message,
                    {
                      autoHideDuration: 3000,
                      variant: 'error',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                }
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleBlur,
              }) => (
                <Form className="md:mx-10 lg:mx-4">
                  <div className="mb-5">
                    <div className="flex flex-row">
                      <Select
                        className="min-w-[100px] mr-2"
                        value={countryCode}
                        name="countryCode"
                        onChange={e => {
                          setCountryCode(e.target.value);
                        }}>
                        {countryCodes.map(indivCountryCode => {
                          return (
                            <MenuItem
                              key={indivCountryCode.dialCode}
                              value={indivCountryCode.dialCode}>
                              {indivCountryCode.emoji}
                              {' '}
                              {indivCountryCode.dialCode}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <TextField
                        className="flex-1"
                        variant="outlined"
                        value={mobileNumber}
                        name="mobileNumber"
                        onChange={e => {
                          setMobileNumber(e.target.value);
                        }}
                        onBlur={handleBlur}
                        label="Mobile Number"
                        placeholder="Mobile Number"
                        error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                      />
                    </div>

                    {Boolean(touched.mobileNumber && errors.mobileNumber) && (
                      <Typography variant="caption" className="text-red-600">
                        {errors.mobileNumber}
                      </Typography>
                    )}
                  </div>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className="p-3 block bg-[#50C878]! font-bold text-[#ffffff] rounded-full w-full">
                    Validate Mobile
                  </Button>

                  <LoginTypeButtons
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mobile;
