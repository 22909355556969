import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {isAxiosError} from 'axios';
import {enqueueSnackbar} from 'notistack';

// Types
import {UserProps} from '../../../../../models/User';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {AdministratorContext} from './_context/AdministratorContext';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';
import UsersList from './_components/UsersList';
import UserProfile from './_components/UserProfile';
import UserSchedules from './_components/UserSchedules';
import UserRouteAccess from './_components/UserRouteAccess';
// import ProductScheduleAdmin from './_components/ProductScheduleAdmin';

// APIs
import {
  getHomeAdministratorData,
  submitUserForm,
} from '../../../_api/mobile/home/administrator/administrator';

export type UserFormType = 'create' | 'edit';
type UserFormBasic = Omit<UserProps, 'reportsTo' | 'roles'>;
export interface UserForm extends UserFormBasic {
  countryCode: string;
}

const AdministratorContents = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [userFormType, setUserFormType] = useState<UserFormType>();

  // Context
  const {authUser} = useContext(AuthContext);
  const {
    selectedUserId,
    setSelectedUserId,
    users,
    setUsers,
    user,
    setUser,
    roles,
    setRoles,
  } = useContext(AdministratorContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getHomeAdministratorData();

      switch (status) {
        case 200: {
          setUsers(data.data.users);
          setRoles(data.data.roles);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [setUsers, setRoles]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handleCloseUserFormDialog = () => {
    setUserFormType(undefined);
  };

  const handleToggleUserFormDialog = (type: UserFormType) => {
    setUserFormType(type);
  };

  // Variables
  const countryCodes = [
    {
      name: 'Australia',
      dialCode: '61',
      emoji: '🇦🇺',
      code: 'AU',
    },
    {
      name: 'Philippines',
      dialCode: '63',
      emoji: '🇵🇭',
      code: 'PH',
    },
    {
      name: 'New Zealand',
      dialCode: '64',
      emoji: '🇳🇿',
      code: 'NZ',
    },
  ];

  const initialValues: UserForm = {
    id: userFormType === 'edit' && user ? user.id : 0,
    email: userFormType === 'edit' && user ? user.email : '',
    firstname: userFormType === 'edit' && user ? user.firstname : '',
    lastname: userFormType === 'edit' && user ? user.lastname : '',
    superior: userFormType === 'edit' && user ? user.superior : '',
    countryCode: userFormType === 'edit' && user ? user.mobileNumber.slice(0, 2) : '64',
    mobileNumber: userFormType === 'edit' && user ? user.mobileNumber.slice(2) : '',
    staffCredit: userFormType === 'edit' && user ? user.staffCredit : 0,
    role: userFormType === 'edit' && user ? user.role : '',
    isBlocked: userFormType === 'edit' && user ? user.isBlocked : false,
  };

  const validationSchemaUserForm = Yup.object().shape({
    firstname: Yup.string().required('Firstname is required'),
    lastname: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    countryCode: Yup.string().required('Country Code is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
  });

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />
  } else if (!authUser) {
    return null;
  }

  return (
    <Fragment>
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border mb-5">
        <div className="grid lg:grid-rows-3 lg:grid-cols-4 gap-2">
          <div className="bg-[#ffffff] border border-blue-500 shadow lg:row-span-3 lg:min-h-[500px] max-h-[1000px] rounded-lg overflow-hidden hover:overflow-y-scroll">
            <UsersList
              isRefreshing={isRefreshing}
              refreshHandler={refreshHandler}
              users={users}
              handleToggleUserFormDialog={handleToggleUserFormDialog}
            />
          </div>
          
          {selectedUserId ? (
            <Fragment>
              <div className="lg:col-span-3 bg-[#ffffff] border border-green-500 shadow rounded-lg">
                <UserProfile handleToggleUserFormDialog={handleToggleUserFormDialog} />
              </div>

              <div className="lg:col-span-2 lg:row-span-2 bg-[#ffffff] border border-yellow-500 shadow rounded-lg">
                <UserSchedules />
              </div>

              <div className="lg:col-span-1 lg:row-span-2 bg-[#ffffff] border border-gray-500 shadow rounded-lg">
                <UserRouteAccess />
              </div>
            </Fragment>
          ) : (
            <Typography>
              Please select a user.
            </Typography>
          )}
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchemaUserForm}
          onSubmit={async (values, {setErrors, resetForm}) => {
            try {
              const {status, data} = await submitUserForm(values);
              const newUsers = [...users];

              switch (status) {
                case 200: {
                  const userIndex = newUsers.findIndex(indivUser => indivUser.id === user?.id);
                  if (userIndex >= 0) {
                    const newUser = {...newUsers[userIndex], ...data.data.user}
                    newUsers.splice(userIndex, 1, newUser);
                  }
                  setUser(data.data.user);
                  break;
                }
                case 201: {
                  newUsers.push(data.data.user);
                  setSelectedUserId(data.data.user.id);
                  break;
                }
              }

              resetForm();
              handleCloseUserFormDialog();
              setUsers(newUsers);
              enqueueSnackbar(
                data.message,
                {
                  autoHideDuration: 3000,
                  variant: 'success',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            } catch (err) {
              let message = 'Something went wrong, please contact your administrator.'
              if (isAxiosError(err)) {
                const response = err.response;
                if (response) {
                  message = response.data.message;
                  const data = response.data.data;
                  console.log(data.mobile_number && data.mobile_number[0])
                  switch (response.status) {
                    case 400: {
                      setErrors({
                        firstname: data.firstname && data.firstname[0],
                        lastname: data.lastname && data.lastname[0],
                        email: data.email && data.email[0],
                        mobileNumber: data.mobile_number && data.mobile_number[0],
                      });
                    }
                  }
                }
              }
        
              enqueueSnackbar(
                message,
                {
                  autoHideDuration: 3000,
                  variant: 'error',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            }
          }}>
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
          }) => (
            <Dialog
              fullWidth
              disableScrollLock={true}
              onClose={() => {
                handleCloseUserFormDialog();
                handleReset();
              }}
              open={Boolean(userFormType)}>
              <DialogTitle>
                {user ? `Update ${user.firstname} ${user.lastname} Information` : 'Create User'}
              </DialogTitle>
              <DialogContent>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row gap-3">
                    <TextField
                      className="flex-1"
                      autoComplete="off"
                      variant="standard"
                      value={values.firstname}
                      name="firstname"
                      label="Firstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.firstname && errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                    />

                    <TextField
                      className="flex-1"
                      autoComplete="off"
                      variant="standard"
                      value={values.lastname}
                      name="lastname"
                      label="Lastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.lastname && errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                    />
                  </div>

                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    value={values.email}
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <div className="flex flex-row mt-3">
                    <Select
                      className="min-w-[100px] mr-2 self-start"
                      value={values.countryCode}
                      name="countryCode"
                      onChange={handleChange}>
                      {countryCodes.map(indivCountryCode => {
                        return (
                          <MenuItem
                            key={indivCountryCode.dialCode}
                            value={indivCountryCode.dialCode}>
                            {indivCountryCode.emoji}
                            {' '}
                            {indivCountryCode.dialCode}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    <TextField
                      autoComplete='off'
                      className="flex-1"
                      variant="outlined"
                      value={values.mobileNumber}
                      name="mobileNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Mobile Number"
                      placeholder="Mobile Number"
                      error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                      helperText={touched.mobileNumber && errors.mobileNumber}
                    />
                  </div>

                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    value={values.superior}
                    name="superior"
                    label="Reports to"
                    onChange={handleChange}
                  />

                  <div className="flex flex-row gap-3 items-center mt-3">
                    <FormControl
                      className="flex-1"
                      error={Boolean(touched.role && errors.role)}>
                      <InputLabel>Role</InputLabel>
                      <Select
                        label="Role"
                        type="number"
                        name="role"
                        value={values.role}
                        onChange={handleChange}>
                        {roles.map(indivRole => {
                          return (
                            <MenuItem key={indivRole.id} value={indivRole.name}>
                              {indivRole.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched.role && errors.role && (
                        <FormHelperText>{errors.role}</FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      type="number"
                      variant="outlined"
                      value={values.staffCredit}
                      name="staffCredit"
                      label="Staff Credit"
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <span className="pr-1">$</span>,
                      }}
                      sx={{width: '25%'}}
                      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  color="error"
                  onClick={handleCloseUserFormDialog}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  color='primary'
                  onClick={() => {
                    handleSubmit();
                  }}>
                  {userFormType === 'edit' ? 'Update' : 'Create'}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
        
      </div>

      {/* <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border">
        <ProductScheduleAdmin />
      </div> */}
    </Fragment>
    
  );
};

export default AdministratorContents;
