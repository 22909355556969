// Context
import SafetyContextProvider from './_context/SafetyContext';

// Components
import SafetyReports from './_components/SafetyReports';
import FileReport from './_components/FileReport';
import SelectedSafetyReport from './_components/SelectedSafetyReport';

const Safety = () => {
  return (
    <SafetyContextProvider>
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
        <div className="grid lg:grid-cols-3 gap-2">
          <div className="bg-[#ffffff] border shadow rounded-lg max-h-[1000px]">
            <FileReport />
          </div>

          <div className="bg-[#ffffff] border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
            <SafetyReports />
          </div>

          <div className="bg-[#ffffff] border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
            <SelectedSafetyReport />
          </div>
        </div>
      </div>
    </SafetyContextProvider>
  );
};

export default Safety;
