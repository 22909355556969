import {useCallback, useContext, useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {ProfileContext} from '../_context/ProfileContext';

// Types
import {UserProps} from '../../../../../models/User';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIss
import {getUserProfile} from '../../../_api/mobile/profile/profile';


const UserProfile = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProps>();

  // Context
  const {authUser} = useContext(AuthContext);
  const {updateCount} = useContext(ProfileContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getUserProfile();

      if (status === 200) {
        setUserProfile(data.data.user);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />
  }

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            User Profile
          </Typography>

          {Boolean(authUser?.id) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <div className="flex flex-row border-b pb-2 gap-2">
          <Typography fontWeight="bold">
            Name:
          </Typography>

          <Typography>
            {userProfile?.firstname} {userProfile?.lastname}
          </Typography>
        </div>

        <div className="flex flex-row border-b pb-2 gap-2">
          <Typography fontWeight="bold">
            Phone number:
          </Typography>

          <Typography>
            +{userProfile?.mobileNumber}
          </Typography>
        </div>

        <div className="flex flex-row border-b pb-2 gap-2">
          <Typography fontWeight="bold">
            Staff credit:
          </Typography>

          <Typography>
            ${userProfile?.staffCredit.toFixed(2)}
          </Typography>
        </div>

        <div className="flex flex-row border-b pb-2 gap-2">
          <Typography fontWeight="bold">
            Role:
          </Typography>

          <Typography>
            {userProfile?.role}
          </Typography>
        </div>

        <div className="flex flex-row border-b pb-2 gap-2">
          <Typography fontWeight="bold">
            Reports to:
          </Typography>

          <Typography>
            {userProfile?.superior}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
