import axios from 'axios';
// Utils
import {API_URL} from '../../../../../../utils/urls';

// Types
import {TimesheetLogChangeProps} from '../../../../../../models/Timesheet';

interface ResponseGetTimesheetLogChanges {
  data: {
    timesheetLogChanges: TimesheetLogChangeProps[];
  };
}

export function getTimesheetLogChanges() {
  return axios.get<ResponseGetTimesheetLogChanges>(
    API_URL + '/home/timeclock/timesheet-log-changes',
  );
}
