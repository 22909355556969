// Dependecies
import axios from 'axios';
import {API_URL} from '../../../../../utils/urls';

// Types
import {UserProps} from '../../../../../models/User';
import {ScheduleProps} from '../../../../../models/Schedule';

interface ResponseGetUserProfile {
  message: string;
  data: {
    user: UserProps;
    schedules: ScheduleProps[];
  };
}

export function getUserProfile() {
  return axios.get<ResponseGetUserProfile>(API_URL + '/mobile/profile');
}
