import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {TimePicker} from '@mui/x-date-pickers';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';
import {HomeContext} from '../../_context/HomeContext';

// Types
import {TimesheetLogProps, TimesheetProps} from '../../../../../../models/Timesheet';
import {ManagerTabProps} from '../../../../../../models/Employee';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Utils
import {
  STATUS_ID_APPROVED,
  STATUS_ID_CANCELLED,
  STATUS_ID_PENDING,
} from '../../../../../../utils/constants';

// APIs
import {
  getTimeclock,
  logTimeclock,
  submitChangeTimesheetLogRequest,
} from '../../../../_api/mobile/home/timeclock/timeclock';
import {setTaskStatus} from '../../../../_api/mobile/home/timeclock/manager/manager';

type TabType = 'personal' | 'manager';

const TodayLogs = () => {
  const [init, setInit] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [screenOption, setScreenOption] = useState<TabType>('personal');
  const [timesheet, setTimesheet] = useState<TimesheetProps>();
  const [timesheetLogs, setTimesheetLogs] = useState<TimesheetLogProps[]>([]);
  const [managerTasksCount, setManagerTasksCount] = useState<number>(0);
  const [clockedIn, setClockedIn] = useState<boolean>(false);
  const [hasEmployees, setHasEmployees] = useState<boolean>(false);
  const [managerTasks, setManagerTasks] = useState<ManagerTabProps[]>([]);
  const [managerTasksLoaded, setManagerTasksLoaded] = useState<boolean>(false);
  const [selectedManagerTaskStatus] = useState<number>(STATUS_ID_PENDING);
  const [openChangeTimesheetLogDialog, setOpenChangeTimesheetLogDialog]
    = useState<boolean>(false);
  const [selectedTimesheetLog, setSelectedTimesheetLog]
    = useState<TimesheetLogProps>();

  // Variables
  let taskCount = 0;

  // Context
  const {authUser} = useContext(AuthContext);
  const {updateCount, setUpdateCount} = useContext(HomeContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getTimeclock();

      if (status === 200) {
        setTimesheet(data.data.timesheet);
        setTimesheetLogs(data.data.timesheetLogs);
        setClockedIn(data.data.clockedIn);
        setHasEmployees(data.data.hasEmployees);
        if (!data.data.hasEmployees) {
          setScreenOption('personal');
        }
        setManagerTasksCount(data.data.managerTasksCount);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const fetchManagerData = useCallback(
    async (initialize: boolean) => {
      try {
        const {status, data} = await getTimeclock(
          'manager',
          // undefined,
          selectedManagerTaskStatus,
        );
        if (status === 200) {
          setManagerTasks(data.data.managerTasks);
          setManagerTasksLoaded(true);
          setManagerTasksCount(data.data.managerTasksCount);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setRefreshing(false);
        if (initialize) {
          setInit(true);
        }
      }
    },
    [selectedManagerTaskStatus],
  );

  const handleLogTimeClock = async () => {
    try {
      const {status} = await logTimeclock();

      if (status === 201) {
        setUpdateCount(newUpdateCount => (newUpdateCount + 1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseTimesheetLogDialog = () => {
    setOpenChangeTimesheetLogDialog(false);
    setSelectedTimesheetLog(undefined);
  };

  const handleSubmitTimesheetLogChange = useCallback(
    async () => {
      if (selectedTimesheetLog && timesheet) {
        const {status} = await submitChangeTimesheetLogRequest(
          timesheet,
          selectedTimesheetLog,
          dayjs(selectedTimesheetLog.time, 'HH:mm:ss'),
        );

        if (status === 201) {
          setUpdateCount(newUpdateCount => (newUpdateCount + 1));
          setOpenChangeTimesheetLogDialog(false);
        }
      }
    },
    [timesheet, selectedTimesheetLog, setUpdateCount],
  );

  const setStatusClickHandler = async (task: ManagerTabProps, statusId: number) => {
    setIsLoading(true);
    try {
      const {status} = await setTaskStatus(task, statusId);

      if (status === 200) {
        if (authUser?.id === task.user.id) {
          setUpdateCount(newUpdateCount => (newUpdateCount + 1));
        }
        fetchManagerData(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshHandler = useCallback(async () => {
    setRefreshing(true);
    try {
      if (screenOption === 'manager') {
        await fetchManagerData(false);
      } else {
        await fetchData(false);
      }
    } finally {
      setRefreshing(false);
    }
  }, [fetchData, fetchManagerData, screenOption]);

  const tabChangeHandler = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: TabType,
  ) => {
    setScreenOption(newValue);
  };

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (screenOption === 'manager') {
      fetchManagerData(false);
    }
  }, [screenOption, fetchManagerData]);

  useEffect(() => {
    if (!hasEmployees && screenOption === 'manager') {
      setScreenOption('personal');
    }
  }, [hasEmployees, screenOption]);

  useEffect(() => {
    let secondsCounter = 0;

    const interval = setInterval(() => {
      setDate(dayjs());

      // check if user is clocked-in every 5 mins
      if (secondsCounter === 300) {
        fetchData(false);
        secondsCounter = 0;
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <Fragment>
      <div className="p-2 md:p-5">
        <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Timeclock
            </Typography>

            {Boolean(authUser?.id) && (
              <button
                type="button"
                disabled={refreshing}
                onClick={refreshHandler}>
                {refreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>

          <Typography variant="body2">
            {date.format('ddd. MMMM DD, YYYY')}
          </Typography>
        </div>

        <div className="flex flex-col gap-3">
          <Typography
            fontWeight="bold"
            textAlign="center"
            fontSize={30}>
            {date.format('hh:mm:ss A')}
          </Typography>

          <div className="flex flex-row justify-center">
            <Button
              disabled={isLoading}
              variant="contained"
              color={clockedIn ? 'error' : 'success'}
              onClick={handleLogTimeClock}>
              <Typography>
                {clockedIn ? 'End Clock' : 'Start Clock'}
              </Typography>
            </Button>
          </div>

          {Boolean(hasEmployees) && (
            <Tabs
              variant="fullWidth"
              value={screenOption}
              onChange={tabChangeHandler}>
              <Tab label="Personal" value="personal" />
              <Tab label={'Manager' + (managerTasksCount ? ` (${managerTasksCount})` : '')} value="manager" />
            </Tabs>
          )}

          {screenOption === 'personal' ? (
            <Fragment>
              <Typography fontSize={16} fontWeight="bold">
                Today&apos;s Activities
              </Typography>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Type
                      </TableCell>
                      <TableCell>
                        Time
                      </TableCell>
                      <TableCell>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timesheetLogs.length ? timesheetLogs.map(indivTimesheetLog => {
                      let hasPendingRquest = false;

                      indivTimesheetLog.timesheetLogChanges.forEach(
                        indivTimesheetLogChange => {
                          if (
                            indivTimesheetLogChange.status.id ===
                            STATUS_ID_PENDING
                          ) {
                            hasPendingRquest = true;
                          }
                        },
                      );

                      const time = dayjs(indivTimesheetLog.time, 'HH:mm:ss');

                      return (
                        <TableRow key={indivTimesheetLog.id}>
                          <TableCell>
                            {indivTimesheetLog.type}
                          </TableCell>
                          <TableCell>
                            {time.format('hh:mm A')}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setSelectedTimesheetLog(indivTimesheetLog);
                                setOpenChangeTimesheetLogDialog(true);
                              }}>
                              Change
                            </Button>
                            {Boolean(hasPendingRquest) && (
                              <Typography variant="subtitle2" color="primary">
                                * Request Pending
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography className="text-gray-500">
                            No activity.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Fragment>
          ) : (
            <div className="grid gap-3">
              <Typography fontSize={16} fontWeight="bold">
                Managers&apos; to do:
              </Typography>

              {managerTasks
                .sort((currentTask, nextTask) => {
                  const currentUpdatedAt = dayjs(currentTask.task.updatedAt);
                  const nextUpdatedAt = dayjs(nextTask.task.updatedAt);
                  return nextUpdatedAt.unix() - currentUpdatedAt.unix();
                })
                .map((indivTask, indivTaskIndex) => {
                  taskCount++;

                  const createdAt = dayjs(indivTask.task.createdAt);
                  const updatedAt = dayjs(indivTask.task.updatedAt);
                  let statusColor = 'text-blue-500';

                  switch (indivTask.task.status?.id) {
                    case STATUS_ID_APPROVED: {
                      statusColor = 'text-green-500';
                      break;
                    }
                    case STATUS_ID_CANCELLED: {
                      statusColor = 'text-red-500';
                      break;
                    }
                  }

                  switch (indivTask.type) {
                    case 'timesheet-log-change': {
                      const timesheetDateTime = dayjs(
                        indivTask.timesheet.createdAt,
                      );
                      const fromTime = dayjs(
                        indivTask.task.fromTime,
                        'HH:mm:ss',
                      );
                      const toTime = dayjs(indivTask.task.toTime, 'HH:mm:ss');

                      return (
                        <div
                          key={indivTask.task.id}
                          className="p-3 border-[0.5px] rounded">
                          <div className="grid gap-1">
                            <TaskTextComponent
                              label="Task"
                              value="Changes in timesheet log"
                            />

                            <TaskTextComponent
                              label="Name"
                              value={
                                `${indivTask.user.firstname} ${indivTask.user.lastname}`
                                }
                            />

                            <TaskTextComponent
                              label="Date"
                              value={timesheetDateTime.format('ddd MMM. DD, YYYY')}
                            />

                            <TaskTextComponent
                              label="Status"
                              value={indivTask.task.status.name}
                              labelClass={`!font-bold ${statusColor}`}
                            />

                            <TaskTextComponent
                              label="Type"
                              value={indivTask.timesheetLog.type}
                            />

                            <Typography className="mb-1 !font-bold">Request:</Typography>

                            <div className="mb-1 flex flex-row px-2 gap-2">
                              <Typography className="mx-5">&bull;</Typography>
                              <div className="flex flex-row">
                                {indivTask.task.fromTime ? (
                                  <Typography>
                                    Change from{' '}
                                    <span className="font-bold">
                                      {fromTime.format('hh:mm A ')}
                                    </span>
                                    to{' '}
                                    <span className="font-bold">
                                      {toTime.format('hh:mm A')}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography>
                                    Clock-out @{' '}
                                    <Typography className="font-bold">
                                      {toTime.format('hh:mm A')}
                                    </Typography>
                                  </Typography>
                                )}
                              </div>
                            </div>

                            <div className="mb-1">
                              <Typography fontWeight="bold">Address: </Typography>
                              {indivTask.timesheetLog.coordinates?.address ? (
                                <Typography>
                                  {
                                    indivTask.timesheetLog.coordinates!
                                      .address
                                  }
                                </Typography>
                              ) : (
                                <Typography className="text-red-500">
                                  Coordinates not available
                                </Typography>
                              )}
                            </div>

                            <TaskTextComponent
                              label="Requested"
                              value={createdAt.format('ddd MMM. DD, YYYY hh:mm A')}
                            />

                            {createdAt.unix() !== updatedAt.unix() && (
                              <TaskTextComponent
                                label="Last updated"
                                value={updatedAt.format(
                                  'ddd MMM. DD, YYYY hh:mm A',
                                )}
                              />
                            )}
                          </div>

                          <div className="mt-5 flex flex-row items-center justify-around">
                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setStatusClickHandler(indivTask, 5);
                              }}>
                              Approve & change
                            </Button>

                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setStatusClickHandler(indivTask, 4);
                              }}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      );
                    }

                    case 'payroll': {
                      return (
                        <div
                          key={indivTask.task.id}
                          className="p-3 border-[0.5px] rounded">
                          <div className="grid gap-1">
                            <TaskTextComponent
                              label="Task"
                              value="Weekly pay to approve"
                            />

                            <TaskTextComponent
                              label="Name"
                              value={`${indivTask.user.firstname} ${indivTask.user.lastname}`}
                            />

                            <TaskTextComponent
                              label="Status"
                              value={indivTask.task.status.name}
                              labelClass={statusColor}
                            />

                            <TaskTextComponent
                              label="Created"
                              value={createdAt.format('ddd MMM. DD, YYYY hh:mm A')}
                            />

                            <TaskTextComponent
                              label="Last updated"
                              value={updatedAt.format(
                                'ddd MMM. DD, YYYY hh:mm A',
                              )}
                            />
                          </div>

                          <div className="mt-5 flex flex-row items-center justify-around">
                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // setStatusClickHandler(indivTask, 5);
                              }}>
                              Review
                            </Button>

                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setStatusClickHandler(indivTask, 4);
                              }}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      );
                    }

                    case 'leave-request-date': {
                      const leaveRequestDate = dayjs(indivTask.task.date);
                      const startTime = dayjs(
                        indivTask.task.startTime,
                        'HH:mm:ss',
                      );
                      const endTime = dayjs(indivTask.task.endTime, 'HH:mm:ss');
                      const leaveIsPaid = indivTask.task.leaveType?.isPaidLeave;
                      const isPaidTextColor = leaveIsPaid
                        ? 'text-green-500'
                        : 'text-red-500';

                      return (
                        <div
                          key={indivTaskIndex}
                          className="p-3 border-[0.5px] rounded">
                          <div className="grid gap-1">
                            <TaskTextComponent
                              label="Task"
                              value="Leave request approval"
                            />

                            <TaskTextComponent
                              label="Name"
                              value={`${indivTask.user.firstname} ${indivTask.user.lastname}`}
                            />

                            <TaskTextComponent
                              label="Leave Type"
                              value={indivTask.task.leaveType?.name}
                              labelClass={`font-bold ${isPaidTextColor}`}
                            />

                            <TaskTextComponent
                              label="Date"
                              value={leaveRequestDate.format('ddd MMM. DD, YYYY')}
                            />

                            <TaskTextComponent
                              label="Time"
                              value={`${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`}
                            />

                            <TaskTextComponent
                              label="Status"
                              value={indivTask.task.status?.name}
                              labelClass={statusColor}
                            />

                            <TaskTextComponent
                              label="Requested"
                              value={createdAt.format('ddd MMM. DD, YYYY hh:mm A')}
                            />
                          </div>

                          <div className="mt-5 flex flex-row items-center justify-around">
                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setStatusClickHandler(indivTask, 5);
                              }}>
                              Approve
                            </Button>

                            <Button
                              disabled={isLoading}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setStatusClickHandler(indivTask, 4);
                              }}>
                              Cancel
                            </Button>

                            {/* <Button
                              disabled={isLoading}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // setStatusClickHandler(indivTask, 4);
                              }}>
                              Revise
                            </Button> */}
                          </div>
                        </div>
                      );
                    }

                    default:
                      return null;
                  }
                })}

                {Boolean(managerTasksLoaded) && !taskCount && (
                  <Typography className="text-gray-400">
                    {selectedManagerTaskStatus === STATUS_ID_PENDING
                      ? 'Nothing to update.'
                      : 'No task.'}
                  </Typography>
                )}

                {!managerTasksLoaded && (
                  <div className="justify-center items-center">
                    <LoadingFetchData />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        maxWidth="xs"
        disableScrollLock={true}
        onClose={handleCloseTimesheetLogDialog}
        open={openChangeTimesheetLogDialog}>
        <DialogTitle>Change Log</DialogTitle>
        {selectedTimesheetLog ? (
          <DialogContent>
            <div className="grid gap-4">
              <div className="flex flex-row gap-3">
                <Typography fontWeight="bold">
                  Date:
                </Typography>

                <Typography>
                  {dayjs(timesheet?.createdAt).format('ddd. MMM. DD, YYYY')}
                </Typography>
              </div>

              {Boolean(selectedTimesheetLog.coordinates?.address) && (
                <div className="flex flex-row gap-3">
                  <Typography fontWeight="bold">
                    Address:
                  </Typography>

                  <Typography>
                    {selectedTimesheetLog.coordinates?.address}
                  </Typography>
                </div>
              )}

              <div className="flex flex-row gap-3 items-center">
                <Typography fontWeight="bold">
                  {selectedTimesheetLog.type}:
                </Typography>

                <TimePicker
                  value={dayjs(selectedTimesheetLog.time, 'HH:mm:ss')}
                  onChange={newValue => {
                    if (newValue) {
                      const newSelectedTimesheetLog = {...selectedTimesheetLog};
                      newSelectedTimesheetLog.time = newValue.format('HH:mm:ss');
                      setSelectedTimesheetLog(newSelectedTimesheetLog);
                    }
                  }}
                />
              </div>
            </div>
          </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>
                Please select a log.
              </DialogContentText>
            </DialogContent>
          )
        }

        <DialogActions>
          <Button
            color="error"
            onClick={handleCloseTimesheetLogDialog}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={handleSubmitTimesheetLogChange}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

interface TaskTextComponentProps {
  label: string;
  value?: string;
  labelClass?: string;
}

const TaskTextComponent = ({
  label,
  value,
  labelClass,
}: TaskTextComponentProps) => {
  return (
    <div className="flex flex-row gap-1">
      <Typography fontWeight="bold">
        {label}:
      </Typography>
      <Typography className={labelClass}>
        {value}
      </Typography>
    </div>
  );
};

export default TodayLogs;
