import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {TextField} from '@mui/material';
import {Link} from 'react-router-dom';

// Images
import FabricarLogo from '../../../assets/images/logos/fabricar-logo.png';

const ForgotPassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const initialValues = {
    email: '',
  };

  return (
    <div className="lg:flex justify-end">
      <div className="lg:w-1/2 p-5 lg:p-10">
        <div className="border rounded-xl shadow-md bg-[#ffffff]/[0.7] min-h-[90vh] h-full p-5 lg:py-1 xl:w-[80%]">
          <img
            alt="header-logo"
            src={FabricarLogo}
            className="object-contain w-[50%] lg:w-[25%] md:w-[30%] block mx-auto mb-5"
          />

          <h1 className="text-center text-[30px] font-bold text-[#2c63ba] mb-5">
            Hello
          </h1>

          <h1 className="font-thin text-[30px] text-center mb-10">
            Let&apos;s get started
          </h1>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              // same shape as initial values
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
            }) => (
              <Form className="md:mx-10 lg:mx-4">
                <div className="mb-5">
                  <TextField
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    placeholder="Email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={Boolean(touched.email && errors.email) && errors.email}
                  />
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="p-3 block bg-[#50C878] font-bold text-[#ffffff] rounded-full w-full mb-5">
                    Submit reset link
                  </button>
                </div>

                <div className="mb-3 flex justify-center">
                  <Link
                    to="/auth/login"
                    className="font-bold text-center text-[#2c63ba]">
                    Login
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
