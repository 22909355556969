// Context
import ProfileContextProvider from './_context/ProfileContext';

// Components
import UserProfile from './_components/UserProfile';
import EmployeeAgreementHours from './_components/EmployeeAgreementHours';

const Profile = () => {
  return (
    <ProfileContextProvider>
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
        <div className="grid lg:grid-cols-3 gap-2">
          <div className="bg-[#ffffff] border shadow rounded-lg">
            <UserProfile />
          </div>

          <div className="bg-[#ffffff] lg:col-span-2 border shadow rounded-lg">
            <EmployeeAgreementHours />
          </div>
        </div>
      </div>
    </ProfileContextProvider>
  );
};

export default Profile;
