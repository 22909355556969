import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Alert, AlertColor, CircularProgress, Typography} from '@mui/material';
import dayjs from 'dayjs';

// Types
import {ProductScheduleProps, PurchaseOrderProps} from '../../../models/ProductSchedule';

// Context
import {AuthContext} from '../../global/auth/context/AuthContext';
import {
  ProductScheduleContextProvider,
} from './_context/ProductScheduleContext';

// APIs
import {getProductScheduleUserScreen, setPurchaseOrderStatus} from '../_api/web/_api/product-schedule';

// Components
import {LoadingFetchData} from '../../global/_components/Loading';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

const ProductSchedule = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderProps[]>([]);
  const [productSchedule, setProductSchedule] = useState<ProductScheduleProps>();
  const [selectedPurchaseOrderIndex, setSelectedPurchaseOrderIndex] = useState<number>(0);

  // Refs
  const selectedPurchaseOrderIndexRef = useRef(0);

  // Context
  const {authUser} = useContext(AuthContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getProductScheduleUserScreen();

      switch (status) {
        case 200: {
          setPurchaseOrders(
            [
              ...data.data.purchaseOrders,
              ...data.data.unassignedPurchaseOrders,
            ],
          );
          setProductSchedule(data.data.productSchedule);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    const keydownHandler = async (e: KeyboardEvent) => {
      e.preventDefault();
  
      if (e.key === 'ArrowRight') {
        setSelectedPurchaseOrderIndex(currentValue => {
          let newValue = 0;
          if (currentValue < purchaseOrders.length - 1) {
            newValue = currentValue + 1;
          }

          selectedPurchaseOrderIndexRef.current = newValue;
          return newValue;
        });
      } else if (e.key === 'ArrowDown') {
        setSelectedPurchaseOrderIndex(0);
      } else if (e.key === 'ArrowUp') {
        try {
          if (productSchedule) {
            const {status, data} = await setPurchaseOrderStatus(
              productSchedule,
              purchaseOrders[selectedPurchaseOrderIndexRef.current],
            );

            if (status === 200) {
              const newPurchaseOrders = [...purchaseOrders];
              newPurchaseOrders.splice(selectedPurchaseOrderIndexRef.current, 1, data.data.purchaseOrder);
              setPurchaseOrders(newPurchaseOrders);
              document.removeEventListener('keydown', keydownHandler);
            }
          }
        } catch (err) {
          console.log(err);
        }
      } 
    };

    if (purchaseOrders.length && productSchedule) {
      document.addEventListener('keydown', keydownHandler);
    }

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [purchaseOrders, productSchedule]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <ProductScheduleContextProvider>
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
        <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              <span>Schedule for: </span>{productSchedule?.name}
            </Typography>

            {Boolean(authUser?.id) && (
              <button
                type="button"
                disabled={isRefreshing}
                onClick={refreshHandler}>
                {isRefreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>
        </div>

        <div className="grid grid-cols-4 gap-3">
          {purchaseOrders.map((indivPurchaseOrder, indivPurchaseOrderIndex) => {
            let containerClass = 'p-2 border bg-gray-100 rounded flex flex-col gap-1 cursor-pointer hover:border-blue-500 min-w-[400px]';
            if (selectedPurchaseOrderIndex === indivPurchaseOrderIndex) {
              containerClass += ' border-blue-500'
            }

            const deadline = dayjs(indivPurchaseOrder.deadline);
            const orderDate = dayjs(indivPurchaseOrder.orderDate);
            let qty = 0;
            let SKU = '';
            indivPurchaseOrder.purchaseOrderProducts.forEach(indivPurchaseOrderProduct => {
              qty += indivPurchaseOrderProduct.qty;
              SKU = indivPurchaseOrderProduct.supplierSKU;
            });

            let statusColor: AlertColor = 'info';
            
            switch (indivPurchaseOrder.status.id) {
              case 3: {
                statusColor = 'success';
                break;
              }
              case 4: {
                statusColor = 'error';
                break;
              }
            }
            
            return (
              <div
                key={indivPurchaseOrder.id}
                className={containerClass}>
                <div className="flex flex-row justify-between items-center">
                  <Typography>
                    <span className="font-bold">PO: </span>
                    {indivPurchaseOrder.orderNumber.slice(3)}
                  </Typography>

                  <Typography>
                    <span className="font-bold">Date: </span>
                    {orderDate.format('Do MMM. YYYY')}
                  </Typography>
                </div>

                <div className="flex flex-row justify-between items-center">
                  <Typography>
                    <span className="font-bold">Qty: </span>
                    {qty}
                  </Typography>

                  <Typography>
                    <span className="font-bold">SKU: </span>
                    {SKU}
                  </Typography>
                </div>

                <div className="flex flex-row justify-between items-center">
                  <Typography>
                    <span className="font-bold">Promised: </span>
                    {indivPurchaseOrder.deadline ? deadline.format('Do MMM. YYYY') : '-'}
                  </Typography>

                  <Typography>
                    <span className="font-bold">Sold: </span>
                    0 / {qty}
                  </Typography>
                </div>

                {indivPurchaseOrder.purchaseOrderProducts.map(indivPurchaseOrderProduct => {
                  return (
                    <div key={indivPurchaseOrderProduct.id} className="flex flex-row gap-1">
                      <Typography fontWeight="bold">
                        Name:
                      </Typography>

                      <Typography>
                        {indivPurchaseOrderProduct.name}
                      </Typography>
                    </div>
                  );
                })}

                <Alert severity={statusColor}>{indivPurchaseOrder.status.name}</Alert>
              </div>
            );
          })}
        </div>
      </div>
    </ProductScheduleContextProvider>
  );
};

export default ProductSchedule;
