import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {enqueueSnackbar} from 'notistack';
import {DndContext, DragEndEvent, useDraggable, useDroppable} from '@dnd-kit/core';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {isAxiosError} from 'axios';

// Types
import {
  ProductScheduleLabourProps,
  ProductScheduleProps,
  ProductScheduleTypeProps,
  PurchaseOrderProps,
  ProductScheduleLabourForm,
} from '../../../../../models/ProductSchedule';
import {UserProps} from '../../../../../models/User';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIs
import {
  assignProductScheduleLabour,
  deleteProductScheduleLabour,
  getProductSchedule,
  getProductSchedulesScreenData,
  getProductScheduleUsers,
  movePuchaseOrder,
  updateProductScheduleLabour,
} from '../../../_api/mobile/home/product-schedule/product-schedule';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// Utils
import {searchInputCheck} from '../../../../../utils/utils';


type ProductScheduleTabType = 'products' | 'people';

const ProductScheduleContents = () => {
  // Refs
  const dateTodayRef = useRef<Dayjs>(dayjs());
  const currentWeek = dateTodayRef.current.week();
  const searchInputRef = useRef<HTMLDivElement>(null);

  // States
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [selectedProductSchedule, setSelectedProductSchedule] = useState<ProductScheduleProps>();
  const [productScheduleTypes, setProductScheduleTypes] = useState<ProductScheduleTypeProps[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<number>(currentWeek);
  const [searchInput, setSearchInput] = useState<string>('');
  const [productScheduleTab, setProductScheduleTab] = useState<ProductScheduleTabType>('products');

  // Variables
  const numberOfWeeksOfCurrentYear = dateTodayRef.current.isoWeeksInYear();
  const numberOfWeeksOfNextYear = dateTodayRef.current.add(1, 'years').isoWeeksInYear();
  const remainingWeekDifference = numberOfWeeksOfCurrentYear - currentWeek;

  const weeksArrayOfCurrentYear: number[] = Array.from({length: remainingWeekDifference + 1}, (_, i) => (i + currentWeek));
  const weeksArrayOfNextYear: number[] = Array.from({length: numberOfWeeksOfNextYear - remainingWeekDifference - 20}, (_, i) => (i + 1));
  const weeks = weeksArrayOfCurrentYear.concat(weeksArrayOfNextYear);

  // Context
  const {authUser} = useContext(AuthContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getProductSchedulesScreenData();

      if (status === 200) {
        const newProductScheduleTypes = data.data.productScheduleTypes;
        setProductScheduleTypes(newProductScheduleTypes);
        setSelectedProductSchedule(newProductScheduleTypes[0].productSchedules[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
    searchInputRef.current?.focus();
  };

  const productScheduleTabChangeHandler = useCallback(
    (_: React.SyntheticEvent, newValue: ProductScheduleTabType) => {
      setSearchInput('');
      setProductScheduleTab(newValue);
    },
    [],
  );

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />
  } else if (!authUser) {
    return null;
  }

  return (
    <Fragment> 
      <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border">
        <div className="grid lg:grid-cols-5 gap-2">
          <div className="bg-[#ffffff] border border-blue-500 shadow lg:col-span-4 rounded-lg">
            <div className="p-2 md:p-5">
              <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
                <div className="flex flex-row gap-2">
                  <Typography fontSize={20} fontWeight="bold">
                    Product Schedule
                  </Typography>

                  <button
                    type="button"
                    disabled={isRefreshing}
                    onClick={refreshHandler}>
                    {isRefreshing ? (
                      <CircularProgress size={12} />
                    ) : (
                      <RefreshIcon color="primary" fontSize="small" />
                    )}
                  </button>
                </div>
              </div>

              <div className="flex flex-row gap-3 mb-3 items-center">
                <Typography fontWeight="bold" fontSize={18}>
                  Filters: 
                </Typography>

                <FormControl>
                  <InputLabel shrink>Schedule</InputLabel>
                  <Select
                    native
                    autoWidth
                    label="Schedule"
                    value={selectedProductSchedule?.id}
                    onChange={e => {
                      var newSelectedProductSchedule = undefined;

                      productScheduleTypes.forEach(indivProductScheduleType => {
                        indivProductScheduleType.productSchedules.forEach(indivProductSchedule => {
                          if (indivProductSchedule.id === parseInt(e.target.value.toString())) {
                            newSelectedProductSchedule = indivProductSchedule;
                          }
                        });
                      });

                      setSelectedProductSchedule(newSelectedProductSchedule);
                    }}>
                    {productScheduleTypes.map(indivProductScheduleType => {
                      return (
                        <optgroup key={indivProductScheduleType.id} label={indivProductScheduleType.name}>
                          {indivProductScheduleType.productSchedules.map(indivProductSchedule => {
                            return (
                              <option key={indivProductSchedule.id} value={indivProductSchedule.id}>
                                {indivProductSchedule.name}
                              </option>
                            );
                          })}
                        </optgroup>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl>
                  <InputLabel shrink>Week</InputLabel>
                  <Select
                    native
                    autoWidth
                    label="Week"
                    type="number"
                    value={selectedWeek}
                    onChange={e => {
                      setSelectedWeek(e.target.value as number);
                    }}>
                    {weeks.map((indivWeek, indivWeekIndex) => {
                      if (indivWeekIndex >= (weeks.length - 2)) {
                        return null;
                      }

                      return (
                        <option key={indivWeek} value={indivWeek}>
                          Week {indivWeek} {indivWeek < weeks[0] && `(${dayjs().add(1, 'years').format('YYYY')})`}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  sx={{width: 250}}
                  autoComplete="off"
                  autoCorrect="off"
                  ref={searchInputRef}
                  value={searchInput}
                  onChange={searchInputChangeHandler}
                  variant="standard"
                  label={`Search ${productScheduleTab === 'products' ? 'Purchase Order' : 'Person'}`}
                  placeholder="Name, PO#, SKU"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button
                          type="button"
                          onClick={searchInputIconClickHandler}>
                          {searchInput ? (
                            <CancelIcon color="error" />
                          ) : (
                            <SearchIcon />
                          )}
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="ml-auto">
                  <Tabs value={productScheduleTab} onChange={productScheduleTabChangeHandler}>
                    <Tab label="People" value="people" />
                    <Tab label="Products" value="products" />
                  </Tabs>
                </div>
              </div>

              {productScheduleTab === 'people' ? (
                <PeopleTabComponent
                  selectedProductSchedule={selectedProductSchedule}
                  selectedWeek={selectedWeek}
                  weeks={weeks}
                  searchInput={searchInput}
                />
              ) : (
                <ProductsTabComponent
                  selectedProductSchedule={selectedProductSchedule}
                  selectedWeek={selectedWeek}
                  weeks={weeks}
                  searchInput={searchInput}
                />
              )}
            </div>
          </div>

          <div className="bg-[#ffffff] border border-red-500 shadow rounded-lg hover:overflow-y-scroll">
            <div className="p-2 md:p-5">
              <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
                <div className="flex flex-row gap-2">
                  <Typography fontSize={20} fontWeight="bold">
                    Alerts
                  </Typography>

                  <button
                    type="button"
                    disabled={isRefreshing}
                    onClick={refreshHandler}>
                    {isRefreshing ? (
                      <CircularProgress size={12} />
                    ) : (
                      <RefreshIcon color="primary" fontSize="small" />
                    )}
                  </button>
                </div>
              </div>

              <div className="border border-red-500 rounded p-3">
                This is a sample alert.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

interface TabContentComponentProps {
  selectedProductSchedule?: ProductScheduleProps;
  weeks: number[];
  selectedWeek: number;
  searchInput?: string;
}

const ProductsTabComponent = ({
  selectedProductSchedule,
  weeks,
  selectedWeek,
  searchInput,
}: TabContentComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [previousProductScheduleType, setPreviousProductScheduleType] = useState<ProductScheduleTypeProps>();
  const [unassignedPurchaseOrders, setUnassignedPurchaseOrders] = useState<PurchaseOrderProps[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderProps[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Refs
  const dateTodayRef = useRef<Dayjs>(dayjs());

  // Variables
  const selectedWeekIndex = weeks.findIndex(indivWeek => {
    return indivWeek.toString() === selectedWeek.toString();
  });

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedProductSchedule) {
        const {status, data} = await getProductSchedule(selectedProductSchedule);

          if (status === 200) {
            setUnassignedPurchaseOrders(data.data.unassignedPurchaseOrders);
            setPreviousProductScheduleType(data.data.previousProductScheduleType);
            setPurchaseOrders(data.data.purchaseOrders);
          }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedProductSchedule]);

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    setIsSubmitting(true);
    try {
      if (
        selectedProductSchedule &&
        event.active.data &&
        event.active.data.current &&
        event.over &&
        event.over.data &&
        event.over.data.current
      ) {
        const purchaseOrder = event.active.data.current.item as PurchaseOrderProps;
        if (purchaseOrder.currentWeek !== event.over.id) {
          const {status, data} = await movePuchaseOrder(
            selectedProductSchedule,
            purchaseOrder,
            event.over.id as number,
            event.over.data.current.year,
          );

          if (status === 200) {
            const newPurchaseOrders = [...purchaseOrders];
            const newPurchaseOrder = data.data.purchaseOrder;

            // if the purchase order does not have a current week it is assumed that it is unassigned
            if (purchaseOrder.currentWeek) {
              const purchaseOrderIndex = newPurchaseOrders.findIndex(indivPurchaseOrder => {
                return indivPurchaseOrder.id === newPurchaseOrder.id;
              });
              newPurchaseOrders.splice(purchaseOrderIndex, 1, newPurchaseOrder);
              setPurchaseOrders(newPurchaseOrders);
            } else {
              const newUnassignedPurchaseOrders = [...unassignedPurchaseOrders];
              const purchaseOrderIndex = newUnassignedPurchaseOrders.findIndex(indivPurchaseOrder => {
                return indivPurchaseOrder.id === newPurchaseOrder.id;
              });
              newUnassignedPurchaseOrders.splice(purchaseOrderIndex, 1);
              newPurchaseOrders.push(newPurchaseOrder);
              setUnassignedPurchaseOrders(newUnassignedPurchaseOrders);
            }

            setPurchaseOrders(newPurchaseOrders);
            enqueueSnackbar(
              data.message,
              {
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong, please contact your admin',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [selectedProductSchedule, purchaseOrders, unassignedPurchaseOrders]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />
  } else if (!previousProductScheduleType) {
    return (
      <Typography>
        Empty
      </Typography>
    );
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="grid grid-cols-4 gap-3">
        <ColumnComponent
          opacity={isSubmitting ? 50 : 100}
          disabled={true}
          productScheduleType={previousProductScheduleType}>
          <div className="flex flex-col gap-3">
            <div className="border-b pb-1">
              <Typography fontWeight="bold" fontSize={18}>
                {previousProductScheduleType.name}
              </Typography>
            </div>

            {unassignedPurchaseOrders
              .filter(indivUnassignedPurchaseOrder => {
                let SKU = '';
                let name = '';
                indivUnassignedPurchaseOrder.purchaseOrderProducts.forEach(indivUnassignedPurchaseOrderProduct => {
                  SKU = indivUnassignedPurchaseOrderProduct.supplierSKU;
                  name = indivUnassignedPurchaseOrderProduct.name;
                });
                if (searchInput) {
                  if (
                    searchInputCheck(searchInput, indivUnassignedPurchaseOrder.orderNumber ?? '') ||
                    searchInputCheck(searchInput, name ?? '') ||
                    searchInputCheck(searchInput, SKU ?? '')
                  ) {
                    return indivUnassignedPurchaseOrder;
                  }
                } else {
                  return indivUnassignedPurchaseOrder;
                }

                return null;
              })
              .map(indivUnassignedPurchaseOrder => {
                const deadline = dayjs(indivUnassignedPurchaseOrder.deadline);
                const orderDate = dayjs(indivUnassignedPurchaseOrder.orderDate);
                let qty = 0;
                let SKU = '';
                indivUnassignedPurchaseOrder.purchaseOrderProducts.forEach(indivUnassignedPurchaseOrderProduct => {
                  qty += indivUnassignedPurchaseOrderProduct.qty;
                  SKU = indivUnassignedPurchaseOrderProduct.supplierSKU;
                });

                let status = indivUnassignedPurchaseOrder.status.name;
                let statusColor: AlertColor = 'error';

                switch (indivUnassignedPurchaseOrder.status.id) {
                  case 2: {
                    status = 'Not cut';
                    break;
                  }

                  case 3: {
                    status = 'Cut and ready';
                    statusColor = 'info';
                    break;
                  }
                }

                return (
                  <RowComponent key={indivUnassignedPurchaseOrder.id} item={indivUnassignedPurchaseOrder}>
                    <div className="p-2 border bg-gray-100 rounded flex flex-col gap-1 cursor-pointer hover:border-blue-500">
                      <div className="flex flex-row justify-between items-center">
                        <Typography>
                          <span className="font-bold">PO: </span>
                          {indivUnassignedPurchaseOrder.orderNumber.slice(3)}
                        </Typography>

                        <Typography>
                          <span className="font-bold">Date: </span>
                          {orderDate.format('Do MMM. YYYY')}
                        </Typography>
                      </div>

                      <div className="flex flex-row justify-between items-center">
                        <Typography>
                          <span className="font-bold">Qty: </span>
                          {qty}
                        </Typography>

                        <Typography>
                          <span className="font-bold">SKU: </span>
                          {SKU}
                        </Typography>
                      </div>

                      <div className="flex flex-row justify-between items-center">
                        <Typography>
                          <span className="font-bold">Promised: </span>
                          {indivUnassignedPurchaseOrder.deadline ? deadline.format('Do MMM. YYYY') : '-'}
                        </Typography>

                        <Typography>
                          <span className="font-bold">Sold: </span>
                          0 / {qty}
                        </Typography>
                      </div>

                      {indivUnassignedPurchaseOrder.purchaseOrderProducts.map(indivPurchaseOrderProduct => {
                        return (
                          <div key={indivPurchaseOrderProduct.id}>
                            <Typography fontWeight="bold">
                              Name:
                            </Typography>

                            <Typography>
                              {indivPurchaseOrderProduct.name}
                            </Typography>
                          </div>
                        );
                      })}

                      {previousProductScheduleType.id !== 1 && (
                        <Alert severity={statusColor}>{status}</Alert>
                      )}
                    </div>
                  </RowComponent>
                );
              })
            }
          </div>
        </ColumnComponent>

        {weeks
          .filter((indivWeek, currentWeekIndex) => {
            if (
              currentWeekIndex >= selectedWeekIndex &&
              currentWeekIndex <= (selectedWeekIndex + 2)
            ) {
              return indivWeek;
            }

            return null;
          })
          .map(indivWeek => {
            let columnDate = dayjs().week(indivWeek).set('day', 1);
            const year = indivWeek < weeks[0] ? dayjs().add(1, 'years').year() : columnDate.year();
            if (year > dateTodayRef.current.year()) {
              columnDate = dateTodayRef.current.set('year', year).week(indivWeek).set('day', 1);
            }

            return (
              <ColumnComponent
                opacity={isSubmitting ? 50 : 100}
                disabled={isSubmitting}
                key={indivWeek}
                week={indivWeek}
                year={year}>
                <div className="flex flex-col gap-3">
                  <div className="border-b pb-1 flex flex-row justify-between">
                    <Typography fontWeight="bold" fontSize={18}>
                      Week {indivWeek}
                    </Typography>
                    <Typography fontWeight="bold" fontSize={18} className="text-gray-500">
                      {columnDate.format('Do MMM')} {year}
                    </Typography>
                  </div>

                  {purchaseOrders
                    .filter(indivPurchaseOrder => {
                      if (indivPurchaseOrder.currentWeek === indivWeek) {
                        return purchaseOrders;
                      } else {
                        return null;
                      }
                    })
                    .filter(indivUnassignedPurchaseOrder => {
                      let SKU = '';
                      let name = '';
                      indivUnassignedPurchaseOrder.purchaseOrderProducts.forEach(indivUnassignedPurchaseOrderProduct => {
                        SKU = indivUnassignedPurchaseOrderProduct.supplierSKU;
                        name = indivUnassignedPurchaseOrderProduct.name;
                      });
                      if (searchInput) {
                        if (
                          searchInputCheck(searchInput, indivUnassignedPurchaseOrder.orderNumber ?? '') ||
                          searchInputCheck(searchInput, name ?? '') ||
                          searchInputCheck(searchInput, SKU ?? '')
                        ) {
                          return indivUnassignedPurchaseOrder;
                        }
                      } else {
                        return indivUnassignedPurchaseOrder;
                      }

                      return null;
                    })
                    .map(indivPurchaseOrder => {
                      const deadline = dayjs(indivPurchaseOrder.deadline);
                      const orderDate = dayjs(indivPurchaseOrder.orderDate);
                      let qty = 0;
                      let SKU = '';
                      indivPurchaseOrder.purchaseOrderProducts.forEach(indivPurchaseOrderProduct => {
                        qty += indivPurchaseOrderProduct.qty;
                        SKU = indivPurchaseOrderProduct.supplierSKU;
                      });

                      let statusColor: AlertColor = 'info';
                      
                      switch (indivPurchaseOrder.status.id) {
                        case 3: {
                          statusColor = 'success';
                          break;
                        }
                        case 4: {
                          statusColor = 'error';
                          break;
                        }
                      }

                      return (
                        <RowComponent key={indivPurchaseOrder.id} item={indivPurchaseOrder}>
                          <div className="p-2 border bg-gray-100 rounded flex flex-col gap-1 cursor-pointer hover:border-blue-500">
                            <div className="flex flex-row justify-between items-center">
                              <Typography>
                                <span className="font-bold">PO: </span>
                                {indivPurchaseOrder.orderNumber.slice(3)}
                              </Typography>

                              <Typography>
                                <span className="font-bold">Date: </span>
                                {orderDate.format('Do MMM. YYYY')}
                              </Typography>
                            </div>

                            <div className="flex flex-row justify-between items-center">
                              <Typography>
                                <span className="font-bold">Qty: </span>
                                {qty}
                              </Typography>

                              <Typography>
                                <span className="font-bold">SKU: </span>
                                {SKU}
                              </Typography>
                            </div>

                            <div className="flex flex-row justify-between items-center">
                              <Typography>
                                <span className="font-bold">Promised: </span>
                                {indivPurchaseOrder.deadline ? deadline.format('Do MMM. YYYY') : '-'}
                              </Typography>

                              <Typography>
                                <span className="font-bold">Sold: </span>
                                0 / {qty}
                              </Typography>
                            </div>

                            {indivPurchaseOrder.purchaseOrderProducts.map(indivPurchaseOrderProduct => {
                              return (
                                <div key={indivPurchaseOrderProduct.id}>
                                  <Typography fontWeight="bold">
                                    Name:
                                  </Typography>

                                  <Typography>
                                    {indivPurchaseOrderProduct.name}
                                  </Typography>
                                </div>
                              );
                            })}

                            <div className="flex flex-row items-center gap-3 mb-3">
                              <Typography>
                                <span className="font-bold">Labour Hours:</span>
                              </Typography>

                              <TextField
                                type="number"
                                variant="standard"
                                value={0}
                                name="workHours"
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // error={Boolean(touched.workHours && errors.workHours)}
                                // helperText={touched.workHours && errors.workHours}
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                sx={{
                                  width: 100,
                                  textAlign: 'right',
                                }}
                                inputProps={{
                                  sx: {
                                    textAlign: 'right',
                                  },
                                }}
                              />

                              <Typography>
                                mins
                              </Typography>
                            </div>

                            <Alert severity={statusColor}>{indivPurchaseOrder.status.name}</Alert>
                          </div>
                        </RowComponent>
                      );
                    })
                  }
                </div>
              </ColumnComponent>
            );
          })
        }
      </div>
    </DndContext>
  );
};

const PeopleTabComponent = ({
  selectedProductSchedule,
  weeks,
  selectedWeek,
  searchInput,
}: TabContentComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [productScheduleLabours, setProductScheduleLabours] =
    useState<ProductScheduleLabourProps[]>([]);
  const [selectedProductScheduleLabour, setSelectedProductScheduleLabour] =
    useState<ProductScheduleLabourProps>();

  // Refs
  const dateTodayRef = useRef<Dayjs>(dayjs());

  // Variables
  const selectedWeekIndex = weeks.findIndex(indivWeek => {
    return indivWeek.toString() === selectedWeek.toString();
  });

  const initialValues: ProductScheduleLabourForm = {
    id: selectedProductScheduleLabour?.id ?? 0,
    week: selectedProductScheduleLabour?.week ?? 0,
    year: selectedProductScheduleLabour?.year ?? 0,
    workHours: selectedProductScheduleLabour?.workHours ?? 0,
  };

  const validationSchemaUserForm = Yup.object().shape({
    id: Yup.number().required('Mobile Number is required'),
    week: Yup.number().required('Week is required'),
    year: Yup.number().required('Year is required'),
    workHours: Yup.number().required('Workhours is required'),
  });

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedProductSchedule) {
        const {status, data} = await getProductScheduleUsers(selectedProductSchedule);

        if (status === 200) {
          setUsers(data.data.users);
          setProductScheduleLabours(data.data.productScheduleLabours);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedProductSchedule]);

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    setIsSubmitting(true);
    try {
      if (
        selectedProductSchedule &&
        event.active.data &&
        event.active.data.current &&
        event.over &&
        event.over.data &&
        event.over.data.current
      ) {
        const productScheduleLabour = event.active.data.current.item as ProductScheduleLabourProps;
        const week = event.over.data.current.week;

        if (productScheduleLabour.week === week) {
          setSelectedProductScheduleLabour(productScheduleLabour);
          return null;
        }

        let productScheduleLabourExists = false;

        productScheduleLabours.forEach(indivProductScheduleLabour => {
          if (
            indivProductScheduleLabour.user.id === productScheduleLabour.user.id &&
            week === indivProductScheduleLabour.week
          ) {
            productScheduleLabourExists = true;
          }
        });

        if (productScheduleLabourExists) {
          enqueueSnackbar(
            'Person is already assigned to that week',
            {
              autoHideDuration: 3000,
              variant: 'warning',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
          return null;
        }

        if (productScheduleLabour.week === 0) {
          productScheduleLabour.id = 0;
        }

        const {status, data} = await assignProductScheduleLabour(
          selectedProductSchedule,
          productScheduleLabour,
          week,
        );

        const newProductScheduleLabours = [...productScheduleLabours];

        switch (status) {
          case 201: {
            newProductScheduleLabours.push(data.data.productScheduleLabour);
            break;
          }
          case 200: {
            const productScheduleLabourIndex = newProductScheduleLabours.findIndex(indivProductScheduleLabour => {
              return indivProductScheduleLabour.id === productScheduleLabour.id;
            });

            if (productScheduleLabourIndex >= 0) {
              newProductScheduleLabours.splice(productScheduleLabourIndex, 1, data.data.productScheduleLabour);
            }
          }
        }

        setProductScheduleLabours(newProductScheduleLabours);
        enqueueSnackbar(
          data.message,
          {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong, please contact your admin',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [selectedProductSchedule, productScheduleLabours]);

  const handleCloseUserFormDialog = () => {
    setSelectedProductScheduleLabour(undefined);
  };

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />
  }

  return (
    <Fragment>
      <DndContext onDragEnd={handleDragEnd}>
        <div className="grid grid-cols-4 gap-3">
          <ColumnComponent opacity={100} disabled={true}>
            <div className="flex flex-col gap-3">
              <div className="border-b pb-1">
                <Typography fontWeight="bold" fontSize={18}>
                  Users
                </Typography>
              </div>

              {users
                .map(indivUser => {
                  const productScheduleLabour: ProductScheduleLabourProps = {
                    id: indivUser.id + Math.random(),
                    user: indivUser,
                    week: 0,
                    year: 0,
                    workHours: 0,
                  };

                  const userFullName = `${indivUser.firstname} ${indivUser.lastname}`;

                  if (
                    searchInput &&
                      !userFullName
                        .toLocaleLowerCase()
                        .includes(searchInput.toLocaleLowerCase())
                  ) {
                    return null;
                  }

                  return (
                    <RowComponent key={indivUser.id} item={productScheduleLabour}>
                      <div className="p-2 border bg-gray-100 rounded flex flex-col gap-1 cursor-pointer hover:border-blue-500">
                        <div className="border-[#000000] border-b pb-1 mb-2">
                          <Typography fontWeight="bold" fontSize={16}>
                            {indivUser.firstname} {indivUser.lastname}
                          </Typography>
                        </div>

                        <div className="flex items-center gap-1 mb-1">
                          <WorkIcon fontSize="small" />
                          <Typography variant="body2">
                            {indivUser.role}
                          </Typography>
                        </div>

                        <div className="flex items-center gap-1">
                          <EmailIcon fontSize="small" />
                          <Typography variant="body2">
                            {indivUser.email}
                          </Typography>
                        </div>
                      </div>
                    </RowComponent>
                  );
                })}
            </div>
          </ColumnComponent>

          {weeks
            .filter((indivWeek, currentWeekIndex) => {
              if (
                currentWeekIndex >= selectedWeekIndex &&
                currentWeekIndex <= (selectedWeekIndex + 2)
              ) {
                return indivWeek;
              }

              return null;
            })
            .map(indivWeek => {
              let columnDate = dayjs().week(indivWeek).set('day', 1);
              const year = indivWeek < weeks[0] ? dayjs().add(1, 'years').year() : columnDate.year();
              if (year > dateTodayRef.current.year()) {
                columnDate = dateTodayRef.current.set('year', year).week(indivWeek).set('day', 1);
              }

              const productScheduleLaboursFiltered = productScheduleLabours.filter(indivProductScheduleLabour => {
                if (indivProductScheduleLabour.week === indivWeek) {
                  return indivProductScheduleLabour;
                }

                return null;
              });

              return (
                <ColumnComponent
                  key={indivWeek}
                  opacity={isSubmitting ? 50 : 100}
                  disabled={isSubmitting}
                  week={indivWeek}
                  year={year}>
                  <div className="flex flex-col gap-3">
                    <div className="border-b pb-1 flex flex-row justify-between">
                      <Typography fontWeight="bold" fontSize={18}>
                        Week {indivWeek}
                      </Typography>
                      <Typography fontWeight="bold" fontSize={18} className="text-gray-500">
                        {columnDate.format('Do MMM')} {year}
                      </Typography>
                    </div>

                    {productScheduleLaboursFiltered.map(indivProductScheduleLabour => {
                      const user = indivProductScheduleLabour.user;
                      const userFullName = `${user.firstname} ${user.lastname}`;

                      if (
                        searchInput &&
                          !userFullName
                            .toLocaleLowerCase()
                            .includes(searchInput.toLocaleLowerCase())
                      ) {
                        return null;
                      }

                      return (
                        <RowComponent
                          key={indivProductScheduleLabour.id}
                          item={indivProductScheduleLabour}>
                          <div
                            key={indivProductScheduleLabour.id}
                            className="p-2 border bg-gray-100 rounded flex flex-col gap-1 cursor-pointer hover:border-blue-500">
                            <div className="flex-1">
                              <div className="border-[#000000] border-b pb-1 mb-2 flex flex-row justify-between items-center">
                                <Typography fontWeight="bold" fontSize={16}>
                                  {user.firstname} {user.lastname}
                                </Typography>

                                <div className="flex flex-row items-center gap-1">
                                  <AccessTimeFilledIcon fontSize="small" />
                                  <Typography variant="body2">
                                    {indivProductScheduleLabour.workHours} hours
                                  </Typography>
                                </div>
                              </div>

                              <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <WorkIcon fontSize="small" />
                                  <Typography variant="body2">
                                    {user.role}
                                  </Typography>
                                </div>

                                <div className="flex items-center gap-1">
                                  <EmailIcon fontSize="small" />
                                  <Typography variant="body2">
                                    {user.email}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </RowComponent>
                      );
                    })}
                  </div>
                </ColumnComponent>
              );
            })
          }
        </div>
      </DndContext>
       
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchemaUserForm}
        onSubmit={async (values, {resetForm}) => {
          try {
            if (selectedProductScheduleLabour && selectedProductSchedule) {
              const {status, data} = await updateProductScheduleLabour(selectedProductSchedule, values);

              if (status === 200) {
                const newProductScheduleLabours = [...productScheduleLabours];
                const productScheduleLabourIndex = newProductScheduleLabours.findIndex(indivProductScheduleLabour => {
                  return indivProductScheduleLabour.id === selectedProductScheduleLabour.id;
                });
                if (productScheduleLabourIndex >= 0) {
                  newProductScheduleLabours.splice(productScheduleLabourIndex, 1, data.data.productScheduleLabour);
                }
                setProductScheduleLabours(newProductScheduleLabours);
                resetForm();
                setSelectedProductScheduleLabour(undefined);
                enqueueSnackbar(
                  data.message,
                  {
                    autoHideDuration: 3000,
                    variant: 'success',
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                  },
                );
              }
            }
          } catch (err) {
            enqueueSnackbar(
              'Something went wrong, please contact your administrator',
              {
                autoHideDuration: 3000,
                variant: 'error',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
          }
        }}>
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
        }) => (
          <Dialog
            disableScrollLock={true}
            onClose={() => {
              handleCloseUserFormDialog();
              handleReset();
            }}
            
            open={Boolean(selectedProductScheduleLabour)}>
            <DialogTitle>
              <div className="flex flex-row justify-between items-center gap-5">
                <Typography fontSize="inherit" fontWeight="inherit">
                  Week {values.week}
                </Typography>

                <Typography fontSize="inherit" fontWeight="inherit" className="!text-gray-500">
                  {dayjs().week(values.week ?? 0).format('Do MMM.')} {values.year}
                </Typography>
              </div>
              
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col gap-3">
                <Typography>
                  <span className="font-bold">Person: </span>
                  {selectedProductScheduleLabour?.user.firstname}
                  {selectedProductScheduleLabour?.user.lastname}
                </Typography>

                <Typography>
                  <span className="font-bold">Email: </span>
                  {selectedProductScheduleLabour?.user.email}
                </Typography>

                <Typography>
                  <span className="font-bold">Role: </span>
                  {selectedProductScheduleLabour?.user.role}
                </Typography>

                <div className="flex flex-row items-center gap-3">
                  <Typography>
                    <span className="font-bold">Work Hours:</span>
                  </Typography>

                  <TextField
                    type="number"
                    variant="standard"
                    value={values.workHours}
                    name="workHours"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.workHours && errors.workHours)}
                    helperText={touched.workHours && errors.workHours}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    sx={{
                      width: 100,
                      textAlign: 'right',
                    }}
                    inputProps={{
                      sx: {
                        textAlign: 'right',
                      },
                    }}
                  />

                  <Typography>
                    hours
                  </Typography>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <div className="flex flex-row items-center justify-between">
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  color="error"
                  onClick={async () => {
                    try {
                      if (selectedProductScheduleLabour && selectedProductSchedule) {
                        const {status, data} = await deleteProductScheduleLabour(selectedProductSchedule, values);
          
                        if (status === 200) {
                          const newProductScheduleLabours = [...productScheduleLabours];
                          const productScheduleLabourIndex = newProductScheduleLabours.findIndex(indivProductScheduleLabour => {
                            return indivProductScheduleLabour.id === selectedProductScheduleLabour.id;
                          });
                          if (productScheduleLabourIndex >= 0) {
                            newProductScheduleLabours.splice(productScheduleLabourIndex, 1);
                          }
                          setProductScheduleLabours(newProductScheduleLabours);
                          handleReset();
                          setSelectedProductScheduleLabour(undefined);
                          enqueueSnackbar(
                            data.message,
                            {
                              autoHideDuration: 3000,
                              variant: 'warning',
                              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            },
                          );
                        }
                      }
                    } catch (err) {
                      enqueueSnackbar(
                        'Something went wrong, please contact your administrator',
                        {
                          autoHideDuration: 3000,
                          variant: 'error',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                    }
                  }}>
                  Remove
                </Button>

                <div className="ml-10">
                  <Button
                    disabled={isSubmitting}
                    color="error"
                    onClick={handleCloseUserFormDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isSubmitting}
                    color='primary'
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Update
                  </Button>
                </div>
              </div>
              
            </DialogActions>
          </Dialog>
        )}
      </Formik>
     
    </Fragment>
  );
};

interface ColumnComponentProps {
  children: React.ReactNode;
  productScheduleType?: ProductScheduleTypeProps,
  week?: number;
  year?: number;
  disabled?: boolean;
  opacity: number;
}

const ColumnComponent = ({
  children,
  productScheduleType,
  week,
  year,
  disabled,
  opacity,
}: ColumnComponentProps) => {
  const {isOver, setNodeRef} = useDroppable({
    id: productScheduleType?.id ?? week ?? Math.random(),
    disabled,
    data: {
      week,
      year,
    },
  });
  
  return (
    <div
      ref={setNodeRef}
      className={`rounded border shadow-lg p-3 ${isOver ? 'border border-green-500' : ''} opacity-[${opacity}]`}>
      {children}
    </div>
  );
};

interface RowComponentProps {
  children: React.ReactNode;
  item: PurchaseOrderProps | ProductScheduleLabourProps;
}

const RowComponent = ({
  children,
  item,
}: RowComponentProps) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: item.id,
    data: {
      item,
    },
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    opacity: 0.8,
  } : undefined;
  
  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

export default ProductScheduleContents;
