import {useCallback, useContext, useEffect, useState} from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

// Types
import {TimesheetLogChangeProps} from '../../../../../../models/Timesheet';

// Context
import {HomeContext} from '../../_context/HomeContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// APIs
import {getTimesheetLogChanges} from '../_api/timeclock';

// Utils
import {
  STATUS_ID_APPROVED,
  STATUS_ID_CANCELLED,
} from '../../../../../../utils/constants';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

const ChangeRequests = () => {
  // Context
  const {updateCount} = useContext(HomeContext);

  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [timesheetLogChanges, setTimesheetLogChanges] = useState<TimesheetLogChangeProps[]>([]);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getTimesheetLogChanges();

      if (status === 200) {
        setTimesheetLogChanges(data.data.timesheetLogChanges);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-col md:flex-1 md:flex-row gap-1 md:items-center md:justify-between">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Change Requests
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="md:w-[150px] lg:w-auto xl:md:w-[150px]">
                Date
              </TableCell>
              <TableCell
                className="md:w-[150px] lg:w-auto xl:md:w-[150px]">
                Type
              </TableCell>
              <TableCell>
                Request
              </TableCell>
              <TableCell className="md:w-[150px] lg:w-auto xl:md:w-[150px]">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheetLogChanges.map(indivTimesheetLogChange => {
              const date = dayjs(indivTimesheetLogChange.timesheetLog.createdAt);
              const fromTime = dayjs(
                indivTimesheetLogChange.fromTime,
                'HH:mm:ss',
              );
              const toTime = dayjs(indivTimesheetLogChange.toTime, 'HH:mm:ss');
              let statusColor = 'text-blue-500';

              switch (indivTimesheetLogChange.status.id) {
                case STATUS_ID_APPROVED: {
                  statusColor = 'text-green-500';
                  break;
                }
                case STATUS_ID_CANCELLED: {
                  statusColor = 'text-red-500';
                  break;
                }
              }

              return (
                <TableRow key={indivTimesheetLogChange.id}>
                  <TableCell>
                    {date.format('ddd. MMM. DD, YYYY')}
                  </TableCell>
                  <TableCell>
                    {indivTimesheetLogChange.timesheetLog.type}
                  </TableCell>
                  <TableCell>
                    {indivTimesheetLogChange.fromTime ? (
                      <Typography>
                        Change from{' '}
                        <span className="font-bold">
                          {fromTime.format('hh:mm A ')}
                        </span>
                        to{' '}
                        <span className="font-bold">
                          {toTime.format('hh:mm A')}
                        </span>
                      </Typography>
                    ) : (
                      <Typography>
                        Clock-out @{' '}
                        <span className="font-bold">
                          {toTime.format('hh:mm A')}
                        </span>
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold" className={statusColor}>
                      {indivTimesheetLogChange.status.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ChangeRequests;
