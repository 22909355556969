import {useState, createContext, useCallback} from 'react';

// Models
import {AuthTokenProps, WebAccessType} from '../../../../models/Auth';
import {UserProps} from '../../../../models/User';
import {RouteProps} from '../../../../models/Route';

// Utils
import * as AuthHelper from '../utils/AuthHelpers';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  auth?: AuthTokenProps;
  saveAuth: (auth: AuthTokenProps | undefined) => void;
  authUser?: UserProps;
  setAuthUser: React.Dispatch<
    React.SetStateAction<UserProps | undefined>
  >;
  routes: RouteProps[];
  setRoutes: React.Dispatch<
    React.SetStateAction<RouteProps[]>
  >;
  notificationsCount: number;
  setNotificationsCount: React.Dispatch<
    React.SetStateAction<number>
  >;
  webAccess?: WebAccessType;
  setWebAccess: React.Dispatch<
    React.SetStateAction<WebAccessType | undefined>
  >;
  logout: () => void;
}

const initialAuthState = {
  auth: AuthHelper.getAuth(),
  saveAuth: () => {},
  authUser: undefined,
  setAuthUser: () => {},
  routes: [],
  setRoutes: () => {},
  notificationsCount: 0,
  setNotificationsCount: () => {},
  webAcess: undefined,
  setWebAccess: () => {},
  logout: () => {},
};

export const AuthContext = createContext<AuthContextProps>(initialAuthState);

const AuthProvider = ({children}: AuthProviderProps) => {
  const [auth, setAuth] = useState<AuthTokenProps | undefined>(AuthHelper.getAuth());
  const [authUser, setAuthUser] = useState<UserProps | undefined>();
  const [routes, setRoutes] = useState<RouteProps[]>([]);
  const [webAccess, setWebAccess] = useState<WebAccessType | undefined>();
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const saveAuth = (authQuery: AuthTokenProps | undefined) => {
    setAuth(authQuery);
    if (authQuery) {
      AuthHelper.setAuth(authQuery);
    } else {
      AuthHelper.removeAuth();
    }
  };

  const logout = useCallback(() => {
    saveAuth(undefined);
    setAuthUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        authUser,
        setAuthUser,
        routes,
        setRoutes,
        notificationsCount,
        setNotificationsCount,
        webAccess,
        setWebAccess,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
