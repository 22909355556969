import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, TextField, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {Form, Formik} from 'formik';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {SafetyContext} from '../_context/SafetyContext';

// Types
import {SafetyReportResponseProps} from '../../../../../models/SafetyReport';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIs
import {getSafetyReportResponses, postSafetyReportResponse, setSafetyReportStatus} from '../../../_api/mobile/safety/safety';

// Utils
import {
  STATUS_ID_CANCELLED,
  STATUS_ID_COMPLETED,
  STATUS_ID_PENDING,
} from '../../../../../utils/constants';
import { enqueueSnackbar } from 'notistack';

const SelectedSafetyReport = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [safetyReportResponses, setSafetyReportResponses] =
    useState<SafetyReportResponseProps[]>([]);

  // Context
  const {authUser} = useContext(AuthContext);
  const {
    updateCount,
    selectedSafetyReport,
    setSelectedSafetyReport,
  } = useContext(SafetyContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedSafetyReport) {
        const {status, data} = await getSafetyReportResponses(selectedSafetyReport);

        if (status === 200) {
          setSafetyReportResponses(data.data.safetyReportResponses);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedSafetyReport]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  if (!selectedSafetyReport || !selectedSafetyReport) {
    return (
      <div className="p-2 md:p-5">
        <Typography className="text-gray-500">
          Please select a report
        </Typography>
      </div>
    );
  } else if (!init) {
    return <LoadingFetchData />;
  }

  const safetyReportDateTime = dayjs(selectedSafetyReport.createdAt);
  let statusText = selectedSafetyReport.status?.name;
  let statusTextColor = 'text-blue-500';

  switch (selectedSafetyReport.status?.id) {
    case STATUS_ID_COMPLETED: {
      statusText = 'Resolved';
      statusTextColor = 'text-green-500';
      break;
    }
    case STATUS_ID_CANCELLED: {
      statusTextColor = 'text-red-500';
      break;
    }
  }

  return (
    <Formik
      initialValues={{
        response: '',
      }}
      validate={values => {
        const errors: {response?: string;} = {};

        if (!values.response) {
          errors.response = 'Response is required.'
        }

        return errors;
      }}
      onSubmit={async (values, {resetForm, setSubmitting}) => {
        try {
          const {status, data} = await postSafetyReportResponse(selectedSafetyReport, values.response);

          if (status === 201) {
            const newSafetyReportResponses = [...safetyReportResponses];
            newSafetyReportResponses.push(data.data.safetyReportResponse);
            setSafetyReportResponses(newSafetyReportResponses);

            enqueueSnackbar(
              'Response posted.',
              {
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
          }
        } catch (err) {
          console.log(err);
          enqueueSnackbar(
            'Something went wrong, please contact your administrator.',
            {
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        } finally {
          setSubmitting(false);
          resetForm();
        }
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
      }) => (
        <Form>
          <div className="p-2 md:p-5">
            <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
              <div className="flex flex-row gap-2">
                <Typography fontSize={20} fontWeight="bold">
                  Incident report responses
                </Typography>

                {Boolean(authUser?.id) && (
                  <button
                    type="button"
                    disabled={isRefreshing}
                    onClick={refreshHandler}>
                    {isRefreshing ? (
                      <CircularProgress size={12} />
                    ) : (
                      <RefreshIcon color="primary" fontSize="small" />
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">
                  Date:
                </Typography>
                <Typography>
                  {safetyReportDateTime.format('ddd MMM. DD, YYYY - hh:mm A')}
                </Typography>
              </div>

              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">
                  Status:
                </Typography>
                <Typography className={`!font-bold ${statusTextColor}`}>
                  {statusText}
                </Typography>
              </div>

              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">
                  Sender:
                </Typography>
                <Typography>
                  {selectedSafetyReport.user.firstname} {selectedSafetyReport.user.lastname}
                </Typography>
              </div>

              <div className="border-b pb-3">
                <div className="flex flex-col gap-2">
                  <Typography fontWeight="bold">
                    Report:
                  </Typography>
                  <Typography>
                    {selectedSafetyReport.report}
                  </Typography>
                </div>
              </div>
              
              <Typography fontWeight="bold">
                Comments:
              </Typography>

              {safetyReportResponses.length ? safetyReportResponses.map(indivResponse => {
                const safetResponseDateTime = dayjs(indivResponse.createdAt);

                return (
                  <div key={indivResponse.id}>
                    <Typography>
                      {indivResponse.response}
                    </Typography>
                    <Typography variant="caption" className="text-gray-500">
                      {indivResponse.user?.firstname} {indivResponse.user?.lastname} - {safetResponseDateTime.format('ddd MMM. DD, YYYY @ hh:mm A')}
                    </Typography>
                  </div>
                );
              }) : (
                <Typography variant="caption" className="text-gray-500">
                  No response yet.
                </Typography>
              )}

              {selectedSafetyReport.status.id === STATUS_ID_PENDING && (
                <Fragment>
                  <TextField
                    name="response"
                    rows={3}
                    value={values.response}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    variant="outlined"
                    placeholder="Please let us know what you think."
                    onBlur={handleBlur}
                    error={Boolean(touched.response && errors.response)}
                    helperText={touched.response && errors.response}
                  />

                  <div className="flex flex-row gap-3 justify-end">
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="error"
                      onClick={handleReset}>
                      Clear
                    </Button>

                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit">
                      Submit
                    </Button>
                  </div>
                  
                  <div className="flex flex-col gap-3">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={async () => {
                        setSubmitting(true);
                        try {
                          const {status, data} = await setSafetyReportStatus(
                            selectedSafetyReport,
                            3,
                          );
                    
                          if (status === 200) {
                            setSelectedSafetyReport(data.data.safetyReport);
                            enqueueSnackbar(
                              'Report approved.',
                              {
                                autoHideDuration: 3000,
                                variant: 'success',
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                              },
                            );
                          }
                        } catch (err) {
                          console.log(err);
                          enqueueSnackbar(
                            'Something went wrong, please contact your administrator.',
                            {
                              autoHideDuration: 3000,
                              variant: 'error',
                              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            },
                          );
                        } finally {
                          setSubmitting(false);
                        }
                      }}>
                      Resolve
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      onClick={async () => {
                        setSubmitting(true);
                        try {
                          const {status, data} = await setSafetyReportStatus(
                            selectedSafetyReport,
                            4,
                          );
                    
                          if (status === 200) {
                            setSelectedSafetyReport(data.data.safetyReport);
                            enqueueSnackbar(
                              'Report cancelled.',
                              {
                                autoHideDuration: 3000,
                                variant: 'error',
                                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                              },
                            );
                          }
                        } catch (err) {
                          console.log(err);
                          enqueueSnackbar(
                            'Something went wrong, please contact your administrator.',
                            {
                              autoHideDuration: 3000,
                              variant: 'error',
                              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            },
                          );
                        } finally {
                          setSubmitting(false);
                        }
                      }}>
                      Cancel
                    </Button>
                  </div>
                  
                </Fragment>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectedSafetyReport;
