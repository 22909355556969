import axios from 'axios';
import {Dayjs} from 'dayjs';

// Types
import {ManagerTabProps} from '../../../../../../../models/Employee';
import {
  TimesheetProps,
  TimesheetLogProps,
  TimesheetLogTypeProps,
  TimesheetNoteProps,
} from '../../../../../../../models/Timesheet';
import {PayrollProps} from '../../../../../../../models/Payroll';
import {UserProps} from '../../../../../../../models/User';
import {LeaveRequestProps} from '../../../../../../../models/LeaveRequest';

// Constants
import {TIMECLOCK_API_URL} from '../timeclock';

interface ResponseSetTaskStatus {
  message?: string;
  data: {
    task: ManagerTabProps;
  };
}

export function setTaskStatus(task: ManagerTabProps, statusId: number) {
  const requestBody = {
    user_id: task.user.id,
    type: task.type,
    task_id: task.task.id,
    status_id: statusId,
  };

  return axios.patch<ResponseSetTaskStatus>(
    TIMECLOCK_API_URL + '/manager/set-task-status',
    requestBody,
  );
}

interface ResponseGetReviewPayrollData {
  message?: string;
  data: {
    payroll: PayrollProps;
    timesheets: TimesheetProps[];
    startingDate: string;
  };
}

export function getReviewPayrollData(payroll: PayrollProps, user: UserProps) {
  const params = {user_id: user.id};

  return axios.get<ResponseGetReviewPayrollData>(
    TIMECLOCK_API_URL + '/manager/review-payroll/' + payroll.id,
    {params},
  );
}

interface ResponseGetReviewPayrollLogs {
  message?: string;
  data: {
    timesheetNotes: TimesheetNoteProps[];
    timesheetLogs: TimesheetLogProps[];
  };
}

export function getReviewPayrollLogs(
  payroll: PayrollProps,
  timesheet: TimesheetProps,
  user: UserProps,
) {
  const params = {user_id: user.id};

  return axios.get<ResponseGetReviewPayrollLogs>(
    TIMECLOCK_API_URL +
      '/manager/review-payroll/' +
      payroll.id +
      '/logs/' +
      timesheet.id,
    {params},
  );
}

interface ResponseAddPayrollLogData {
  message?: string;
  data: {
    timesheetLog: TimesheetLogProps;
  };
}

export function addPayrollLog(
  payroll: PayrollProps,
  user: UserProps,
  timesheet: TimesheetProps,
  time: Dayjs,
  type: TimesheetLogTypeProps,
) {
  const requestBody = {
    user_id: user.id,
    time: time.format('HH:mm:ss'),
    type,
  };

  return axios.post<ResponseAddPayrollLogData>(
    TIMECLOCK_API_URL +
      '/manager/review-payroll/' +
      payroll.id +
      '/logs/' +
      timesheet.id,
    requestBody,
  );
}

interface ResponseUploadPayroll {
  message?: string;
  data: {
    payroll: PayrollProps;
  };
}

export function uploadPayroll(payroll: PayrollProps, user: UserProps) {
  const requestBody = {user_id: user.id};

  return axios.post<ResponseUploadPayroll>(
    TIMECLOCK_API_URL + '/manager/review-payroll/' + payroll.id,
    requestBody,
  );
}

interface ResponseGetAdjustPayrollLogData {
  message?: string;
  data: {
    timesheetLog: TimesheetLogProps;
  };
}

export function getAdjustPayrollLogData(
  payroll: PayrollProps,
  user: UserProps,
  timesheet: TimesheetProps,
  timesheetLog: TimesheetLogProps,
) {
  const params = {user_id: user.id};

  return axios.get<ResponseGetAdjustPayrollLogData>(
    TIMECLOCK_API_URL +
      '/manager/review-payroll/' +
      payroll.id +
      '/logs/' +
      timesheet.id +
      '/adjust/' +
      timesheetLog.id,
    {params},
  );
}

interface ResponseAdjustPayrollLogData {
  message?: string;
  data: {
    timesheetLog: TimesheetLogProps;
  };
}

export function adjustPayrollLogData(
  payroll: PayrollProps,
  user: UserProps,
  timesheet: TimesheetProps,
  timesheetLog: TimesheetLogProps,
  time: Dayjs,
) {
  const requestBody = {
    user_id: user.id,
    time: time.format('HH:mm:ss'),
  };

  return axios.post<ResponseAdjustPayrollLogData>(
    TIMECLOCK_API_URL +
      '/manager/review-payroll/' +
      payroll.id +
      '/logs/' +
      timesheet.id +
      '/adjust/' +
      timesheetLog.id,
    requestBody,
  );
}

interface ResponseDeletePayrollLog {
  message?: string;
  data: {
    timesheetLog: TimesheetLogProps;
  };
}

export function deletePayrollLog(
  payroll: PayrollProps,
  user: UserProps,
  timesheet: TimesheetProps,
  timesheetLog: TimesheetLogProps,
) {
  const requestBody = {
    user_id: user.id,
  };

  return axios.delete<ResponseDeletePayrollLog>(
    TIMECLOCK_API_URL +
      '/manager/review-payroll/' +
      payroll.id +
      '/logs/' +
      timesheet.id +
      '/adjust/' +
      timesheetLog.id,
    {data: requestBody},
  );
}

interface ResponseSetPayrollStatus {
  message?: string;
  data: {
    payroll: PayrollProps;
  };
}

export function setPayrollStatus(
  payroll: PayrollProps,
  user: UserProps,
  statusId: number,
) {
  const requestBody = {
    user_id: user.id,
    status_id: statusId,
  };

  return axios.patch<ResponseSetPayrollStatus>(
    TIMECLOCK_API_URL + '/manager/review-payroll/' + payroll.id,
    requestBody,
  );
}

interface ResponseAddPayrollTimesheet {
  message?: string;
  data: {
    timesheet: TimesheetProps;
  };
}

export function addPayrollTimesheet(
  payroll: PayrollProps,
  user: UserProps,
  timesheetDate: Dayjs,
) {
  const requestBody = {
    user_id: user.id,
    timesheet_date: timesheetDate.format('YYYY-MM-DD'),
  };

  return axios.put<ResponseAddPayrollTimesheet>(
    TIMECLOCK_API_URL + '/manager/review-payroll/' + payroll.id,
    requestBody,
  );
}

interface ResponseGetReviewLeaveRequestData {
  message?: string;
  data: {
    leaveRequest: PayrollProps;
  };
}

export function getReviewLeaveRequestData(
  leaveRequest: LeaveRequestProps,
  user: UserProps,
) {
  const params = {
    user_id: user.id,
  };

  return axios.get<ResponseGetReviewLeaveRequestData>(
    TIMECLOCK_API_URL + '/manager/review-leave-request/' + leaveRequest.id,
    {params},
  );
}

interface ResponseSetLeaveRequestStatus {
  message?: string;
  data: {
    leaveRequest: LeaveRequestProps;
  };
}

export function setLeaveRequestStatus(
  leaveRequest: LeaveRequestProps,
  user: UserProps,
  statusId: number,
) {
  const requestBody = {
    user_id: user.id,
    status_id: statusId,
  };

  return axios.patch<ResponseSetLeaveRequestStatus>(
    TIMECLOCK_API_URL + '/manager/review-leave-request/' + leaveRequest.id,
    requestBody,
  );
}
