import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

// Types
import {TimesheetBoardProps} from '../../../models/Timesheet';
import {UserProps} from '../../../models/User';

// Icons
import ScheduleIcon from '@mui/icons-material/Schedule';

// APIs
import {
  getTimesheetBoard,
} from '../_api/mobile/home/timeclock/timesheet-board/timesheet-board';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../global/_components/Loading';

/**
 * TimesheetBoard
 *  - TimesheetBoardTableContent
 */
interface TimesheetBoardUserProps {
  board: TimesheetBoardProps;
  userId?: UserProps['id'];
}

const TimesheetBoardTableContent = ({
  board,
  userId,
}: TimesheetBoardUserProps) => {
  // Hooks
  const smScreen = useMediaQuery('(min-width:450px)');
  const xlScreen = useMediaQuery('(min-width:1280px)');

  const userScheduleClockInDateTime = dayjs(board.userSchedule.clockIn, 'HH:mm:ss');
  const userScheduleClockOutDateTime = dayjs(board.userSchedule.clockOut, 'HH:mm:ss');
  const timesheetLogDateTime = dayjs(board.timesheetLog.time, 'HH:mm:ss');
  const earlyMinutes =
    (userScheduleClockInDateTime.unix() - timesheetLogDateTime.unix()) / 60;
  let fontColor = 'green';
  let earlyMinutesText = Math.ceil(earlyMinutes).toFixed(0) + ' mins early';

  if (earlyMinutes < 0) {
    fontColor = 'error';
    earlyMinutesText =
      Math.abs(Math.ceil(earlyMinutes)).toFixed(0) + ' mins late';
  } else if (!board.userSchedule.clockIn) {
    fontColor = 'primary';
    earlyMinutesText = 'Casual Attendee';
  }

  return (
    <TableRow
      sx={{verticalAlign: 'top'}}
      selected={board.user.id.toString() === userId?.toString()}>
      <TableCell>
        <Typography variant="inherit" fontWeight="bold">
          {board.user.firstname} {board.user.lastname}
        </Typography>

        {Boolean(!smScreen || xlScreen) && (
          <React.Fragment>
            <ScheduleIcon fontSize="inherit" />
            <Typography variant="caption">
              {board.userSchedule.clockIn ? (
                <Fragment>
                  {' '}
                  {userScheduleClockInDateTime.format('hh:mm A')}
                  {' - '}
                  {userScheduleClockOutDateTime.format('hh:mm A')}
                </Fragment>
              ) : ' Day off'}
            </Typography>
          </React.Fragment>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="inherit">
          {timesheetLogDateTime.format('hh:mm A')}
        </Typography>
        <Typography variant="caption" color={fontColor}>
          {Math.abs(earlyMinutes) !== 0 && earlyMinutesText}
        </Typography>
      </TableCell>
      {Boolean(smScreen && !xlScreen) && (
        <TableCell>
          <Typography variant="inherit">
            {board.userSchedule.clockIn
              ? userScheduleClockInDateTime.format('hh:mm A')
              : '-'}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

interface TimesheetBoardComponentProps {
  dateToday: Dayjs;
  updateCount: number;
  userId?: UserProps['id'];
}

interface TimesheetBoardPropsGrouped {
  early: TimesheetBoardProps[];
  casual: TimesheetBoardProps[];
  late: TimesheetBoardProps[];
}

const TimesheetBoard = ({
  updateCount,
  dateToday,
  userId,
}: TimesheetBoardComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [timesheetBoard, setTimesheetBoard] = useState<TimesheetBoardProps[]>([]);

  // Hooks
  const smScreen = useMediaQuery('(min-width:450px)');
  const xlScreen = useMediaQuery('(min-width:1280px)');

  // Variables
  const timesheetBoardGrouped: TimesheetBoardPropsGrouped = {
    early: [],
    late: [],
    casual: [],
  };

  let timesheetBoardIsEmpty = true;

  timesheetBoard
    .forEach(indivBoard => {
      if (!indivBoard.userSchedule.clockIn) {
        timesheetBoardGrouped.casual.push(indivBoard);
      } else {
        const userScheduleDateTime = dayjs(
          indivBoard.userSchedule.clockIn,
          'HH:mm:ss',
        );

        const timesheetLogDateTime = dayjs(
          indivBoard.timesheetLog.time,
          'HH:mm:ss',
        );

        const earlyMinutes =
          (userScheduleDateTime.unix() - timesheetLogDateTime.unix()) / 60;

        if (earlyMinutes < 0) {
          timesheetBoardGrouped.late.push(indivBoard);
        } else {
          timesheetBoardGrouped.early.push(indivBoard);
        }
      }
    });

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getTimesheetBoard();

      if (status === 200) {
        setTimesheetBoard(
          data.data.timesheetBoard.sort((currentBoard, nextBoard) => {
            if (!currentBoard.userSchedule.clockIn) {
              const currentBoardTimesheetLogDateTime = dayjs(currentBoard.timesheetLog.time, 'hh:mm:ss');
              const nextBoardTimesheetLogDateTime = dayjs(nextBoard.timesheetLog.time, 'hh:mm:ss');
              return currentBoardTimesheetLogDateTime.diff(nextBoardTimesheetLogDateTime);
            } else if (currentBoard.userSchedule.clockIn) {
              const currentUserScheduleDateTime = dayjs(
                currentBoard.userSchedule.clockIn,
                'HH:mm:ss',
              );
              const currentTimesheetLogDateTime = dayjs(
                currentBoard.timesheetLog.time,
                'HH:mm:ss',
              );
              const nextUserScheduleDateTime = dayjs(
                nextBoard.userSchedule.clockIn,
                'HH:mm:ss',
              );
              const nextTimesheetLogDateTime = dayjs(
                nextBoard.timesheetLog.time,
                'HH:mm:ss',
              );

              const currentEarlyMinutes =
                currentUserScheduleDateTime.unix() -
                currentTimesheetLogDateTime.unix();
              const nextEarlyMinutes =
                nextUserScheduleDateTime.unix() - nextTimesheetLogDateTime.unix();

              return nextEarlyMinutes - currentEarlyMinutes;
            }

            return 0;
          }),
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshLeaderBoardHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshLeaderBoardHandler();
    }
  }, [refreshLeaderBoardHandler, updateCount]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-col md:flex-1 md:flex-row gap-1 md:items-center md:justify-between">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Leader Board
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshLeaderBoardHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>

          <Typography variant="body2">
            {dateToday.format('ddd. MMMM DD, YYYY')}
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="xl:min-w-[170px]">
                <Typography variant="inherit">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inherit">
                  Clocked-in
                </Typography>
              </TableCell>
              {Boolean(smScreen && !xlScreen) && <TableCell>Schedule</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {init ? (
              <Fragment>
                {timesheetBoardGrouped.early.map(indivTimesheetBoard => {
                  timesheetBoardIsEmpty = false;
                  return (
                    <TimesheetBoardTableContent
                      key={indivTimesheetBoard.timesheet.id}
                      board={indivTimesheetBoard}
                      userId={userId}
                    />
                  );
                })}

                {timesheetBoardGrouped.late.map(indivTimesheetBoard => {
                  timesheetBoardIsEmpty = false;
                  return (
                    <TimesheetBoardTableContent
                      key={indivTimesheetBoard.timesheet.id}
                      board={indivTimesheetBoard}
                      userId={userId}
                    />
                  );
                })}

                {timesheetBoardGrouped.casual.map(indivTimesheetBoard => {
                  timesheetBoardIsEmpty = false;
                  return (
                    <TimesheetBoardTableContent
                      key={indivTimesheetBoard.timesheet.id}
                      board={indivTimesheetBoard}
                      userId={userId}
                    />
                  );
                })}

                {Boolean(timesheetBoardIsEmpty) && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="caption" color="gray">
                        Nobody is here yet, be the first to clock-in.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <LoadingFetchData />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TimesheetBoard;
