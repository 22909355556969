import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {enqueueSnackbar} from 'notistack';

// Context
import {DashboardContext} from '../../_context/DashboardContext';

// Types
import {UserProps} from '../../../../../models/User';
import {ScheduleProps} from '../../../../../models/Schedule';
import {
  TimesheetLogTypeProps,
  TimesheetProps,
} from '../../../../../models/Timesheet';

// APIs
import {
  getDashboard,
  getDashboardUser,
  getDashboardUserTimesheets,
  logTime,
} from '../../_api/dashboard';

// Icons
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';
import UserTimesheetsTable from '../../../_components/UserTimesheetsTable';

export const UsersList = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [users, setUsers] = useState<UserProps[]>([]);

  // Refs
  const searchInputRef = useRef<HTMLDivElement>(null);

  // Context
  const {
    selectedUserId,
    setSelectedUserId,
    updateCount,
  } = useContext(DashboardContext);

  // Hooks
  const screenXL = useMediaQuery('(min-width:1280px) and (max-width:1600px)');

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getDashboard();

      if (status === 200) {
        setUsers(data.data.users);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshUsersListHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const userClickHandler = (
    element: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const newUserId = element.currentTarget.value;

    if (selectedUserId && newUserId === selectedUserId) {
      setSelectedUserId(undefined);
    } else {
      setSelectedUserId(newUserId);
    }
  };

  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
    searchInputRef.current?.focus();
  };

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshUsersListHandler();
    }
  }, [refreshUsersListHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="p-2 md:p-5">
      <div className="mb-5 border-[#000000] border-b pb-1">
        <div className="flex flex-row items-center gap-2">
          <Typography fontWeight="bold" fontSize={20}>
            Users List
          </Typography>

          <button
            type="button"
            disabled={isRefreshing}
            onClick={refreshUsersListHandler}>
            {isRefreshing ? (
              <CircularProgress size={12} />
            ) : (
              <RefreshIcon color="primary" fontSize="small" />
            )}
          </button>
        </div>
      </div>

      <div className="mb-5 px-3">
        <TextField
          autoCorrect="off"
          ref={searchInputRef}
          value={searchInput}
          onChange={searchInputChangeHandler}
          variant="standard"
          label="Seach name"
          placeholder="Please type in the first or last name."
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  type="button"
                  onClick={searchInputIconClickHandler}>
                  {searchInput ? (
                    <CancelIcon color="error" />
                  ) : (
                    <SearchIcon />
                  )}
                </button>
              </InputAdornment>
            ),
          }}
        />
      </div>

      {users
        .sort((currentUser, nextUser) => {
          const currentName = currentUser.firstname + ' ' + currentUser.lastname;
          const nextName = nextUser.firstname + ' ' + nextUser.lastname;
          return currentName.localeCompare(nextName);
        })
        .map(indivUser => {
          const userFullName = `${indivUser.firstname} ${indivUser.lastname}`;

          if (
            searchInput &&
              !userFullName
                .toLocaleLowerCase()
                .includes(searchInput.toLocaleLowerCase())
          ) {
            return null;
          }

          let userContainerClass ='mb-3 border-[#f6f6f6] hover:border hover:shadow-lg hover:bg-[#f0f0f0] p-3 rounded';

          if (indivUser.id.toString() === selectedUserId) {
            userContainerClass += ' border shadow-lg bg-[#f0f0f0]';
          }

          return (
            <button
              key={indivUser.id}
              value={indivUser.id}
              type="button"
              className="text-left block w-full"
              onClick={userClickHandler}>
              <div
                className={userContainerClass}>
                <div className="flex flex-row">
                  {!screenXL && (
                    <div className="w-[100px] h-[100px] border-[#f6f6f6] bg-[#ffffff]/[0.2] rounded md:flex lg:hidden xl:flex items-center justify-center mr-2 shadow hidden">
                      <Typography fontWeight="bold" textAlign="center">
                        {indivUser.firstname[0]}{indivUser.lastname[0]}
                      </Typography>
                    </div>
                  )}

                  <div className="flex-1">
                    <div className="border-[#000000] border-b pb-1 mb-2">
                      <Typography fontWeight="bold" fontSize={16}>
                        {indivUser.firstname} {indivUser.lastname}
                      </Typography>
                    </div>

                    <div className="flex items-center gap-1 mb-1">
                      <WorkIcon fontSize="small" />
                      <Typography variant="body2">
                        {indivUser.role}
                      </Typography>
                    </div>

                    <div className="flex items-center gap-1">
                      <EmailIcon fontSize="small" />
                      <Typography variant="body2">
                        {indivUser.email}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          );
        })
      }
    </div>
  );
};

export const UserProfile = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [user, setUser] = useState<UserProps>();
  const [userSchedule, setUserSchedule] = useState<ScheduleProps>();

  // Context
  const {selectedUserId, setSelectedUserId, updateCount} =
    useContext(DashboardContext);

  // Hooks
  const screenXL = useMediaQuery('(min-width:1280px) and (max-width:1600px)');

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedUserId) {
        const {status, data} =
          await getDashboardUser(selectedUserId);

        if (status === 200) {
          const {
            user: userQuery,
            userSchedule: userScheduleQuery,
          } = data.data;
          setUser(userQuery);
          setUserSchedule(userScheduleQuery);
        }
      } else {
        setUser(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedUserId]);

  const deselectUserClickHandler = () => {
    setSelectedUserId(undefined);
  };

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);


  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  const scheduledClockIn = dayjs(userSchedule?.clockIn, 'HH:mm:ss');
  const scheduledClockOut = dayjs(userSchedule?.clockOut, 'HH:mm:ss');

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-row justify-between border-b border-[#000000] pb-1 mb-3">
        <div className="flex flex-row items-center gap-2">
          <Typography fontSize={20} fontWeight="bold">
            User Profile
          </Typography>

          {Boolean(selectedUserId) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>

        {Boolean(user) && (
          <button
            type="button"
            onClick={deselectUserClickHandler}>
            <RemoveCircleIcon color="error" />
          </button>
        )}
      </div>

      <div className="container">
        {init ? (
          user ? (
            <div className="container">
              <div className={`flex flex-col gap-2 md:flex-row lg:flex-col xl:flex-row ${screenXL && '!flex-col'}`}>
                <div className="w-[100px] h-[100px] border-green-400 bg-[#ffffff]/[0.2] rounded flex items-center justify-center shadow mx-auto lg:mx-auto">
                  <Typography fontWeight="bold" textAlign="center">
                    {user.firstname[0]}{user.lastname[0]}
                  </Typography>
                </div>

                <div className="flex-1 flex flex-col gap-1">
                  <div className="border-[#000000] border-b pb-1">
                    <Typography fontWeight="bold" fontSize={20}>
                      {user.firstname} {user.lastname}
                    </Typography>
                  </div>

                  <div className="flex gap-1">
                    <WorkIcon fontSize="small" />
                    <Typography variant="body2">
                      {user.role}
                    </Typography>
                  </div>

                  <div className="flex gap-1">
                    <EmailIcon fontSize="small" />
                    <Typography variant="body2">
                      {user.email}
                    </Typography>
                  </div>

                  <div className="flex gap-1">
                    <ScheduleIcon fontSize="small" />
                    <Typography variant="body2" sx={{whiteSpace: 'break-spaces'}}>
                      {
                        userSchedule && userSchedule.clockIn
                          ? `${
                            userSchedule.day
                            } \n\r ${
                              scheduledClockIn.format('hh:mm A')
                            } - ${
                              scheduledClockOut.format('hh:mm A')
                            } \n\r ${
                              userSchedule.breakMinutes
                            } minute break`
                          : 'Day off'
                      }
                    </Typography>
                  </div>

                  <div className="flex gap-1">
                    <SupervisorAccountIcon fontSize="small" />
                    <Typography variant="body2">
                      Reports to {user.superior || 'N/A'}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Typography fontWeight="bold" color="grey">
              Please select a user
            </Typography>
          )
        ) : <LoadingFetchData />}
      </div>
    </div>
  );
};

/**
 * UserTimesheets
 */

export const UserTimesheets = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timesheets, setTimesheets] = useState<TimesheetProps[]>([]);
  const [startOfWeek, setStartOfWeek] = useState<Dayjs>(dayjs(dayjs().day(4)));

  // Context
  const {dateToday, selectedUserId, updateCount} =
    useContext(DashboardContext);

  // Variables
  let currentTimesheetLogType: TimesheetLogTypeProps = 'CLOCK-OUT';
  timesheets.forEach(indivTimesheet => {
    const timesheetDate = dayjs(indivTimesheet.createdAt);

    indivTimesheet.timesheetLogs.forEach(indivTimesheetLog => {
      if (
        dateToday.format('YYYY-MM-DD') ===
        timesheetDate.format('YYYY-MM-DD')
      ) {
        currentTimesheetLogType = indivTimesheetLog.type;
      }
    });
  });

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedUserId) {
        const {status, data} =
          await getDashboardUserTimesheets(selectedUserId);

        if (status === 200) {
          setTimesheets(data.data.timesheets.sort((currentTimesheet, nextTimesheet) => {
            const currentTimesheetDate = dayjs(currentTimesheet.createdAt);
            const nextTimesheetDate = dayjs(nextTimesheet.createdAt);
            return nextTimesheetDate.unix() - currentTimesheetDate.unix();
          }));
          setStartOfWeek(dayjs(data.data.startOfWeek));
        }
      } else {
        setTimesheets([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedUserId]);

  const logTimeClickHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      if (selectedUserId) {
        const {status, data} = await logTime(selectedUserId);

        if (status === 201) {
          const {
            message,
            data:{timesheet, timesheetLog, timesheetNote},
          } = data;
          let timesheetIsLogged = false;
          const newTimesheets = [...timesheets];
          newTimesheets.forEach(indivTimesheet => {
            if (indivTimesheet.id === timesheet.id) {
              timesheetIsLogged = true;
              indivTimesheet.timesheetLogs.push(timesheetLog);
              indivTimesheet.timesheetNotes.push(timesheetNote);
            }
          });

          if (!timesheetIsLogged) {
            const newTimesheet = timesheet;
            newTimesheet.timesheetLogs = [timesheetLog];
            newTimesheet.timesheetNotes = [timesheetNote];
            newTimesheets.unshift(newTimesheet);
          }
          setTimesheets(newTimesheets);

          let snackBarColor: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined = 'default';
          switch (timesheetLog.type) {
            case 'CLOCK-IN': {
              snackBarColor = 'success';
              break;
            }
            case 'CLOCK-OUT': {
              snackBarColor = 'warning';
              break;
            }
          }

          enqueueSnackbar(
            message,
            {
              autoHideDuration: 5000,
              variant: snackBarColor,
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [selectedUserId, timesheets]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-2 items-center">
            <Typography fontSize={20} fontWeight="bold">
              Timesheets
            </Typography>

            {Boolean(selectedUserId) && (
              <button
                type="button"
                disabled={isRefreshing}
                onClick={refreshHandler}>
                {isRefreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>

          <Typography variant="body2">
            {`${startOfWeek.format('ddd. MMMM DD, YYYY')} - ${startOfWeek.add(6, 'days').format('ddd. MMMM DD, YYYY')}`}
          </Typography>
        </div>

        {Boolean(init) && (
          <div className="flex flex-col gap-1">
            <Button
              disabled={!selectedUserId || isLoading}
              variant="contained"
              color={currentTimesheetLogType === 'CLOCK-OUT' ? 'success' : 'error'}
              onClick={logTimeClickHandler}>
              {currentTimesheetLogType === 'CLOCK-OUT' ? 'Clock-in' : 'Clock-out'}
            </Button>
            <Typography variant="body2">
              {dateToday.format('ddd. MMM. DD, YYYY hh:mm:ss A')}
            </Typography>
          </div>
        )}
      </div>

      <UserTimesheetsTable
        isLoading={init}
        timesheets={timesheets}
        userId={parseInt(selectedUserId ?? '0')}
      />
    </div>
  );
};

export const LeaveCalendar = () => {
  const [isRefreshing] = useState<boolean>(false);

  // Context
  const {dateToday} = useContext(DashboardContext);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between border-b border-[#000000] pb-1 mb-3 md:items-center">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Leave Calendar
          </Typography>

          <button
            type="button"
            disabled={isRefreshing}>
            {isRefreshing ? (
              <CircularProgress size={12} />
            ) : (
              <RefreshIcon color="primary" fontSize="small" />
            )}
          </button>
        </div>

        <Typography>
          {dateToday.format('ddd. MMMM DD, YYYY')}
        </Typography>
      </div>
    </div>
  );
};
