// Dependecies
import axios from 'axios';
import {API_URL} from '../../../../../../utils/urls';

// Types
import {
  ProductScheduleFormProps,
  ProductScheduleLabourForm,
  ProductScheduleLabourProps,
  ProductScheduleProps,
  ProductScheduleTypeProps,
  PurchaseOrderProps,
} from '../../../../../../models/ProductSchedule';
import {UserProps} from '../../../../../../models/User';

// Constants
export const PRODUCT_SCHEDULE_API_URL = API_URL + '/mobile/home/product-schedule';

interface ResponseGetProductSchedulesScreenData {
  data: {
    productScheduleTypes: ProductScheduleTypeProps[];
  };
}

export function getProductSchedulesScreenData() {
  return axios.get<ResponseGetProductSchedulesScreenData>(PRODUCT_SCHEDULE_API_URL);
}

interface ResponseSubmitProductScheduleForm {
  message: string;
  data: {
    productSchedule: ProductScheduleProps;
  };
}

export function submitProductScheduleForm(productSchedule: ProductScheduleFormProps) {
  const requestBody = {
    id: productSchedule.id,
    name: productSchedule.name,
    description: productSchedule.description,
  };

  return axios.post<ResponseSubmitProductScheduleForm>(PRODUCT_SCHEDULE_API_URL, requestBody);
}

interface ResponseGetProductSchedule {
  data: {
    previousProductScheduleType: ProductScheduleTypeProps;
    unassignedPurchaseOrders: PurchaseOrderProps[];
    purchaseOrders: PurchaseOrderProps[];
  };
}

export function getProductSchedule(productSchedule: ProductScheduleProps) {
  return axios.get<ResponseGetProductSchedule>(PRODUCT_SCHEDULE_API_URL + '/' + productSchedule.id);
}


interface ResponseMovePurchaseOrder {
  message: string;
  data: {
    purchaseOrder: PurchaseOrderProps;
  };
}


export function movePuchaseOrder(
  productSchedule: ProductScheduleProps,
  purchaseOrder: PurchaseOrderProps,
  week: number,
  year: number,
) {
  const requestBody = {
    id: purchaseOrder.id,
    week,
    year,
  };
  
  return axios.patch<ResponseMovePurchaseOrder>(
    PRODUCT_SCHEDULE_API_URL + '/' + productSchedule.id + '/purchase-orders',
    requestBody,
  );
}

interface ResponseGetProductScheduleUsers {
  data: {
    users: UserProps[];
    productScheduleLabours: ProductScheduleLabourProps[];
  };
}

export function getProductScheduleUsers(productSchedule: ProductScheduleProps) {
  return axios.get<ResponseGetProductScheduleUsers>(
    `${PRODUCT_SCHEDULE_API_URL}/${productSchedule.id}/users`
  );
}

interface ResponseAssignProductScheduleLabour {
  message: string;
  data: {
    productScheduleLabour: ProductScheduleLabourProps;
  };
}

export function assignProductScheduleLabour(
  productSchedule: ProductScheduleProps,
  productScheduleLabour: ProductScheduleLabourProps,
  week: number,
) {
  const requestBody = {
    id: productScheduleLabour.id,
    user_id: productScheduleLabour.user.id,
    week,
  };

  return axios.post<ResponseAssignProductScheduleLabour>(
    `${PRODUCT_SCHEDULE_API_URL}/${productSchedule.id}/users/assign`,
    requestBody,
  );
}

interface ResponseUpdateProductScheduleLabour {
  message: string;
  data: {
    productScheduleLabour: ProductScheduleLabourProps;
  };
}

export function updateProductScheduleLabour(
  productSchedule: ProductScheduleProps,
  productScheduleLabour: ProductScheduleLabourForm,
) {
  const requestBody = {
    id: productScheduleLabour.id,
    work_hours: parseFloat(productScheduleLabour.workHours.toString()),
  };

  return axios.patch<ResponseUpdateProductScheduleLabour>(
    `${PRODUCT_SCHEDULE_API_URL}/${productSchedule.id}/users`,
    requestBody,
  );
}

interface ResponseDeleteProductScheduleLabour {
  message: string;
  data: {
    productScheduleLabour: ProductScheduleLabourProps;
  };
}

export function deleteProductScheduleLabour(
  productSchedule: ProductScheduleProps,
  productScheduleLabour: ProductScheduleLabourForm,
) {
  const requestBody = {
    id: productScheduleLabour.id,
  };

  return axios.delete<ResponseDeleteProductScheduleLabour>(
    `${PRODUCT_SCHEDULE_API_URL}/${productSchedule.id}/users`,
    {data: requestBody},
  );
}
