import {useState} from 'react';
import {Button, FormHelperText, Typography} from '@mui/material';
import OtpInput from 'react-otp-input';
import {isAxiosError} from 'axios';
import {enqueueSnackbar} from 'notistack';

// Assests
import StyxMillLogo from '../../../assets/images/logos/styxmill_logo.png';

// APIs
import {submitAuthPin} from './_api/user-access';

const UserAccess = () => {
  const [pin, setPin] = useState<string>('');
  const [submitIsAllowed, setSubmitIsAllowed] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const otpChangeHandler = (newValue: string) => {
    setSubmitIsAllowed(newValue.length === 4);
    setPin(newValue);
    setError('');
  };

  const pinSubmitHandler = async () => {
    setSubmitIsAllowed(false);
    try {
      const {status} = await submitAuthPin(pin);

      if (status === 200) {
        window.location.href = '/';
      }
    } catch (err) {
      let message = 'Something went wrong, please contact your administrator.'
          
      if (isAxiosError(err)) {
        const response = err.response;

        if (response) {
          message = response.data.message;
          setError(message);
        }
      }

      enqueueSnackbar(
        message,
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setSubmitIsAllowed(true);
    }
  };

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border lg:w-1/4 lg:mx-auto">
      <div className="grid gap-5">
        <img
          className="object-contain w-[50%] lg:w-[50%] md:w-[30%] block mx-auto"
          src={StyxMillLogo}
          alt="fabricar-logo"
        />

        <div className="grid gap-2">
          <Typography
            variant="h5"
            color="primary"
            fontWeight="bold"
            textAlign="center">
            Verification
          </Typography>

          <Typography variant="body2" textAlign="center">
            Fabricar is an application to help manage productivity. <br />
            Please type-in your 4-digit pin to login.
          </Typography>
        </div>

        <div className="flex flex-col items-center justify-center">
          <OtpInput
            value={pin}
            onChange={otpChangeHandler}
            numInputs={4}
            renderInput={(props) => <input {...props} className={`${error ? 'border border-red-600' : 'border'} mx-1 text-[40px]`} />}
          />
          {Boolean(error) && (
            <FormHelperText className="!text-red-600">
              {error}
            </FormHelperText>
          )}
        </div>

        <Button
          disabled={!submitIsAllowed}
          variant="contained"
          color="primary"
          onClick={pinSubmitHandler}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default UserAccess;
