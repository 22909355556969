import {createContext, useEffect, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';

// Types
import {SafetyReportProps} from '../../../../../models/SafetyReport';

interface SafetyProviderProps {
  children: React.ReactNode;
}

interface SafetyContextProps {
  dateToday: Dayjs;
  setDateToday: React.Dispatch<React.SetStateAction<Dayjs>>;
  updateCount: number;
  setUpdateCount: React.Dispatch<React.SetStateAction<number>>;
  safetyReports: SafetyReportProps[];
  setSafetyReports: React.Dispatch<React.SetStateAction<SafetyReportProps[]>>;
  selectedSafetyReport?: SafetyReportProps | undefined;
  setSelectedSafetyReport: React.Dispatch<React.SetStateAction<SafetyReportProps | undefined>>;
}

const initialDashboardState = {
  dateToday: dayjs(),
  setDateToday: () => null,
  updateCount: 0,
  setUpdateCount: () => null,
  safetyReports: [],
  setSafetyReports: () => null,
  selectedSafetyReport: undefined,
  setSelectedSafetyReport: () => null,
};

export const SafetyContext = createContext<SafetyContextProps>(initialDashboardState);

export const SafetyContextProvider = ({children}: SafetyProviderProps) => {
  const dateTodayRef = useRef(dayjs());
  const [dateToday, setDateToday] = useState<Dayjs>(dateTodayRef.current);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [safetyReports, setSafetyReports] = useState<SafetyReportProps[]>([]);
  const [selectedSafetyReport, setSelectedSafetyReport] = useState<SafetyReportProps>();

  useEffect(() => {
    let secondsCounter = dateTodayRef.current.second();

    const interval = setInterval(() => {
      setDateToday(dayjs());

      // check fetch updates every 1 min
      if (secondsCounter === 59) {
        secondsCounter = 0;
        setUpdateCount(count => count + 1);
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SafetyContext.Provider
      value={{
        dateToday,
        setDateToday,
        updateCount,
        setUpdateCount,
        safetyReports,
        setSafetyReports,
        selectedSafetyReport,
        setSelectedSafetyReport,
      }}>
      {children}
    </SafetyContext.Provider>
  );
};

export default SafetyContextProvider;
