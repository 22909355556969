import { Typography } from '@mui/material';
import StyxMillLogo from '../../../../assets/images/logos/styxmill_logo.png'

const More = () => {
  return (
    <div className="md:mx-10 mx-3 p-2 md:p-5">
      <a href="https://styxmill.com/" target="_blank" rel="noopener noreferrer">
        <div className="w-[20%]">
          <div className="border p-5 rounded mb-3">
              <img src={StyxMillLogo} alt="Styx Mill Logo" />
          </div>
          <Typography align="center" className="text-blue-500">
            (Visit Styx Mill)
          </Typography>
        </div>
      </a>
    </div>
  );
};

export default More;
