import {useCallback, useContext, useEffect, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {
  CircularProgress,
  Typography,
} from '@mui/material';

// Types
import {TimesheetProps} from '../../../../../../models/Timesheet';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';
import {HomeContext} from '../../_context/HomeContext';

// APIs
import {getMyHours} from '../../../../_api/mobile/home/timeclock/my-hours/my-hours';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import UserTimesheetsTable from '../../../../_components/UserTimesheetsTable';

export const MyHours = () => {
  // Context
  const {authUser} = useContext(AuthContext);
  const {updateCount, dateToday} = useContext(HomeContext);

  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [timesheets, setTimesheets] = useState<TimesheetProps[]>([]);
  const [startingDate, setStartingDate] = useState<Dayjs>(dayjs(dateToday.day(4)));

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getMyHours();

      if (status === 200) {
        setTimesheets(data.data.timesheets.sort((currentTimesheet, nextTimesheet) => {
          const currentTimesheetDate = dayjs(currentTimesheet.createdAt);
          const nextTimesheetDate = dayjs(nextTimesheet.createdAt);
          return nextTimesheetDate.unix() - currentTimesheetDate.unix();
        }));
        setStartingDate(dayjs(data.data.startingDate));
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-col md:flex-1 md:flex-row gap-1 md:items-center md:justify-between">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              My Hours
            </Typography>

            {Boolean(authUser?.id) && (
              <button
                type="button"
                disabled={isRefreshing}
                onClick={refreshHandler}>
                {isRefreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>

          <Typography variant="body2">
            {`${startingDate.format('ddd. MMMM DD, YYYY')} - ${startingDate.add(6, 'days').format('ddd. MMMM DD, YYYY')}`}
          </Typography>
        </div>
      </div>

      <UserTimesheetsTable
        isLoading={init}
        timesheets={timesheets}
        userId={authUser!.id}
      />
    </div>
  );
};

export default MyHours;
