import {useCallback, useContext, useEffect, useState} from 'react';
import {CircularProgress,Switch,Typography } from '@mui/material';
import {enqueueSnackbar} from 'notistack';

// Types
import {RouteProps} from '../../../../../../models/Route';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';
import {AdministratorContext} from '../_context/AdministratorContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// APIs
import {getUserRoutes, updateUserRoute} from '../../../../_api/mobile/home/administrator/administrator';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

const UserRouteAccess = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userRoutes, setUserRoutes] = useState<RouteProps[]>([]);
  const [routes, setRoutes] = useState<RouteProps[]>([]);

  // Context
  const {authUser} = useContext(AuthContext);
  const {selectedUserId} = useContext(AdministratorContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedUserId) {
        const {status, data} = await getUserRoutes(selectedUserId);
        
        if (status === 200) {
          setUserRoutes(data.data.userRoutes);
          setRoutes(data.data.routes);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedUserId]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Home Screen Access
          </Typography>

          {Boolean(authUser?.id) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        {routes.map((indivRoute, indivRouteIndex) => {
          const userRoute = userRoutes.find(indivUserRoute => {
            return indivUserRoute.id === indivRoute.id;
          });

          return (
            <div key={indivRouteIndex} className="flex flex-row items-center justify-between border-b pb-1">
              <Typography fontWeight="bold">
                {indivRoute.title}
              </Typography>

              <Switch
                disabled={isSubmitting}
                checked={Boolean(userRoute)}
                onChange={async () => {
                  setIsSubmitting(true);
                  try {
                    if (selectedUserId) {
                      const {status, data} = await updateUserRoute(selectedUserId, indivRoute);
                      const newUserRoutes = [...userRoutes];

                      switch (status) {
                        case 200: {
                          if (userRoute) {
                            const userRouteIndex = userRoutes.findIndex(indivUserRoute => {
                              return indivUserRoute.id === indivRoute.id;
                            });

                            newUserRoutes.splice(userRouteIndex, 1);
                          } else {
                            newUserRoutes.push(indivRoute);
                          }

                          enqueueSnackbar(
                            data.message ?? 'Changes applied.',
                            {
                              autoHideDuration: 3000,
                              variant: userRoute ? 'warning' : 'success',
                              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                            },
                          );
                          break;
                        }
                      }

                      setUserRoutes(newUserRoutes);
                    }
                  } catch (err) {

                  } finally {
                    setIsSubmitting(false);
                  }
                }}
              />
          </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserRouteAccess;
