import axios from 'axios';

// Types
import {UserProps} from '../../../../models/User';
import {TimesheetBoardProps, TimesheetLogProps, TimesheetNoteProps, TimesheetProps} from '../../../../models/Timesheet';
import {ScheduleProps} from '../../../../models/Schedule';

// Utils
import {API_URL} from '../../../../utils/urls';

const DASHBOARD_URL = `${API_URL}/dashboard`;

interface GetDashboardResponse {
  data: {
    users: UserProps[];
  };
}

export const getDashboard = () => {
  return axios.get<GetDashboardResponse>(DASHBOARD_URL);
};

interface GetDashboardUserResponse {
  data: {
    user: UserProps;
    userSchedule?: ScheduleProps;
  };
}

export const getDashboardUser = (userId: string) => {
  return axios.get<GetDashboardUserResponse>(`${DASHBOARD_URL}/${userId}`);
};

interface GetDashboardTimesheetBoardResponse {
  data: {
    timesheetBoard: TimesheetBoardProps[];
  };
}

export const getDashboardTimesheetBoard = () => {
  return axios.get<GetDashboardTimesheetBoardResponse>(`${DASHBOARD_URL}/timesheet-board`);
};

interface GetDashboardUserTimesheetsResponse {
  data: {
    startOfWeek: string;
    timesheets: TimesheetProps[];
  };
}

export const getDashboardUserTimesheets = (userId: string) => {
  return axios.get<GetDashboardUserTimesheetsResponse>(`${DASHBOARD_URL}/${userId}/timesheets`);
};

interface LogTimeResponse {
  message: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLog: TimesheetLogProps;
    timesheetNote: TimesheetNoteProps;
  };
}

export const logTime = (userId: string) => {
  return axios.post<LogTimeResponse>(`${DASHBOARD_URL}/${userId}/timesheets`);
};
