import axios from 'axios';
import dayjs, {Dayjs} from 'dayjs';

// Types
import {
  LeaveRequestDateProps,
  LeaveRequestDatePropsNote,
  LeaveTypeProps,
} from '../../../../../../../models/LeaveRequest';
import {UserProps} from '../../../../../../../models/User';
import {ScheduleProps} from '../../../../../../../models/Schedule';

// URL
import {TIMECLOCK_API_URL} from '../timeclock';

interface ResponseGetLeaveCalendar {
  message?: string;
  data: {
    hasEmployees: boolean;
    isSelfManaged: boolean;
    leaveRequestDates: LeaveRequestDateProps[];
  };
}

export function getLeaveCalendar(date: Dayjs, showStaffLeaveRequest: boolean) {
  const params: {date: string; show_staff_leave_request?: boolean} = {
    date: date.format('YYYY-MM-DD'),
  };

  if (showStaffLeaveRequest) {
    params.show_staff_leave_request = true;
  }

  return axios.get<ResponseGetLeaveCalendar>(
    TIMECLOCK_API_URL + '/leave-calendar',
    {params},
  );
}

interface ResponseSetLeaveRequestDateStatus {
  message?: string;
  data: {
    leaveRequestDate: LeaveRequestDateProps;
  };
}

export function setLeaveRequestDateStatus(
  leaveRequestDate: LeaveRequestDateProps,
  user: UserProps,
  statusId: number,
) {
  const requestBody = {
    leave_request_date_id: leaveRequestDate.id,
    user_id: user.id,
    status_id: statusId,
  };

  return axios.patch<ResponseSetLeaveRequestDateStatus>(
    TIMECLOCK_API_URL + '/leave-calendar',
    requestBody,
  );
}

interface ResponseGetRequestLeave {
  message?: string;
  data: {
    userSchedules: ScheduleProps[];
    leaveTypes: LeaveTypeProps[];
  };
}

export function getRequestLeave() {
  return axios.get<ResponseGetRequestLeave>(
    TIMECLOCK_API_URL + '/leave-calendar/request-leave',
  );
}

interface ResponseSubmitRequestLeave {
  message?: string;
}

export function submitRequestLeave(
  leaveType: LeaveTypeProps,
  leaveRequestDates: LeaveRequestDateProps[],
) {
  const requestBody: {
    leave_type_id: number;
    leave_request_dates: {
      date: string;
      start_time?: string;
      end_time?: string;
      scheduled_clock_in?: string;
      scheduled_clock_out?: string;
      scheduled_break_minutes: number;
    }[];
  } = {
    leave_type_id: leaveType.id,
    leave_request_dates: [],
  };

  leaveRequestDates.forEach(indivLeaveRequestDate => {
    const leaveRequestDate = dayjs(indivLeaveRequestDate.date);

    requestBody.leave_request_dates.push({
      date: leaveRequestDate.format('YYYY-MM-DD'),
      start_time: indivLeaveRequestDate.startTime,
      end_time: indivLeaveRequestDate.endTime,
      scheduled_clock_in: indivLeaveRequestDate.scheduledClockIn,
      scheduled_clock_out: indivLeaveRequestDate.scheduledClockOut,
      scheduled_break_minutes: indivLeaveRequestDate.scheduledBreakMinutes,
    });
  });

  return axios.post<ResponseSubmitRequestLeave>(
    TIMECLOCK_API_URL + '/leave-calendar/request-leave',
    requestBody,
  );
}

interface ResponseGetReviseLeaveRequestDate {
  message?: string;
  data: {
    leaveTypes: LeaveTypeProps[];
    leaveRequestDate: LeaveRequestDateProps;
    leaveRequestDateNotes: LeaveRequestDatePropsNote[];
  };
}

export function getReviseLeaveRequestDate(
  leaveRequestDate: LeaveRequestDateProps,
  user: UserProps,
) {
  const params = {
    user_id: user.id,
  };

  return axios.get<ResponseGetReviseLeaveRequestDate>(
    TIMECLOCK_API_URL + '/leave-calendar/' + leaveRequestDate.id,
    {params},
  );
}

interface ResponseSubmitReviseLeaveRequestDate {
  message?: string;
  data: {
    leaveRequestDate: LeaveRequestDateProps;
    leaveRequestDateNotes: LeaveRequestDatePropsNote[];
  };
}

export function submitReviseLeaveRequestDate(
  leaveRequestDate: LeaveRequestDateProps,
  user: UserProps,
  statusId: number,
) {
  const requestBody = {
    user_id: user.id,
    leave_type_id: leaveRequestDate.leaveType?.id,
    start_time: leaveRequestDate.startTime,
    end_time: leaveRequestDate.endTime,
    status_id: statusId,
  };

  return axios.patch<ResponseSubmitReviseLeaveRequestDate>(
    TIMECLOCK_API_URL + 1 + '/leave-calendar/' + leaveRequestDate.id,
    requestBody,
  );
}
