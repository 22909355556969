import {Outlet} from 'react-router-dom';

// Context
import HomeContextProvider from './_context/HomeContext';

const Home = () => {
  return (
    <HomeContextProvider>
      <Outlet />
    </HomeContextProvider>
  );
};

export default Home;
