import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// Types
import {CustomerServiceProps} from '../../../../../models/CustomerService';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Context
import {HomeContext} from '../_context/HomeContext';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIs
import {getCustomerService} from './_api/customer-service';

const CustomerService = () => {
  let isCustomerServiceEmpty = true;

  // Context
  const {updateCount} = useContext(HomeContext);

  // States
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [customerService, setCustomerService] = useState<CustomerServiceProps[]>([]);
  const [filters] = useState([
    {
      key: 'orderNumber',
      title: 'Order Number',
    },
    {
      key: 'branch',
      title: 'Branch',
    },
    {
      key: 'part',
      title: 'Product Code',
    },
  ]);
  const [selectedFilter, setSelectedFilter] = useState<keyof CustomerServiceProps>('orderNumber');
  const [searchInput, setSearchInput] = useState<string>('');
  const [arrayCount, setArrayCount] = useState<number>(20);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getCustomerService();

      if (status === 200) {
        setCustomerService(data.data.customerService);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, []);

  const refreshCustomerServiceHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (arrayCount < customerService.length) {
          setArrayCount(arrayCount + 10);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [arrayCount, customerService]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshCustomerServiceHandler();
    }
  }, [refreshCustomerServiceHandler, updateCount]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border">
      <div className="p-2 md:p-5">
        <div className="flex flex-col md:flex-row justify-between border-b border-[#000000] pb-1 mb-3 md:items-center">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Customer Service
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshCustomerServiceHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>

          <div className="md:flex md:flex-row gap-2">
            <div className="flex flex-row items-center gap-3">
              <Typography>
                Filter by:
              </Typography>
              <Select
                MenuProps={{disableScrollLock: true}}
                variant="standard"
                className="min-w-[100px]"
                value={selectedFilter}
                name="countryCode"
                onChange={e => {
                  setSelectedFilter(e.target.value as keyof CustomerServiceProps);
                }}>
                {filters.map(indivFilter => {
                  return (
                    <MenuItem
                      key={indivFilter.key}
                      value={indivFilter.key}>
                      {indivFilter.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div>
              <TextField
                variant="standard"
                value={searchInput}
                onChange={e => {
                  setSearchInput(e.target.value);
                }}
                placeholder="Search content"
                fullWidth
              />
            </div>
          </div>
        </div>

        <div className="bg-[#ffffff] rounded">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Order Number
                  </TableCell>
                  <TableCell>
                    Branch
                  </TableCell>
                  <TableCell>
                    Part
                  </TableCell>
                  <TableCell>
                    Qty
                  </TableCell>
                  <TableCell>
                    Promised Date
                  </TableCell>
                  <TableCell>
                    Scheduled Delivery Date
                  </TableCell>
                  <TableCell>
                    Order
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {init || isRefreshing ? (
                  <Fragment>
                    {customerService
                      .filter(indivCustomerService => {
                        if (searchInput) {
                          const text = indivCustomerService[selectedFilter].toString().toLocaleLowerCase();
                          if (text.includes(searchInput.toLocaleLowerCase())) {
                            return indivCustomerService;
                          } else {
                            return null;
                          }
                        } else {
                          return indivCustomerService;
                        }
                      })
                      .map((indivCustomerService, indivCustomerServiceIndex) => {
                        if (indivCustomerServiceIndex > arrayCount) {
                          return null;
                        }

                        isCustomerServiceEmpty = false;

                        return (
                          <TableRow key={indivCustomerService.id}>
                            <TableCell>
                              <Typography>
                                {indivCustomerService.orderNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {indivCustomerService.branch}
                              </Typography>
                            </TableCell>
                              <TableCell>
                              <Typography>
                                {indivCustomerService.part}
                              </Typography>
                            </TableCell>
                              <TableCell>
                              <Typography>
                                {indivCustomerService.qty}
                              </Typography>
                            </TableCell>
                              <TableCell>
                              <Typography>
                                {indivCustomerService.promisedDate}
                              </Typography>
                            </TableCell>
                              <TableCell>
                              <Typography>
                                {indivCustomerService.scheduledDeliveryDate}
                              </Typography>
                            </TableCell>
                              <TableCell>
                              <Typography>
                                {indivCustomerService.order}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }

                    {Boolean(isCustomerServiceEmpty) && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Typography variant="caption" color="gray">
                            Nothing to display, please refresh to fetch data.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <LoadingFetchData />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
