import React, {createContext, useEffect, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';

// Types
import {UserProps} from '../../../../../../models/User';
import {RoleProps} from '../../../../../../models/Role';

interface AdministratorProviderProps {
  children: React.ReactNode;
}

interface AdministratorContextProps {
  dateToday: Dayjs;
  setDateToday: React.Dispatch<React.SetStateAction<Dayjs>>;
  selectedUserId?: UserProps['id'];
  setSelectedUserId: React.Dispatch<React.SetStateAction<UserProps['id'] | undefined>>;
  updateCount: number;
  users: UserProps[];
  setUsers: React.Dispatch<React.SetStateAction<UserProps[]>>;
  user: UserProps | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserProps | undefined>>;
  roles: RoleProps[];
  setRoles: React.Dispatch<React.SetStateAction<RoleProps[]>>;
}

const initialAdministratorState = {
  dateToday: dayjs(),
  setDateToday: () => null,
  selectedUserId: undefined,
  setSelectedUserId: () => null,
  updateCount: 0,
  users: [],
  setUsers: () => null,
  user: undefined,
  setUser: () => null,
  roles: [],
  setRoles: () => null,
};

export const AdministratorContext = createContext<AdministratorContextProps>(initialAdministratorState);

export const AdministratorContextProvider = ({children}: AdministratorProviderProps) => {
  const dateTodayRef = useRef(dayjs());
  const [dateToday, setDateToday] = useState<Dayjs>(dateTodayRef.current);
  const [selectedUserId, setSelectedUserId] = useState<UserProps['id']>();
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [user, setUser] = useState<UserProps>();
  const [roles, setRoles] = useState<RoleProps[]>([]);

  useEffect(() => {
    let secondsCounter = dateTodayRef.current.second();

    const interval = setInterval(() => {
      setDateToday(dayjs());

      // check fetch updates every 1 min
      if (secondsCounter === 59) {
        secondsCounter = 0;
        setUpdateCount(count => count + 1);
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AdministratorContext.Provider
      value={{
        dateToday,
        setDateToday,
        selectedUserId,
        setSelectedUserId,
        updateCount,
        users,
        setUsers,
        user,
        setUser,
        roles,
        setRoles,
      }}>
      {children}
    </AdministratorContext.Provider>
  );
};

export default AdministratorContextProvider;
