import axios, {isAxiosError} from 'axios';

// Models
import {AuthTokenProps} from '../../../../models/Auth';

const AUTH_LOCAL_STORAGE_KEY = 'fabricar-auth';
const getAuth = (): AuthTokenProps | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthTokenProps = JSON.parse(lsValue) as AuthTokenProps;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthTokenProps) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios() {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    config => {
      const auth = getAuth();
      if (auth && auth.apiToken) {
        config.headers.Authorization = `Bearer ${auth.apiToken}`;
      }

      return config;
    },
    err => {
      if (isAxiosError(err)) {
        switch (err.status) {
          case 409: {
            window.location.href = '/';
            break;
          }
        }
      }

      return Promise.reject(err);
    },
  );
  axios.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      if (isAxiosError(err)) {
        switch (err.response?.status) {
          case 409: {
            window.location.href = '/';
            break;
          }
        }
      }

      return Promise.reject(err);
    },
  );
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY};
