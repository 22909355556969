import {useContext} from 'react';

// Types
import {RouteProps} from '../../models/Route';

// Context
import {AuthContext} from '../../app/global/auth/context/AuthContext';

// Components
import PageNotFound from '../../app/pages/error/PageNotFound';
import Dashboard from '../../app/pages/dashboard/Dashboard';
import UserAccess from '../../app/pages/user-access/UserAccess';
import Home from '../../app/pages/mobile/home/Home';
import Timeclock from '../../app/pages/mobile/home/timeclock/Timeclock';
import Costings from '../../app/pages/mobile/home/costings/Costings';
import CustomerService from '../../app/pages/mobile/home/customer-service/CustomerService';
import StaffHandbook from '../../app/pages/mobile/home/staff-handbook/StaffHandbook';
import Database from '../../app/pages/mobile/home/database/Database';
import Notifications from '../../app/pages/mobile/notifications/Notifications';
import Safety from '../../app/pages/mobile/safety/Safety';
import Profile from '../../app/pages/mobile/profile/Profile';
import More from '../../app/pages/mobile/more/More';
import Administrator from '../../app/pages/mobile/home/administrator/Administrator';
import MobileProductSchedule from '../../app/pages/mobile/home/product-schedule/ProductSchedule';
import ProductSchedule from '../../app/pages/product-schedule/ProductSchedule';

export const RenderRouteElement = (route: RouteProps['route']) => {
  const {webAccess} = useContext(AuthContext);

  switch (route) {
    case 'dashboard': {
      return <Dashboard />;
    }

    case 'home': {
      return <Home />;
    }

    case 'notifications': {
      return <Notifications />;
    }

    case 'safety': {
      return <Safety />;
    }

    case 'profile': {
      return <Profile />;
    }

    case 'more': {
      return <More />;
    }

    case 'customer-service': {
      return <CustomerService />;
    }

    case 'staff-handbook': {
      return <StaffHandbook />;
    }

    case 'timeclock': {
      return <Timeclock />;
    }

    case 'costings': {
      return <Costings />;
    }

    case 'user-access': {
      return <UserAccess />;
    }

    case 'database': {
      return <Database />;
    }

    case 'administrator': {
      return <Administrator />;
    }

    case 'product-schedule': {
      switch (webAccess) {
        case 'PRODUCT_SCHEDULE_SCREEN': {
          return <ProductSchedule />;
        }

        default: {
          return <MobileProductSchedule />;
        }
      }
    }

    default: {
      return <PageNotFound />;
    }
  }
};
