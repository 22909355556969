import axios from 'axios';

// Types
import {AuthenticationMobileProps, AuthenticationProps, WebAccessType} from '../../../../models/Auth';
import {UserProps} from '../../../../models/User';
import {RouteProps} from '../../../../models/Route';

// Utils
import {API_URL} from '../../../../utils/urls';

const AUTH_URL = `${API_URL}/auth`;

interface LoginResponse {
  data: {
    apiToken: string;
  };
}

export const login = (auth: AuthenticationProps) => {
  const requestBody = auth;

  return axios.post<LoginResponse>(`${AUTH_URL}/login`, requestBody);
};

interface getUserByTokenResponse {
  messaage: string;
  data: {
    user: UserProps;
    webAccess: WebAccessType;
    routes: RouteProps[];
    notificationsCount: number;
  };
}

export const getUserByToken = (token: string) => {
  return axios.get<getUserByTokenResponse>(
    `${AUTH_URL}/user`, {headers: {'Authorization': `Bearer ${token}`}},
  );
};

interface LogoutResponse {
  message: string;
}

export const logout = () => {
  return axios.post<LogoutResponse>(`${AUTH_URL}/logout`);
};

interface MobileLoginResponse {
  message: string;
  data: {
    hasPin: boolean;
  };
}

export const mobileLogin = (auth: AuthenticationMobileProps) => {
  const requestBody = {
    country_code: auth.countryCode,
    mobile_number: auth.mobileNumber,
  };

  return axios.post<MobileLoginResponse>(`${AUTH_URL}/mobile-login`, requestBody);
};

export const verifyPin = (auth: AuthenticationMobileProps, pinCode: string) => {
  const requestBody = {
    country_code: auth.countryCode,
    mobile_number: auth.mobileNumber,
    pin: pinCode,
  };

  return axios.post<LoginResponse>(`${AUTH_URL}/verify-pin`, requestBody);
};

export const authCodeLogin = (authCode: string) => {
  const requestBody = {
    auth_code: authCode,
  };

  return axios.post<LoginResponse>(`${AUTH_URL}/auth-code`, requestBody);
};
