import {Fragment, useContext, useEffect, useState} from 'react';
import {
  Badge,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';

// Images
import FabricarLogoHeader from '../../../../assets/images/logos/fabricar-logo-header.png';

// Context
import {AuthContext} from '../../auth/context/AuthContext';

// APIs
import {logout as logoutUser} from '../../../pages/auth/_api/auth';
import {switchUserAccess} from './_api/user-access';

// Icons
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';

const Header = () => {
  const [anchorMenuElement, setAnchorMenuElement] =
    useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMenuElement, setSelectedMenuElement] = useState<string | null>();

  // Context
  const {authUser, routes, notificationsCount, logout, webAccess} = useContext(AuthContext);

  // Hooks
  const location = useLocation();

  // Handlers
  const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuElement(event.currentTarget);
    setSelectedMenuElement(event.currentTarget.dataset.routeAnchor);
  };

  const menuItemCloseHandler = () => {
    setAnchorMenuElement(null);
    setSelectedMenuElement(null);
  };

  const logoutClickHandler = async () => {
    setIsLoading(true);
    try {
      // Logout from API
      await logoutUser();
    } catch (err) {
      console.log(err);
    } finally {
      // Logout from UI
      logout();
      setIsLoading(false);
      setAnchorMenuElement(null);
    }
  };

  const switchUserClickHandler = async () => {
    try {
      const {status} = await switchUserAccess();

      if (status === 200) {
        window.location.href = '/';
      }
    } catch (err) {
      console.log(err);

      enqueueSnackbar(
        'Something went wrong. Please contact your admin.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    }
  };

  useEffect(() => {
    return () => {
      // window.location.reload();
    };
  }, []);

  let greetings = 'Welcome,';

  switch (webAccess) {
    case 'PRODUCT_SCHEDULE_SCREEN': {
      greetings = 'Current User'
      break;
    }
  }

  return (
    <nav>
      <div className="flex justify-between items-center md:p-10 md:px-16 p-5">
        <div className="flex flex-row items-center">
          <img
            src={FabricarLogoHeader}
            className="lg:w-[200px] w-[150px]"
            alt="fabricar-logo"
          />

          <div className="flex flex-row gap-10 ml-10">
            {routes.map(indivRoute => {
              const isCurrentPage = location.pathname.includes(indivRoute.route);
              const notifications = indivRoute.route === 'notifications' ? notificationsCount : 0;

              return (
                <Fragment key={indivRoute.id}>
                  <Link
                    data-route-anchor={indivRoute.route}
                    to={`/${indivRoute.route}`}
                    className={`${isCurrentPage ? 'border-b-4 border-[#000000]' : 'border-none'} p-2`}
                    onMouseEnter={menuClickHandler}>
                    <Badge badgeContent={notifications} color='error'>
                      <Typography>
                        {indivRoute.title}
                      </Typography>
                    </Badge>
                  </Link>

                  {Boolean(indivRoute.subRoutes.length) && (
                    <Menu
                      disableScrollLock
                      anchorEl={anchorMenuElement}
                      open={selectedMenuElement === indivRoute.route}
                      onClose={menuItemCloseHandler}
                      onClick={menuItemCloseHandler}
                      transformOrigin={{horizontal: 'left', vertical: 'top'}}
                      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
                      {indivRoute.subRoutes.map(indivSubRoute => {
                        return (
                          <MenuItem key={indivSubRoute.id} disabled={isLoading}>
                            <Link to={`${indivRoute.route}/${indivSubRoute.route}`}>
                              <Typography>
                                {indivSubRoute.title}
                              </Typography>
                            </Link>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>

        <div className="flex justify-end">
          <div className="container">
            <div className="flex flex-row items-center gap-4">
              <div className="container hidden md:block p-3 bg-[#ffffff]/[0.5] rounded-lg">
                <Typography fontWeight="bold">
                  {greetings}
                </Typography>
                <Typography>
                  {authUser?.firstname} {authUser?.lastname}
                </Typography>
                {Boolean(authUser?.primaryUserAccess) && (
                  <div>
                    <Typography variant="caption" color="grey">
                      (via {authUser?.primaryUserAccess})
                    </Typography>

                    <button className="block" disabled={isLoading} onClick={switchUserClickHandler}>
                      <Typography className="text-yellow-600" fontSize={16}>
                        <LogoutIcon className="mr-1" fontSize='inherit' />
                        Log off
                      </Typography>
                    </button>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="border rounded p-2 bg-[#ffffff] text-red-500"
                data-route-anchor="logout"
                onClick={logoutClickHandler}>
                <LockIcon fontSize="large" />
                <Typography>
                  Lock
                </Typography>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
