export const ColorPalette = {
  Primary: '#2c63ba',
  Secondary: '#fb9c2b',
  Success: '#50c878',
  Error: '#b00020',
  Ash: '#cccccc',
  Shark: '#232324',
  Tundora: '#4c4446',
  BarleyCorn: '#938149',
};

export const ColorSelections = [
  '#ffffff',
  '#ededed',
  '#cbe6ef',
  '#fbd9d3',
  '#d4f8d4',
  '#fffee0',
];
