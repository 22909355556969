import {useCallback, useContext, useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import dayjs from 'dayjs';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {SafetyContext} from '../_context/SafetyContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';

// APIs
import {getSafetyReports} from '../../../_api/mobile/safety/safety';

// Utils
import {
  STATUS_ID_CANCELLED,
  STATUS_ID_COMPLETED,
} from '../../../../../utils/constants';

const SafetyReports = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  // Context
  const {authUser} = useContext(AuthContext);
  const {
    updateCount,
    safetyReports,
    setSafetyReports,
    selectedSafetyReport,
    setSelectedSafetyReport,
  } = useContext(SafetyContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getSafetyReports();

      if (status === 200) {
        setSafetyReports(data.data.safetyReports);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [setSafetyReports]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);


  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Reports
          </Typography>

          {Boolean(authUser?.id) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>
      </div>
      
      <div className="flex flex-col gap-3">
        {init ? safetyReports.length ? safetyReports
          .sort((currentSafetyReport, nextSafetyReport) => {
            const currentSafetyReportDate = dayjs(
              currentSafetyReport.createdAt,
            );
            const nextSafetyReportDate = dayjs(
              nextSafetyReport.createdAt,
            );
            return (
              nextSafetyReportDate.unix() -
              currentSafetyReportDate.unix()
            );
          })
          .map(indivSafetyReport => {
            const safetyReportDate = dayjs(
              indivSafetyReport.createdAt,
            );

            let statusText = indivSafetyReport.status?.name;
            let statusTextColor = 'text-blue-500';

            switch (indivSafetyReport.status?.id) {
              case STATUS_ID_COMPLETED: {
                statusText = 'Resolved';
                statusTextColor = 'text-green-500';
                break;
              }
              case STATUS_ID_CANCELLED: {
                statusTextColor = 'text-red-500';
                break;
              }
            }

            let selected = selectedSafetyReport?.id === indivSafetyReport.id;

            return (
              <div
                key={indivSafetyReport.id}
                className={`border ${selected ? 'border-blue-600' : 'hover:border-blue-600'} rounded p-2 cursor-pointer`}
                onClick={() => {
                  setSelectedSafetyReport(indivSafetyReport);
                }}>
                <div className="border-b pb-2 mb-2 flex flex-row justify-between">
                  <Typography fontWeight="bold">
                    {safetyReportDate.format(
                      'ddd MMM. DD, YYYY - hh:mm A',
                    )}
                  </Typography>

                  <Typography className={`!font-bold ${statusTextColor}`}>
                    {statusText}
                  </Typography>
                </div>
                <div className="flex-row mb-2">
                  <Typography fontWeight="bold">Sender: </Typography>
                  <Typography>
                    {indivSafetyReport.user.firstname}{' '}
                    {indivSafetyReport.user.lastname}
                  </Typography>
                </div>

                <div>
                  <Typography fontWeight="bold">Report: </Typography>
                  <Typography>
                    {indivSafetyReport.report}
                  </Typography>
                </div>
              </div>
            );
          }) : (
            <Typography className="text-gray-500" variant="caption">
              Nothing to display
            </Typography>
          ) : (
            <LoadingFetchData />
          )}
      </div>
    </div>
  );
};

export default SafetyReports;
