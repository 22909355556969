// Dependecies
import axios from 'axios';
import {API_URL} from '../../../../../utils/urls';

// Types
import {
  SafetyReportProps,
  SafetyReportResponseProps,
} from '../../../../../models/SafetyReport'
import {ChatProps} from '../../../../../models/Chat'

interface ResponseCreateIncidentReport {
  message?: string;
  data: {
    safetyReport: SafetyReportProps;
  };
}

export function createIncidentReport(report: {report: string}) {
  return axios.post<ResponseCreateIncidentReport>(
    API_URL + '/mobile/safety/reports',
    report,
  );
}

interface ResponseGetSafetyReports {
  message?: string;
  data: {
    safetyReports: SafetyReportProps[];
    hasEmployees: boolean;
    messagesCount: number;
    chats: ChatProps[];
  };
}

export function getSafetyReports(screenTab?: string) {
  const params = {
    screen_tab: screenTab,
  };

  return axios.get<ResponseGetSafetyReports>(
    API_URL + '/mobile/safety/reports',
    {params},
  );
}

interface ResponseGetSafetyReportResponses {
  message?: string;
  data: {
    safetyReport: SafetyReportProps;
    safetyReportResponses: SafetyReportResponseProps[];
  };
}

export function getSafetyReportResponses(safetyReport: SafetyReportProps) {
  return axios.get<ResponseGetSafetyReportResponses>(
    API_URL + '/mobile/safety/reports/' + safetyReport.id,
  );
}

interface ResponsePostSafetyReportResponse {
  message?: string;
  data: {
    safetyReport: SafetyReportProps;
    safetyReportResponse: SafetyReportResponseProps;
  };
}

export function postSafetyReportResponse(
  safetyReport: SafetyReportProps,
  response: string,
) {
  const requestBody = {response};

  return axios.post<ResponsePostSafetyReportResponse>(
    API_URL + '/mobile/safety/reports/' + safetyReport.id,
    requestBody,
  );
}

interface ResponseSetSafetyReportStatus {
  message?: string;
  data: {
    safetyReport: SafetyReportProps;
    safetyReportResponse: SafetyReportResponseProps;
  };
}

export function setSafetyReportStatus(
  safetyReport: SafetyReportProps,
  statusId: number,
) {
  const requestBody = {
    status_id: statusId,
  };

  return axios.patch<ResponseSetSafetyReportStatus>(
    API_URL + '/mobile/safety/reports/' + safetyReport.id,
    requestBody,
  );
}
