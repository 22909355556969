import {useContext} from 'react';

// Context
import {AuthContext} from '../../../../global/auth/context/AuthContext';
import {HomeContext} from '../_context/HomeContext';

// Components
import MyHours from './_components/MyHours';
import TodayLogs from './_components/TodayLogs';
import TimesheetBoard from '../../../_components/TimesheetBoard';
import ChangeRequests from './_components/ChangeRequests';
import LeaveCalendar from './_components/LeaveCalendar';

const Timeclock = () => {
  // Context
  const {authUser} = useContext(AuthContext);
  const {updateCount, dateToday} = useContext(HomeContext);

  if (!authUser) {
    return null;
  }

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2">
        <div className="bg-[#d7fed5] border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
          <TodayLogs />
        </div>

        <div className="lg:col-span-2 border shadow bg-[#d3e4fd] rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
          <MyHours />
        </div>

        <div className="bg-[#ededed] border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll col-span-full xl:col-span-1">
          <TimesheetBoard
            dateToday={dateToday}
            updateCount={updateCount}
            userId={authUser.id}
          />
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-2">
        <div className="bg-[#ffe6e6] border shadow rounded-lg">
          <LeaveCalendar />
        </div>

        <div className="border shadow bg-[#fdfcd0] rounded-lg">
          <ChangeRequests />
        </div>
      </div>
    </div>
  );
};

export default Timeclock;
