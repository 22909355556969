import axios from 'axios';
import {Dayjs} from 'dayjs';

// Types
import type {
  IntegratedTimesheetLogProps,
  TimesheetProps,
  TimesheetLogProps,
  TimesheetLogChangeProps,
  TimesheetNoteProps,
} from '../../../../../../../models/Timesheet';

// URL
import {TIMECLOCK_API_URL} from '../timeclock';

interface ResponseGetMyHours {
  message?: string;
  data: {
    startingDate: string;
    contractHours: number;
    timesheets: TimesheetProps[];
  };
}

export function getMyHours(startingDate?: Dayjs) {
  return axios.get<ResponseGetMyHours>(TIMECLOCK_API_URL + '/my-hours', {
    params: {
      start_of_week: startingDate?.format('YYYY-MM-DD'),
    },
  });
}

interface ResponseGetMyLogs {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLogs: TimesheetLogProps[];
    timesheetNotes: TimesheetNoteProps[];
  };
}

export function getMyLogs(timesheet: TimesheetProps) {
  return axios.get<ResponseGetMyLogs>(
    TIMECLOCK_API_URL + '/my-hours/' + timesheet.id,
  );
}

interface ResponseGetAdjustLoggedTime {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLogs: TimesheetLogProps[];
    timesheetLog: TimesheetLogProps;
    timesheetLogChanges: TimesheetLogChangeProps[];
  };
}

export function getAdjustLoggedTime(
  timesheet: TimesheetProps,
  timesheetLog: IntegratedTimesheetLogProps,
) {
  const params = {
    clock_in_id: timesheetLog.clockIn.id,
    clock_out_id: timesheetLog.clockOut?.id,
  };

  return axios.get<ResponseGetAdjustLoggedTime>(
    TIMECLOCK_API_URL + '/my-hours/' + timesheet.id + '/adjust-logged-time',
    {params},
  );
}

interface ResponseSubmitAdjustLoggedTime {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLog: TimesheetLogProps;
    timesheetLogChanges: TimesheetLogChangeProps[];
  };
}

export function submitAdjustLoggedTime(
  timesheet: TimesheetProps,
  timesheetLog: IntegratedTimesheetLogProps,
  toClockIn: Dayjs,
  toClockOut: Dayjs,
) {
  const requestBody = {
    clock_in_id: timesheetLog.clockIn.id,
    clock_out_id: timesheetLog.clockOut?.id,
    to_clock_in: toClockIn.format('HH:mm:ss'),
    to_clock_out: toClockOut.format('HH:mm:ss'),
  };

  return axios.post<ResponseSubmitAdjustLoggedTime>(
    TIMECLOCK_API_URL + '/my-hours/' + timesheet.id + '/adjust-logged-time',
    requestBody,
  );
}
