import {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {DndContext, DragEndEvent, useDraggable, useDroppable} from '@dnd-kit/core';
import {enqueueSnackbar} from 'notistack';
import dayjs from 'dayjs';

// Types
import {StaffHandbookProps} from '../../../../../../models/StaffHandbook';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import TitleIcon from '@mui/icons-material/Title';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// APIs
import {
  sortStaffHandbookData,
  updateStaffHandbook,
} from '../../../../_api/mobile/home/staff-handbook/staff-handbook';

// Utils
import {searchInputCheck} from '../../../../../../utils/utils';

interface StaffHandbookListComponentProps {
  staffHandbooks: StaffHandbookProps[];
  setStaffHandbooks: React.Dispatch<React.SetStateAction<StaffHandbookProps[]>>;
  setOpenHandbookFormDialog: React.Dispatch<React.SetStateAction<boolean>>;
  refreshHandler: () => void;
  isRefreshing: boolean;
  selectedHandbook: StaffHandbookProps | undefined;
  setSelectedHandbook: React.Dispatch<React.SetStateAction<StaffHandbookProps | undefined>>;
  handbookClickHandler: (element: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handbookUpdateClickHandler: (element: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isAdmin: boolean;
}

const StaffHandbookList = ({
  staffHandbooks,
  setStaffHandbooks,
  setOpenHandbookFormDialog,
  refreshHandler,
  isRefreshing,
  selectedHandbook,
  setSelectedHandbook,
  handbookClickHandler,
  handbookUpdateClickHandler,
  isAdmin,
}: StaffHandbookListComponentProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [sortHandbooks, setSortHandbooks] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sortedHandbooks, setSortedHandbooks] = useState<StaffHandbookProps[]>([]);

  // Context
  const {authUser} = useContext(AuthContext);

  // Refs
  const searchInputRef = useRef<HTMLDivElement>(null);

  // Handlers
  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
    searchInputRef.current?.focus();
  };

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    if (event.over && event.over.id) {
      if (event.over.id !== event.active.id) {
        const overHandbookIndex = parseInt(event.over.id.toString());
        const activeHandbookIndex = parseInt(event.active.id.toString());
        
        const newHandbooks = [...sortedHandbooks];
        const activeHandbook = newHandbooks[activeHandbookIndex];

        if (overHandbookIndex === sortedHandbooks.length - 1) {
          newHandbooks.splice(overHandbookIndex + 1, 0, activeHandbook);
          newHandbooks.splice(activeHandbookIndex, 1);
        } else {
          if (overHandbookIndex === 0) {
            newHandbooks.splice(0, 0, activeHandbook);
            newHandbooks.splice(activeHandbookIndex + 1, 1);
          } else {
            if (overHandbookIndex < activeHandbookIndex) {
              newHandbooks.splice(overHandbookIndex, 0, activeHandbook);
              newHandbooks.splice(activeHandbookIndex + 1, 1);
            } else {
              newHandbooks.splice(overHandbookIndex + 1, 0, activeHandbook);
              newHandbooks.splice(activeHandbookIndex, 1);
            }
          }
        }
        setSortedHandbooks(newHandbooks);
      }
    }
  }, [sortedHandbooks]);

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  const sortHandbooksClickHandler = useCallback(() => {
    setSortHandbooks(true);
    setSortedHandbooks(staffHandbooks);
  }, [staffHandbooks]);

  const cancelSortHandbooksClickHandler = () => {
    setSortHandbooks(false);
  };

  const updateHandbookClickHandler = async (
    staffHandbook: StaffHandbookProps,
    staffHandbookIndex: number,
  ) => {
    setIsSubmitting(true);
    try {
      const {status, data} = await updateStaffHandbook(staffHandbook);

      if (status === 200) {
        const newStaffHandbooks = [...staffHandbooks];
        newStaffHandbooks.splice(
          staffHandbookIndex,
          1,
          data.data.staffHandbook,
        );
        setStaffHandbooks(newStaffHandbooks);
        setSortedHandbooks(newStaffHandbooks);
        enqueueSnackbar(
          data.message,
          {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );

        if (selectedHandbook?.id === staffHandbook.id) {
          let staffHandbookCache: StaffHandbookProps | undefined = undefined;
          newStaffHandbooks.forEach(indivStaffHandbook => {
            if (
              !staffHandbookCache &&
              !indivStaffHandbook.isHeader
            ) {
              staffHandbookCache = indivStaffHandbook;
            }
          });
          setSelectedHandbook(staffHandbookCache);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const saveSortHandbooksClickHandler = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const {status, data} = await sortStaffHandbookData(sortedHandbooks);

      if (status === 200) {
        setStaffHandbooks(data.data.staffHandbooks);
        setSortHandbooks(false);
        enqueueSnackbar(
          'Changes applied.',
          {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [sortedHandbooks, setStaffHandbooks]);

  useEffect(() => {
    if (sortHandbooks) {
      setSortedHandbooks(staffHandbooks);
    }
  }, [staffHandbooks, sortHandbooks]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-1">
        <div className="flex flex-row gap-2">
          <Typography fontSize={20} fontWeight="bold">
            Handbook List
          </Typography>

          {Boolean(authUser?.id) && (
            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
        </div>

        {isAdmin && (
          <div className="flex flex-row gap-2">
            {sortHandbooks ? (
              <Fragment>
                <Button
                  disabled={isSubmitting || isRefreshing}
                  color="error"
                  onClick={cancelSortHandbooksClickHandler}>
                  Cancel
                </Button>

                <Button
                  disabled={isSubmitting || isRefreshing}
                  color="success"
                  variant="contained"
                  onClick={saveSortHandbooksClickHandler}>
                  Save
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button disableRipple color="primary" onClick={sortHandbooksClickHandler}>
                  Sort
                </Button>

                <Button
                  variant='contained'
                  disableRipple
                  color="primary"
                  onClick={() => {
                    setOpenHandbookFormDialog(true);
                  }}>
                  Add
                </Button>
              </Fragment>
              
            )}
          </div>
        )}
      </div>

      {sortHandbooks ? (
        <DndContext onDragEnd={handleDragEnd}>
          {sortedHandbooks.map((indivHandbook, indivHandbookIndex) => {
            const handbookIndent = indivHandbook.indent * 5;

            return (
              <div
                key={indivHandbook.id}
                style={{marginLeft: `${handbookIndent}%`}}
                className="mb-2">
                <DroppableComponent
                  handbookIndex={indivHandbookIndex}>
                  <DraggableComponent handbookIndex={indivHandbookIndex}>
                    <div className="p-2 border rounded bg-[#ffffff]">
                      <Typography className="!text-sm">
                        {indivHandbook.title}
                      </Typography>
                    </div>
                  </DraggableComponent>
                </DroppableComponent>

                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <IconButton
                      disabled={!Boolean(indivHandbook.indent) || isSubmitting}
                      size="small"
                      onClick={() => {
                        const newHandbook = {...indivHandbook};
                        newHandbook.indent -= 1;
                        updateHandbookClickHandler(newHandbook, indivHandbookIndex);
                      }}>
                      <FormatIndentDecreaseIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                      disabled={indivHandbook.indent > 4 || isSubmitting}
                      size="small"
                      onClick={() => {
                        const newHandbook = {...indivHandbook};
                        newHandbook.indent += 1;
                        updateHandbookClickHandler(newHandbook, indivHandbookIndex);
                      }}>
                      <FormatIndentIncreaseIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                      disabled={indivHandbook.indent > 4 || isSubmitting}
                      size="small"
                      onClick={() => {
                        const newHandbook = {...indivHandbook};
                        newHandbook.isHeader = !indivHandbook.isHeader;
                        updateHandbookClickHandler(newHandbook, indivHandbookIndex);
                      }}>
                      <TitleIcon
                        color={indivHandbook.isHeader ? 'primary' : 'inherit'}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </div>

                  <IconButton
                    value={indivHandbook.id}
                    disabled={indivHandbook.indent > 4 || isSubmitting}
                    data-button-type="Delete"
                    size="small"
                    onClick={handbookUpdateClickHandler}>
                    <DeleteIcon color="error" fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </DndContext>
      ) : (
        <Fragment>
          <div className="mb-2 px-3">
            <TextField
              autoCorrect="off"
              autoComplete="off"
              ref={searchInputRef}
              value={searchInput}
              onChange={searchInputChangeHandler}
              variant="standard"
              label="Seach title"
              placeholder="Please type in the title."
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      type="button"
                      onClick={searchInputIconClickHandler}>
                      {searchInput ? (
                        <CancelIcon color="error" />
                      ) : (
                        <SearchIcon />
                      )}
                    </button>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {staffHandbooks
            .sort((currentHandbook, nextHandbook) => {
              return currentHandbook.sort - nextHandbook.sort;
            })
            .filter(indivStaffHandbook => {
              if (searchInput) {
                if (searchInputCheck(searchInput, indivStaffHandbook.title)) {
                  return indivStaffHandbook;
                }
              } else {
                return indivStaffHandbook;
              }

              return null;
            })
            .map(indivHandbook => {
              const staffHandbookUpdatedAt = dayjs(indivHandbook.updatedAt);
              const handbookIndent = indivHandbook.indent * 5;
              let handbookContainerClass = 'mb-1 border p-3 rounded';

              if (indivHandbook.id === selectedHandbook?.id) {
                handbookContainerClass += ' border-blue-500';
              }

              if (indivHandbook.isHeader) {
                handbookContainerClass += ' border-0';
              } else {
                handbookContainerClass += ' hover:border-blue-500 mb-3';
              }

              return (
                <div
                  key={indivHandbook.id}
                  style={{marginLeft: `${handbookIndent}%`}}
                  className={handbookContainerClass}>
                  {indivHandbook.isHeader ? (
                    <div className="flex flex-row justify-between border-[#000000] border-b">
                      <Typography fontWeight="bold">
                        {indivHandbook.title}
                      </Typography>

                      {isAdmin && (
                        <IconButton
                          size="small"
                          value={indivHandbook.id}
                          data-button-type="Update"
                          onClick={handbookUpdateClickHandler}>
                          <EditIcon color="primary" fontSize="inherit" />
                        </IconButton>
                      )}
                    </div>
                  ) : (
                    <button
                      value={indivHandbook.id}
                      type="button"
                      className="text-left block w-full"
                      onClick={handbookClickHandler}>
                      <div className="flex flex-row">
                        <div className="flex-1">
                          <div className="flex flex-row justify-between items-center">
                            <div>
                              <Typography fontWeight="inherit">
                                {indivHandbook.title}
                              </Typography>

                              {
                                !indivHandbook.isHeader &&
                                !indivHandbook.isSeen && (
                                  <Typography
                                    variant='body2'
                                    className="text-green-500"
                                    fontWeight="bold">
                                    Updated
                                    {staffHandbookUpdatedAt.format(' ddd. MMM. DD, YYYY @ hh:mm A')}
                                  </Typography>
                                )
                              }
                            </div>

                            <ChevronRightIcon />
                          </div>
                        </div>
                      </div>
                    </button>
                  )}
                </div>
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

interface DnDProps {
  children: React.ReactNode;
  handbookIndex: number;
}

const DraggableComponent = ({children, handbookIndex}: DnDProps) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: handbookIndex.toString(),
  });
  const style = transform ? {
    transform: `translate3d(0, ${transform.y}px, 0)`,
    opacity: 0.5,
  } : undefined;

  
  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

const DroppableComponent = ({children, handbookIndex}: DnDProps) => {
  const {isOver, setNodeRef} = useDroppable({
    id: handbookIndex.toString(),
  });
  const style = {
    color: isOver ? 'green' : undefined,
    border: isOver ? '0.5px solid green' : undefined,
  };


  return (
    <div ref={setNodeRef} style={style} className="rounded">
      {children}
    </div>
  );
};


export default StaffHandbookList;
