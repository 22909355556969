// Images
import {Link} from 'react-router-dom';
import PageNotFoundImage from '../../../assets/svg/images/PageNotFound.svg';

const PageNotFound = () => {
  return (
    <div>
      <img
        src={PageNotFoundImage}
        alt="Error 404"
        className="object-contain lg:w-[30%] mx-auto mb-5"
      />

      <div className="content-center flex">
        <Link
          to="/"
          className="bg-blue-400 text-[#ffffff] font-bold p-5 rounded-full mx-auto">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
