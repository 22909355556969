import {useState} from 'react';
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// Types
import {ProductProps} from '../../../../../../models/Product';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

// Utils
import {searchInputCheck} from '../../../../../../utils/utils';

interface ProductsComponentProps {
  isRefreshing: boolean;
  isAdmin: boolean;
  refreshHandler: () => void;
  handleClickAddProduct: () => void;
  products: ProductProps[];
  selectedProduct?: ProductProps;
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductProps | undefined>>;
}

const Products = ({
  isRefreshing,
  refreshHandler,
  handleClickAddProduct,
  products,
  selectedProduct,
  setSelectedProduct,
  isAdmin,
}: ProductsComponentProps) => {
  const [searchInput, setSearchInput] = useState<string>('');

  // Handlers
  const searchInputIconClickHandler = () => {
    if (searchInput) {
      setSearchInput('');
    }
  };

  const searchInputChangeHandler = (
    element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInput(element.currentTarget.value);
  };

  let listEmpty = true;

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-row flex-1 justify-between items-center">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Products
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>

          {isAdmin && (
            <Button
              variant="contained"
              onClick={handleClickAddProduct}>
              Add
            </Button>
          )}
        </div>
      </div>

      <div className="mb-2 px-3">
        <TextField
          autoCorrect="off"
          autoComplete="off"
          value={searchInput}
          onChange={searchInputChangeHandler}
          variant="standard"
          label="Search product"
          placeholder="Please type in the title or code."
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  type="button"
                  onClick={searchInputIconClickHandler}>
                  {searchInput ? (
                    <CancelIcon color="error" />
                  ) : (
                    <SearchIcon />
                  )}
                </button>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Code
              </TableCell>
              <TableCell>
                Garment Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .filter(indivProduct => {
                if (searchInput) {
                  if (
                    searchInputCheck(searchInput, indivProduct.name) ||
                    searchInputCheck(searchInput, indivProduct.code)
                  ) {
                    return indivProduct;
                  }

                  return null;
                } else {
                  return indivProduct;
                }
              })
              .map(indivProduct => {
                listEmpty = false;
                const selected = indivProduct.id === selectedProduct?.id;

                return (
                  <TableRow
                    key={indivProduct.id}
                    hover
                    selected={selected}
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedProduct(indivProduct);
                    }}>
                    <TableCell>
                      {indivProduct.code}
                    </TableCell>
                    <TableCell>
                      {indivProduct.name}
                    </TableCell>
                  </TableRow>
                )
              })}
            
            {listEmpty && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography className="text-gray-500" variant='caption'>
                    Nothing to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Products;
