import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {TimePicker} from '@mui/x-date-pickers';
import {enqueueSnackbar} from 'notistack';

// Types
import {UserProps} from '../../../../../../models/User';
import {ScheduleProps} from '../../../../../../models/Schedule';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';
import {AdministratorContext} from '../_context/AdministratorContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

// APIs
import {
  getHomeAdministratorUserData,
  updateUserSchedule,
} from '../../../../_api/mobile/home/administrator/administrator';

const UserSchedules = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [user, setUser] = useState<UserProps>();
  const [userSchedules, setUserSchedules] = useState<ScheduleProps[]>([]);
  const [openUserScheduleFormDialog, setOpenUserScheduleFormDialog] = useState<boolean>(false);
  const [selectedUserSchedule, setSelectedUserSchedule] = useState<ScheduleProps>();

  // Context
  const {authUser} = useContext(AuthContext);
  const {selectedUserId} = useContext(AdministratorContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getHomeAdministratorUserData(selectedUserId!);

      switch (status) {
        case 200: {
          setUser(data.data.user);
          setUserSchedules(data.data.userSchedules);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedUserId]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handlerCloseUserScheduleFormDialog = () => {
    setOpenUserScheduleFormDialog(false);
  };

  const handleSubmitUserScheduleForm = async () => {
    setIsSubmitting(true);
    try {
      if (user && selectedUserSchedule) {
        const clockIn = dayjs(selectedUserSchedule?.clockIn, 'HH:mm:ss');
        const clockOut = dayjs(selectedUserSchedule?.clockOut, 'HH:mm:ss');
  
        if (selectedUserSchedule.isRestDay || clockIn.unix() <= clockOut.unix()) {
          const {status, data} = await updateUserSchedule(user, selectedUserSchedule);

          switch (status) {
            case 200: {
              const newUserSchedules = [...userSchedules];
              const newUserSchedule = data.data.userSchedule;
              const userScheduleIndex = newUserSchedules.findIndex(
                indivUserSchedule => indivUserSchedule.id === newUserSchedule.id,
              );
              newUserSchedules.splice(userScheduleIndex, 1, newUserSchedule);
              setUserSchedules(newUserSchedules);
              setOpenUserScheduleFormDialog(false);

              enqueueSnackbar(
                'Schedule updated.',
                {
                  autoHideDuration: 3000,
                  variant: 'success',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
              break;
            }
          }
        } else {
          enqueueSnackbar(
            'Form error, please check.',
            {
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        }
      }
    } catch (err) {
      enqueueSnackbar(
        'Something went wrong, please contact your administrator.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (user?.id !== selectedUserId) {
      setInit(false);
    }
  }, [user, selectedUserId]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <Fragment>
      <div className="p-2 md:p-5">
        <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Employee Agreement Hours
            </Typography>

            {Boolean(authUser?.id) && (
              <button
                type="button"
                disabled={isRefreshing}
                onClick={refreshHandler}>
                {isRefreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>
        </div>

        {user ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Day
                  </TableCell>
                  <TableCell>
                    Clock-in
                  </TableCell>
                  <TableCell>
                    Clock-out
                  </TableCell>
                  <TableCell>
                    Break (minutes)
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userSchedules.map(indivUserSchedule => {
                  const clockIn = dayjs(indivUserSchedule.clockIn, 'HH:mm:ss');
                  const clockOut = dayjs(indivUserSchedule.clockOut, 'HH:mm:ss');

                  return (
                    <TableRow key={indivUserSchedule.id}>
                      <TableCell>
                        {indivUserSchedule.day}
                      </TableCell>
                      <TableCell>
                        {indivUserSchedule.clockIn ? clockIn.format('hh:mm A') : '-'}
                      </TableCell>
                      <TableCell>
                        {indivUserSchedule.clockOut ? clockOut.format('hh:mm A') : '-'}
                      </TableCell>
                      <TableCell>
                        {indivUserSchedule.breakMinutes}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setOpenUserScheduleFormDialog(true);
                            setSelectedUserSchedule({...indivUserSchedule, isRestDay: !indivUserSchedule.clockIn});
                          }}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>
            Invalid User.
          </Typography>
        )}
      </div>

      {selectedUserSchedule && (
        <Dialog
          disableScrollLock={true}
          onClose={handlerCloseUserScheduleFormDialog}
          open={openUserScheduleFormDialog}>
          <DialogTitle>Update {user?.firstname} {user?.lastname}'s Schedule</DialogTitle>
          <DialogContent>
            <div className="grid gap-4">
              <div className="flex flex-row items-center">
                <Typography fontWeight="bold" sx={{width: 100}}>
                  Rest Day:
                </Typography>

                <Switch
                  checked={selectedUserSchedule.isRestDay}
                  onClick={() => {
                    const newSelectedUserSchedule = {...selectedUserSchedule};
                    newSelectedUserSchedule.isRestDay = !newSelectedUserSchedule.isRestDay;
                    if (!newSelectedUserSchedule.clockIn) {
                      const currentUserSchedule = userSchedules.find(
                        indivUserSchedule => indivUserSchedule.id === selectedUserSchedule.id,
                      );

                      if (currentUserSchedule && currentUserSchedule.clockIn) {
                        newSelectedUserSchedule.clockIn = currentUserSchedule.clockIn;
                        newSelectedUserSchedule.clockOut = currentUserSchedule.clockOut;
                        newSelectedUserSchedule.breakMinutes = currentUserSchedule.breakMinutes;
                      } else {
                        newSelectedUserSchedule.clockIn = '08:00:00';
                        newSelectedUserSchedule.clockOut = '17:00:00';
                        newSelectedUserSchedule.breakMinutes = 0;
                      }
                    } else {
                      newSelectedUserSchedule.clockIn = undefined;
                      newSelectedUserSchedule.clockOut = undefined;
                      newSelectedUserSchedule.breakMinutes = 0;
                    }
                    setSelectedUserSchedule(newSelectedUserSchedule);
                  }}
                />
              </div>

              <div className="flex flex-row items-center">
                <Typography fontWeight="bold" sx={{width: 100}}>
                  Day:
                </Typography>

                <Typography>
                  {selectedUserSchedule.day}
                </Typography>
              </div>

              <div className="flex flex-row items-center justify-between">
                <Typography fontWeight="bold" sx={{width: 100}}>
                  Clock-in:
                </Typography>

                <TimePicker
                  disabled={selectedUserSchedule.isRestDay}
                  value={dayjs(selectedUserSchedule.clockIn, 'HH:mm:ss')}
                  onChange={newValue => {
                    if (newValue) {
                      const newSelectedUserSchedule = {...selectedUserSchedule};
                      newSelectedUserSchedule.clockIn = newValue.format('HH:mm:ss');
                      setSelectedUserSchedule(newSelectedUserSchedule);
                    }
                  }}
                />
              </div>

              <div className="flex flex-row items-center justify-between">
                <Typography fontWeight="bold" sx={{width: 100}}>
                  Clock-out:
                </Typography>

                <TimePicker
                  disabled={selectedUserSchedule.isRestDay}
                  minTime={dayjs(selectedUserSchedule.clockIn, 'HH:mm:ss')}
                  value={dayjs(selectedUserSchedule.clockOut, 'HH:mm:ss')}
                  onChange={newValue => {
                    if (newValue) {
                      const newSelectedUserSchedule = {...selectedUserSchedule};
                      newSelectedUserSchedule.clockOut = newValue.format('HH:mm:ss');
                      setSelectedUserSchedule(newSelectedUserSchedule);
                    }
                  }}
                />
              </div>

              <div className="flex flex-row items-center">
                <Typography fontWeight="bold" sx={{width: 100}}>
                  Break:
                </Typography>

                <TextField
                  disabled={selectedUserSchedule.isRestDay}
                  sx={{width: 100}}
                  className="!mr-2"
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: 'right',
                    },
                  }}
                  type="number"
                  value={selectedUserSchedule.breakMinutes}
                  onChange={e => {
                    const newSelectedUserSchedule = {...selectedUserSchedule};
                    newSelectedUserSchedule.breakMinutes = parseFloat(e.currentTarget.value);
                    setSelectedUserSchedule(newSelectedUserSchedule);
                  }}
                  onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />

                <Typography>
                  mins
                </Typography>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isSubmitting}
              color="error"
              onClick={handlerCloseUserScheduleFormDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isSubmitting}
              color='primary'
              onClick={handleSubmitUserScheduleForm}>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default UserSchedules;
