// Context
import CostingsContextProvider from './_context/CostingsContext';

// Components
import CostingsContent from './CostingsContents';

const Costings = () => {
  return (
    <CostingsContextProvider>
      <CostingsContent />
    </CostingsContextProvider>
  );
};

export default Costings;
