import {Dayjs} from "dayjs";

export const diffForHumans = (datetime: Dayjs) => {
  let delta = Math.round((+new Date() - datetime.unix() * 1000) / 1000);

  let minute = 60,
    hour = minute * 60,
    day = hour * 24;

  let text = 'Invalid';

  if (delta < 30) {
    text = 'just now';
  } else if (delta < minute) {
    text = delta + ' seconds ago';
  } else if (delta < 2 * minute) {
    text = 'a minute ago';
  } else if (delta < hour) {
    text = Math.floor(delta / minute) + ' minutes ago';
  } else if (Math.floor(delta / hour) === 1) {
    text = '1 hour ago';
  } else if (delta < day) {
    text = Math.floor(delta / hour) + ' hours ago';
  } else if (delta < day * 2) {
    text = 'yesterday';
  } else {
    text = datetime.format('ddd. MMM. DD, YYYY [at] hh:mm A');
  }

  return text;
};

export const searchInputCheck = (searchInput: string, text: string) => {
  return text.toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .includes(searchInput.toLowerCase().replace(/[^\w\s]/gi, ''));
};
