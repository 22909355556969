import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {enqueueSnackbar} from 'notistack';
import {Form, Formik, FormikErrors} from 'formik';
import {isAxiosError} from 'axios';
import * as Yup from 'yup';

// Types
import {
  LabourVariablesProps,
  OverheadsProps,
  ProductProps,
  ProductRawMaterialProps,
} from '../../../../../../models/Product';

// Context
import {CostingsContext} from '../_context/CostingsContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// Controllers
import {loadProductionCost} from '../../../../../../controllers/products/ProductController';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

// APIs
import {deleteProductRawMaterial, duplicateProduct, generateProductReportEmail, getProduct, updateProduct, updateProductLabour, updateProductRawMaterial} from '../../../../_api/mobile/home/costings/costings';

interface SelectedProductComponentProps {
  selectedProduct?: ProductProps;
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductProps | undefined>>;
  labourVariables?: LabourVariablesProps;
  overheads?: OverheadsProps;
  isAdmin: boolean;
}

const SelectedProduct = ({
  selectedProduct,
  setSelectedProduct,
  labourVariables,
  overheads,
  isAdmin,
}: SelectedProductComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [percentAdjust, setPercentAdjust] = useState<string>(
    (selectedProduct?.priceMargin || 0).toFixed(2),
  );
  const [priceAdjust, setPriceAdjust] = useState<string>(
    (selectedProduct?.priceAdjust || 0).toFixed(2),
  );
  const [showAllMaterials, setShowAllMaterials] = useState<boolean>(true);
  const [selectedProductRawMaterial, setSelectedProductRawMaterial] = useState<ProductRawMaterialProps>();
  const [openProductLabourDialog, setOpenProductLabourDialog] = useState<boolean>(false);
  const [openEditProduct, setOpenEditProduct] = useState<boolean>(false);
  const [openDuplicateProductDialog, setOpenDuplicateProductDialog] = useState<boolean>(false);
  const [openGenerateProductReportEmailDialog, setOpenGenerateProductReportEmailDialog] = useState<boolean>(false);

  // Context
  const {products, setProducts} = useContext(CostingsContext);

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      if (selectedProduct) {
        const {status, data} = await getProduct(selectedProduct.id);

        if (status === 200) {
          setSelectedProduct(data.data.product);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedProduct, setSelectedProduct]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const percentAdjustHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > | undefined = (element) => {
    setPercentAdjust(element.target.value);
  };

  const priceAdjustChangeHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > | undefined = (element) => {
    setPriceAdjust(element.target.value);
  };

  const priceAdjustBlurHandler = () => {
    setPriceAdjust(currentValue => currentValue ? parseFloat(currentValue).toFixed(2) : '0.00');
  };
  
  const percentAdjustBlurHandler = () => {
    setPercentAdjust(currentValue => currentValue ? parseFloat(currentValue).toFixed(2) : '0.00');
  };

  const showAllRawMaterialsChangeHandler = () => {
    setShowAllMaterials(!showAllMaterials);
  };

  const handleClickEditLabourDialog = () => {
    setOpenProductLabourDialog(currentValue => !currentValue);
  };

  const handleToggleEditProduct = () => {
    setOpenEditProduct(currentValue => !currentValue);
  };

  const handleToggleDuplicateProduct = () => {
    setOpenDuplicateProductDialog(currentValue => !currentValue);
  };

  const handleToggleOpenGenerateProductReportEmailDialog = () => {
    setOpenGenerateProductReportEmailDialog(currentValue => !currentValue);
  };

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (selectedProduct) {
      setPriceAdjust(selectedProduct.priceAdjust.toString());
      setPercentAdjust(selectedProduct.priceMargin.toString());
    }
  }, [selectedProduct]);

  if (!init) {
    return <LoadingFetchData />;
  }

  if (!selectedProduct) {
    return (
      <div className="p-2 md:p-5">
        <Typography className="text-gray-500">
          Please select a product
        </Typography>
      </div>
    );
  } else {
    const productPreviousCostDateTime = dayjs(selectedProduct.previousCost.createdAt);
    const {factoryCost, warehouseCost, managementCost} = loadProductionCost(labourVariables);
    const productFactoryCost = (selectedProduct.cuttingTime + selectedProduct.sewingTime + selectedProduct.seamSealTime) * factoryCost;
    const productWarehouseCost = warehouseCost * selectedProduct.warehouseTime;
    const productManagementCost = (selectedProduct.cuttingTime + selectedProduct.sewingTime + selectedProduct.warehouseTime + selectedProduct.seamSealTime) * managementCost;
    const labourInHouseCost = productFactoryCost + productWarehouseCost + productManagementCost;
    const totalInHouseCost = labourInHouseCost + productWarehouseCost;
    const pathfinderCostsPerMinute = labourVariables ? (labourVariables.pathfinderCostsBlades + labourVariables.pathfinderCostsPlasticPaper) / 60 : 0;
    let materialsCost = 0;
    let materialsIsEmpty = true;
    
    selectedProduct?.rawMaterials.forEach(indivRawMaterial => {
      const indivMaterialCost = indivRawMaterial.cost * indivRawMaterial.qty;
      materialsCost += indivMaterialCost;
    });
    const materialsOverheads = materialsCost * ((overheads?.MYOB_GAP || 0) / 100);

    let liveCost = (labourInHouseCost + materialsCost) * 1.1554;
    liveCost += selectedProduct.cuttingTime * pathfinderCostsPerMinute;
    liveCost = Math.round(liveCost * 100) / 100;
    let costChange = 0;

    if (selectedProduct.previousCost) {
      costChange = ((liveCost / selectedProduct.previousCost.cost) - 1) * 100;
    }

    return (
      <Fragment>
        <div className="flex flex-col flex-1 p-2 md:p-5">
          <div className="flex flex-col md:flex-row justify-between border-b border-[#000000] pb-1 mb-3 gap-3">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row gap-2">
                <Typography fontSize={20} fontWeight="bold">
                  {selectedProduct?.name}
                </Typography>
      
                <button
                  type="button"
                  disabled={isRefreshing}
                  onClick={refreshHandler}>
                  {isRefreshing ? (
                    <CircularProgress size={12} />
                  ) : (
                    <RefreshIcon color="primary" fontSize="small" />
                  )}
                </button>
              </div>

              <Typography variant="body2">
                Product code: {selectedProduct?.code}
              </Typography>
            </div>

            
            {isAdmin && (
              <div className="flex flex-row self-start gap-3">
                <Button
                  variant="contained"
                  onClick={handleToggleEditProduct}>
                  Edit
                </Button>

                <Button
                  variant="contained"
                  color="success"
                  onClick={handleToggleDuplicateProduct}>
                  Duplicate
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleToggleOpenGenerateProductReportEmailDialog}>
                  Email
                </Button>
              </div>
            )}
          </div>

          <div className="flex flex-row gap-3 mb-5">
            <Paper className="flex flex-1 flex-row gap-3 p-2">
              <div className="flex flex-1 flex-col gap-2">
                <div className="flex flex-row justify-between items-start border-b pb-1">
                  <Typography fontWeight="bold">
                    Labour:
                  </Typography>

                  {isAdmin && (
                    <button
                      type="button"
                      onClick={handleClickEditLabourDialog}>
                      <Typography
                        fontWeight="bold"
                        variant="button"
                        className="!text-blue-500 !lowercase">
                        (Edit)
                      </Typography>
                    </button>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Cutting time:
                    </Typography>
                    <Typography variant="body2">
                      {selectedProduct?.cuttingTime.toFixed(2)} min
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Sewing time:
                    </Typography>
                    <Typography variant="body2">
                      {selectedProduct?.sewingTime.toFixed(2)} min
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Seam-seal time:
                    </Typography>
                    <Typography variant="body2">
                      {selectedProduct?.seamSealTime.toFixed(2)} min
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Warehouse time:
                    </Typography>
                    <Typography variant="body2">
                      {selectedProduct?.warehouseTime.toFixed(2)} min
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>

            <Paper className="flex flex-1 flex-row gap-3 p-2">
              <div className="flex flex-1 flex-col gap-2">
                <Typography fontWeight="bold" className="border-b pb-1">
                  Labour Cost:
                </Typography>

                <div className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      In House:
                    </Typography>
                    <Typography variant="body2">
                      $ {labourInHouseCost.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Warehouse:
                    </Typography>
                    <Typography variant="body2">
                      $ {productWarehouseCost.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Total In House:
                    </Typography>
                    <Typography variant="body2">
                      $ {totalInHouseCost.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Outworker:
                    </Typography>
                    <Typography variant="body2">
                      $ {selectedProduct.outworker.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      O / Work Minutes:
                    </Typography>
                    <Typography variant="body2">
                      {(selectedProduct.outworker / 0.42).toFixed(0)} mins
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>

            <Paper className="flex flex-1 flex-row gap-3 p-2">
              <div className="flex flex-1 flex-col gap-2">
                <Typography fontWeight="bold" className="border-b pb-1">
                  Net Production Cost:
                </Typography>

                <div className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Live Cost:
                    </Typography>
                    <Typography variant="body2">
                      $ {liveCost.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <div className="flex flex-row gap-3">
                      <Typography variant="body2" fontWeight="bold">
                        {selectedProduct.previousCost?.createdAt
                          ? productPreviousCostDateTime.format('MMM. YYYY [Cost]')
                          : 'Previous Cost'}
                      </Typography>
                    </div>
                    <Typography variant="body2">
                      $ {selectedProduct?.previousCost ? selectedProduct?.previousCost?.cost.toFixed(2) : '0.00'}
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      Cost Change:
                    </Typography>
                    <Typography variant="body2">
                      {costChange && costChange !== Infinity ? costChange.toFixed(0) : 0} %
                    </Typography>
                  </div>
                  <div className="flex flex-row justify-between gap-10">
                    <Typography variant="body2" fontWeight="bold">
                      AUD Freight:
                    </Typography>
                    <Typography variant="body2">
                      $ {selectedProduct?.freightCanadian.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>

            <Paper className="flex flex-1 flex-row gap-3 p-2">
              <div className="flex flex-1 flex-col gap-2">
                <Typography fontWeight="bold" className="border-b pb-1">
                  Gross Profit Calculator:
                </Typography>

                <div className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between gap-5">
                    <Typography className="w-full" variant="body2" fontWeight="bold">
                      Percent Adjust:
                    </Typography>
                    
                    <div className="flex flex-row gap-2">
                      <TextField
                        autoComplete='off'
                        value={percentAdjust}
                        type="number"
                        variant="standard"
                        size="small"
                        InputProps={{
                          endAdornment: <Typography variant="body2" children="%" />,
                        }}
                        inputProps={{
                          className: '!pb-0 !pr-1',
                          style: {
                            textAlign: 'right',
                            fontSize: '0.9rem',
                            paddingBottom: '0 !important',
                          },
                        }}
                        onChange={percentAdjustHandler}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onBlur={percentAdjustBlurHandler}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-5">
                    <Typography variant="body2" fontWeight="bold">
                      Sell Price:
                    </Typography>
                    <Typography variant="body2">
                      $ {percentAdjust && liveCost ? (((parseFloat(percentAdjust) / 100) * liveCost) + liveCost).toFixed(2) : '0.00'}
                    </Typography>
                  </div>
                  
                  <Divider />

                  <div className="flex flex-row justify-between gap-5">
                    <Typography className="w-full" variant="body2" fontWeight="bold">
                      Price Adjust:
                    </Typography>
                    
                    <div className="flex flex-row gap-2">
                      <TextField
                        autoComplete='off'
                        value={priceAdjust}
                        type="number"
                        variant="standard"
                        size="small"
                        InputProps={{
                          startAdornment: <Typography variant="body2" children="$" />,
                        }}
                        inputProps={{
                          className: '!pb-0 !pl-1',
                          style: {
                            textAlign: 'right',
                            fontSize: '0.9rem',
                          },
                        }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={priceAdjustChangeHandler}
                        onBlur={priceAdjustBlurHandler}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-5">
                    <Typography variant="body2" fontWeight="bold">
                      Margin:
                    </Typography>
                    <Typography variant="body2">
                      {priceAdjust && liveCost
                        ? (
                            (parseFloat(priceAdjust) - liveCost) / liveCost * 100
                          ).toFixed(2) + ' %'
                        : '-'}
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </div>

          <div className="flex flex-row mb-2 items-center justify-between p-1">
            <div className="flex flex-row items-center gap-2">
              <Typography fontWeight="bold">
                Materials
              </Typography>
            </div>
            

            {selectedProduct?.rawMaterials.length > 3 && (
              <div className="flex flex-row gap-3 items-center">
                <Typography>
                  Show all:
                </Typography>
                <Switch
                  checked={showAllMaterials}
                  onChange={showAllRawMaterialsChangeHandler}
                />
              </div>
            )}
          </div>
        
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Code
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Unit Cost
                  </TableCell>
                  <TableCell>
                    Qty
                  </TableCell>
                  <TableCell>
                    Cost
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProduct?.rawMaterials.map((indivRawMaterial, indivRawMaterialIndex) => {
                  const indivMaterialCost = indivRawMaterial.cost * indivRawMaterial.qty;
                  materialsIsEmpty = false;

                  if (!showAllMaterials && indivRawMaterialIndex > 3) {
                    return null;
                  }

                  return (
                    <TableRow
                      key={indivRawMaterial.code}
                      hover={isAdmin}
                      className={isAdmin ? 'cursor-pointer' : 'cursor-auto'}
                      onClick={() => {
                        if (isAdmin) {
                          setSelectedProductRawMaterial(indivRawMaterial);
                        }
                      }}>
                      <TableCell>
                        {indivRawMaterial.code}
                      </TableCell>
                      <TableCell>
                        {indivRawMaterial.name}
                      </TableCell>
                      <TableCell>
                        $ {indivRawMaterial.cost.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {indivRawMaterial.qty.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        $ {indivMaterialCost.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {materialsIsEmpty ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="caption" className="text-gray-500">
                        No material/s added
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {!showAllMaterials && selectedProduct?.rawMaterials.length > 3 && (
                      <TableRow
                        hover
                        className="cursor-pointer"
                        onClick={showAllRawMaterialsChangeHandler}>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="body2" className="text-gray-500">
                            --- Show hidden materials ---
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell rowSpan={3} colSpan={3} />
                      <TableCell align="right">
                        <span className="font-bold">Sub-total:</span>
                      </TableCell>
                      <TableCell>
                        $ {materialsCost.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <span className="font-bold">Overhead:</span>
                      </TableCell>
                      <TableCell>
                        $ {(materialsOverheads).toFixed(
                          2,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <span className="font-bold">Total:</span>
                      </TableCell>
                      <TableCell>
                        $ {(
                            materialsOverheads +
                            materialsCost
                          ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {isAdmin && (
          <Fragment>
            <Formik
              enableReinitialize
              initialValues={{qty: selectedProductRawMaterial?.qty.toFixed(2) || 1.00.toFixed(2)}}
              validate={values => {
                const errors: {qty?: string} = {};
                
                if (parseFloat(values.qty) < 0 || values.qty === undefined) {
                  errors.qty = 'Invalid value'
                }

                return errors;
              }}
              onSubmit={async (values, {resetForm}) => {
                try {
                  if (selectedProduct && selectedProductRawMaterial) {
                    const {status, data} = await updateProductRawMaterial(
                      selectedProduct.id,
                      selectedProductRawMaterial.id!,
                      parseFloat(values.qty),
                    );

                    if (status === 200) {
                      const newSelectedProduct = {...selectedProduct};
                      newSelectedProduct.rawMaterials.forEach(indivMaterial => {
                        if (indivMaterial.id === data.data.rawMaterial.id) {
                          indivMaterial.qty = data.data.rawMaterial.qty;
                        }

                        return indivMaterial;
                      });
                      setSelectedProduct(newSelectedProduct);
                      resetForm();
                      setSelectedProductRawMaterial(undefined);

                      enqueueSnackbar(
                        data.message,
                        {
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                    }
                  }
                  
                } catch (err) {
                  console.log(err);
                }
              }}>
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleReset,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <Dialog
                    fullWidth
                    disableScrollLock={true}
                    onClose={() => {
                      handleReset();
                    }}
                    open={Boolean(selectedProductRawMaterial)}>
                    <DialogTitle>Update Raw Material for {selectedProduct.name}({selectedProduct.code})</DialogTitle>
                    <DialogContent>
                      <div className="flex flex-row gap-3">
                        <div className="flex flex-col flex-1">
                          <Typography>
                            <span className="font-bold">Name: </span>
                            {selectedProductRawMaterial?.name}
                          </Typography>
                          <Typography>
                            <span className="font-bold">Code: </span>
                            {selectedProductRawMaterial?.code}
                          </Typography>
                        </div>
                        
                        <TextField
                          type="number"
                          variant="standard"
                          label="Qty"
                          name="qty"
                          value={values.qty}
                          autoComplete="off"
                          autoCorrect="off"
                          onChange={handleChange}
                          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                          onBlur={handleBlur}
                          error={Boolean(touched.qty && errors.qty)}
                          helperText={touched.qty && errors.qty}
                        />
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <div className="flex flex-row flex-1 justify-between">
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          color="error"
                          onClick={async () => {
                            try {
                              if (selectedProduct && selectedProductRawMaterial) {
                                const {status, data} = await deleteProductRawMaterial(
                                  selectedProduct.id,
                                  selectedProductRawMaterial.id!,
                                );

                                if (status === 200) {
                                  const newSelectedProduct = {...selectedProduct};
                                  newSelectedProduct.rawMaterials = newSelectedProduct.rawMaterials.filter(indivRawMaterial => {
                                    if (indivRawMaterial.id !== data.data.rawMaterial.id) {
                                      return indivRawMaterial;
                                    }

                                    return null;
                                  });
                                  setSelectedProduct(newSelectedProduct);
                                  setSelectedProductRawMaterial(undefined);

                                  enqueueSnackbar(
                                    data.message,
                                    {
                                      autoHideDuration: 3000,
                                      variant: 'warning',
                                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                    },
                                  );
                                }
                              }
                            } catch (err) {
                              console.log(err);
                            }
                          }}>
                          Remove
                        </Button>

                        <div className="flex flex-row gap-3">
                          <Button
                            disabled={isSubmitting}
                            color="error"
                            onClick={() => {
                              handleReset();
                              setSelectedProductRawMaterial(undefined);
                            }}>
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            color="primary"
                            onClick={() => {
                              handleSubmit();
                            }}>
                            Update
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>

            <Formik
              enableReinitialize
              initialValues={{
                cuttingTime: selectedProduct.cuttingTime.toFixed(2),
                sewingTime: selectedProduct.sewingTime.toFixed(2),
                seamSealTime: selectedProduct.seamSealTime.toFixed(2),
                warehouseTime: selectedProduct.warehouseTime.toFixed(2),
              }}
              validate={values => {
                const errors: {
                  cuttingTime?: string,
                  sewingTime?: string,
                  seamSealTime?: string,
                  warehouseTime?: string,
                } = {};
                
                if (parseFloat(values.cuttingTime) < 0 || values.cuttingTime === undefined) {
                  errors.cuttingTime = 'Invalid value';
                }

                if (parseFloat(values.sewingTime) < 0 || values.sewingTime === undefined) {
                  errors.sewingTime = 'Invalid value';
                }

                if (parseFloat(values.seamSealTime) < 0 || values.seamSealTime === undefined) {
                  errors.seamSealTime = 'Invalid value';
                }

                if (parseFloat(values.warehouseTime) < 0 || values.warehouseTime === undefined) {
                  errors.warehouseTime = 'Invalid value';
                }

                return errors;
              }}
              onSubmit={async (values, {resetForm}) => {
                try {
                  if (selectedProduct) {
                    const {status, data} = await updateProductLabour(
                      selectedProduct.id,
                      parseFloat(values.cuttingTime),
                      parseFloat(values.sewingTime),
                      parseFloat(values.seamSealTime),
                      parseFloat(values.warehouseTime),
                    );

                    if (status === 200) {
                      const newProduct = data.data.product;
                      const newSelectedProoduct = {...selectedProduct};
                      newSelectedProoduct.cuttingTime = newProduct.cuttingTime;
                      newSelectedProoduct.sewingTime = newProduct.sewingTime;
                      newSelectedProoduct.seamSealTime = newProduct.seamSealTime;
                      newSelectedProoduct.warehouseTime = newProduct.warehouseTime;
                      setSelectedProduct(newSelectedProoduct);

                      resetForm();
                      setOpenProductLabourDialog(false);

                      enqueueSnackbar(
                        data.message,
                        {
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                    }
                  }
                } catch (err) {
                  console.log(err);
                }
              }}>
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleReset,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <Dialog
                    disableScrollLock={true}
                    onClose={() => {
                      handleReset();
                      setOpenProductLabourDialog(false);
                    }}
                    open={openProductLabourDialog}>
                    <DialogTitle>Update Labour for {selectedProduct.name}({selectedProduct.code})</DialogTitle>
                    <Divider />
                    <DialogContent>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-3">
                          <Typography fontWeight="bold" className="py-1 w-2/3">
                            Cutting time:
                          </Typography>
                          
                          <TextField
                            type="number"
                            variant="standard"
                            name="cuttingTime"
                            value={values.cuttingTime}
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={handleChange}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onBlur={handleBlur}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                            error={Boolean(touched.cuttingTime && errors.cuttingTime)}
                            helperText={touched.cuttingTime && errors.cuttingTime}
                          />

                          <Typography className="py-1">
                            mins
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-3">
                          <Typography fontWeight="bold" className="py-1 w-2/3">
                            Sewing time:
                          </Typography>
                          
                          <TextField
                            type="number"
                            variant="standard"
                            name="sewingTime"
                            value={values.sewingTime}
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={handleChange}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onBlur={handleBlur}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                            error={Boolean(touched.sewingTime && errors.sewingTime)}
                            helperText={touched.sewingTime && errors.sewingTime}
                          />

                          <Typography className="py-1">
                            mins
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-3">
                          <Typography fontWeight="bold" className="py-1 w-2/3">
                            Seam-seal time:
                          </Typography>
                          
                          <TextField
                            type="number"
                            variant="standard"
                            name="seamSealTime"
                            value={values.seamSealTime}
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={handleChange}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onBlur={handleBlur}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                            error={Boolean(touched.seamSealTime && errors.seamSealTime)}
                            helperText={touched.seamSealTime && errors.seamSealTime}
                          />

                          <Typography className="py-1">
                            mins
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-3">
                          <Typography fontWeight="bold" className="py-1 w-2/3">
                            Warehouse time:
                          </Typography>
                          
                          <TextField
                            type="number"
                            variant="standard"
                            name="warehouseTime"
                            value={values.warehouseTime}
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={handleChange}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onBlur={handleBlur}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                            error={Boolean(touched.warehouseTime && errors.warehouseTime)}
                            helperText={touched.warehouseTime && errors.warehouseTime}
                          />

                          <Typography className="py-1">
                            mins
                          </Typography>
                        </div>
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <Button
                        disabled={isSubmitting}
                        color="error"
                        onClick={() => {
                          handleReset();
                          setOpenProductLabourDialog(false);
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        color="success"
                        onClick={() => {
                          handleSubmit();
                        }}>
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>

            <Formik
              enableReinitialize
              initialValues={selectedProduct}
              validate={values => {
                const errors: FormikErrors<ProductProps> = {};
                
                if (!values.name) {
                  errors.name = 'Name is required';
                }

                if (!values.code) {
                  errors.code = 'Code is required';
                }
                
                return errors;
              }}
              onSubmit={async (values, {setErrors}) => {
                try {
                  const {status, data} = await updateProduct(values);
                  
                  switch (status) {
                    case 200: {
                      const newProduct = {...selectedProduct, ...data.data.product};
                      setSelectedProduct(newProduct);
                      const newProducts = [...products];
                      const productIndex = newProducts.findIndex(indivProduct => (indivProduct.id === newProduct.id));
                      newProducts.splice(productIndex, 1, newProduct);
                      setProducts(newProducts);
                      handleToggleEditProduct();

                      enqueueSnackbar(
                        data.message,
                        {
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                      break;
                    }
                  }
                } catch (err) {
                  let message = 'Something went wrong, please contact your administrator.'
          
                  if (isAxiosError(err)) {
                    const response = err.response;

                    if (response) {
                      message = response.data.message;
                      switch (response.status) {
                        case 400: {
                          setErrors({
                            code: response.data.data?.code,
                          });
                          break;
                        }
                      }
                    }
                  }

                  enqueueSnackbar(
                    message,
                    {
                      autoHideDuration: 3000,
                      variant: 'error',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                }
              }}>
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleReset,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <Dialog
                    fullWidth
                    disableScrollLock={true}
                    onClose={() => {
                      handleReset();
                    }}
                    open={openEditProduct}>
                    <DialogTitle>Update Product</DialogTitle>
                    <DialogContent>
                      <div className="flex flex-col gap-5">
                        <TextField
                          fullWidth
                          variant="standard"
                          label="Code"
                          name="code"
                          value={values.code}
                          autoComplete="off"
                          autoCorrect="off"
                          onChange={handleChange}
                          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                          onBlur={handleBlur}
                          error={Boolean(touched.code && errors.code)}
                          helperText={touched.code && errors.code}
                        />

                        <TextField
                          fullWidth
                          variant="standard"
                          label="Name"
                          name="name"
                          value={values.name}
                          autoComplete="off"
                          autoCorrect="off"
                          onChange={handleChange}
                          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                          onBlur={handleBlur}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <div className="flex flex-row flex-1 justify-end">
                        {/* <Button
                          variant="contained"
                          disabled={isSubmitting}
                          color="error"
                          onClick={async () => {
                            try {
                              
                            } catch (err) {
                              console.log(err);
                            }
                          }}>
                          Delete
                        </Button> */}

                        <div className="flex flex-row gap-3">
                          <Button
                            disabled={isSubmitting}
                            color="error"
                            onClick={() => {
                              handleReset();
                              handleToggleEditProduct();
                            }}>
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            color="primary"
                            onClick={() => {
                              handleSubmit();
                            }}>
                            Update
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>

            <Formik
              enableReinitialize
              initialValues={selectedProduct}
              validate={values => {
                const errors: FormikErrors<ProductProps> = {};
                
                if (!values.name) {
                  errors.name = 'Name is required';
                }

                if (!values.code) {
                  errors.code = 'Code is required';
                }
                
                return errors;
              }}
              onSubmit={async (values, {setErrors}) => {
                try {
                  const {status, data} = await duplicateProduct(values);
                  
                  switch (status) {
                    case 201: {
                      const newProduct = data.data.product;
                      setSelectedProduct(newProduct);
                      const newProducts = [...products];
                      newProducts.push(newProduct);
                      setProducts(newProducts);
                      handleToggleDuplicateProduct();

                      enqueueSnackbar(
                        data.message,
                        {
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                      break;
                    }
                  }
                } catch (err) {
                  let message = 'Something went wrong, please contact your administrator.'
          
                  if (isAxiosError(err)) {
                    const response = err.response;

                    if (response) {
                      message = response.data.message;
                      switch (response.status) {
                        case 400: {
                          setErrors({
                            code: response.data.data?.code,
                          });
                          break;
                        }
                      }
                    }
                  }

                  enqueueSnackbar(
                    message,
                    {
                      autoHideDuration: 3000,
                      variant: 'error',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                }
              }}>
              {({
                isSubmitting,
                handleReset,
                handleSubmit,
              }) => (
                <Form>
                  <Dialog
                    fullWidth
                    disableScrollLock={true}
                    onClose={() => {
                      handleReset();
                    }}
                    open={openDuplicateProductDialog}>
                    <DialogTitle>Duplicate Product</DialogTitle>
                    <DialogContent>
                      <div className="flex flex-col gap-5">
                        <Typography>
                          <span className="font-bold">Code: </span>
                          {selectedProduct.code}
                        </Typography>

                        <Typography>
                          <span className="font-bold">Name: </span>
                          {selectedProduct.name}
                        </Typography>
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <div className="flex flex-row flex-1 justify-end">
                        <div className="flex flex-row gap-3">
                          <Button
                            disabled={isSubmitting}
                            color="error"
                            onClick={() => {
                              handleReset();
                              handleToggleDuplicateProduct();
                            }}>
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            color="success"
                            onClick={() => {
                              handleSubmit();
                            }}>
                            Duplicate
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>

            <Formik
              enableReinitialize
              initialValues={{email: ''}}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                try {
                  const {status, data} = await generateProductReportEmail(selectedProduct, values.email);
                  
                  switch (status) {
                    case 200: {
                      handleToggleOpenGenerateProductReportEmailDialog();

                      enqueueSnackbar(
                        data.message,
                        {
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                        },
                      );
                      break;
                    }
                  }
                } catch (err) {
                  let message = 'Something went wrong, please contact your administrator.'
          
                  if (isAxiosError(err)) {
                    const response = err.response;

                    if (response) {
                      message = response.data.message;
                    }
                  }

                  enqueueSnackbar(
                    message,
                    {
                      autoHideDuration: 3000,
                      variant: 'error',
                      anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                    },
                  );
                }
              }}>
              {({
                values,
                isSubmitting,
                handleReset,
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
              }) => (
                <Form>
                  <Dialog
                    fullWidth
                    disableScrollLock={true}
                    onClose={() => {
                      handleReset();
                    }}
                    open={openGenerateProductReportEmailDialog}>
                    <DialogTitle>Email Product</DialogTitle>
                    <DialogContent>
                      <div className="flex flex-col gap-3">
                        <Typography>
                          <span className="font-bold">Code: </span>
                          {selectedProduct.code}
                        </Typography>

                        <Typography>
                          <span className="font-bold">Name: </span>
                          {selectedProduct.name}
                        </Typography>
                      </div>

                      <TextField
                        fullWidth
                        variant="standard"
                        label="Send to"
                        name="email"
                        placeholder="Please type the email address"
                        value={values.email}
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={handleChange}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </DialogContent>

                    <DialogActions>
                      <div className="flex flex-row flex-1 justify-end">
                        <div className="flex flex-row gap-3">
                          <Button
                            disabled={isSubmitting}
                            color="error"
                            onClick={() => {
                              handleReset();
                              handleToggleOpenGenerateProductReportEmailDialog();
                            }}>
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            color="success"
                            onClick={() => {
                              handleSubmit();
                            }}>
                            Send
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>
          </Fragment>
        )}
      </Fragment>
    );
  }
};

export default SelectedProduct;
