import {useContext} from 'react';

// Components
import {
  UserProfile,
  UserTimesheets,
  UsersList,
} from './_components/timesheet-admin/Widgets';
import TimesheetBoard from '../_components/TimesheetBoard';

// Context
import {DashboardContext} from './_context/DashboardContext';

export const DashboardTimesheetAdmin = () => {
  const {dateToday, updateCount, selectedUserId} = useContext(DashboardContext);

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border">
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2">
        <div className="flex flex-col">
          <div className="bg-[#ffffff] border shadow lg:min-h-[150px] rounded-lg mb-2">
            <UserProfile />
          </div>

          <div className="bg-[#ffffff] border shadow lg:min-h-[500px] max-h-[800px] rounded-lg overflow-hidden hover:overflow-y-scroll">
            <UsersList />
          </div>
        </div>

        <div className="lg:col-span-2 bg-[#ffffff] border shadow rounded-lg">
          <UserTimesheets />
        </div>

        <div className="bg-[#ffffff] border shadow rounded-lg col-span-full xl:col-span-1">
          <TimesheetBoard
            dateToday={dateToday}
            updateCount={updateCount}
            userId={parseInt(selectedUserId ?? '0')}
          />
        </div>
      </div>
    </div>
  );
};
