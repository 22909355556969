// Types
import {LabourVariablesProps} from '../../models/Product';

export const loadProductionCost = (labourVariables?: LabourVariablesProps) => {
  const variables = {
    factoryCost: 0,
    warehouseCost: 0,
    managementCost: 0,
  };

  if (labourVariables) {
    variables.factoryCost = ((labourVariables.annualCostFactory / 52 / 5) / (labourVariables.weeklyHoursFactory / 5)) / 60;
    variables.warehouseCost = ((labourVariables.annualCostWarehouse / 52 / 5) / (labourVariables.weeklyHoursWarehouse / 5)) / 60;
    variables.managementCost = (((labourVariables.annualCostManagement / 52 / 5) * 5) / labourVariables.weeklyHoursFactory) / 60;
  }

  return variables;
}