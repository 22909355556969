import React, {createContext, useState} from 'react';

// Types
import {ProductProps} from '../../../../../../models/Product';

interface CostingsProviderProps {
  children: React.ReactNode;
}

interface CostingsContextProps {
  updateCount: number;
  products: ProductProps[];
  setProducts: React.Dispatch<React.SetStateAction<ProductProps[]>>;
}

const initialCostingsState = {
  updateCount: 0,
  products: [],
  setProducts: () => null,
};

export const CostingsContext = createContext<CostingsContextProps>(initialCostingsState);

export const CostingsContextProvider = ({children}: CostingsProviderProps) => {
  const [updateCount] = useState<number>(0);
  const [products, setProducts] = useState<ProductProps[]>([]);

  return (
    <CostingsContext.Provider
      value={{
        updateCount,
        products,
        setProducts,
      }}>
      {children}
    </CostingsContext.Provider>
  );
};

export default CostingsContextProvider;
