import {useState} from 'react';

// Types
import {RawMaterialProps} from '../../../../../models/Product';

// Components
import RawMaterials from '../costings/_components/RawMaterials';

const Database = () => {
  const [rawMaterials, setRawMaterials] = useState<RawMaterialProps[]>([]);

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border">
      <RawMaterials
        isAdmin={true}
        rawMaterials={rawMaterials}
        setRawMaterials={setRawMaterials}
      />
    </div>
  );
};

export default Database;
