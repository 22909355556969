// Context
import ProductScheduleContextProvider from './_context/ProductScheduleContext';

// Components
import ProductScheduleContent from './ProductScheduleContents';

const ProductSchedule = () => {
  return (
    <ProductScheduleContextProvider>
      <ProductScheduleContent />
    </ProductScheduleContextProvider>
  );
};

export default ProductSchedule;
