import {useContext} from 'react';

// Context
import {AuthContext} from '../../global/auth/context/AuthContext';
import DashboardContextProvider from './_context/DashboardContext';

// Components
import {DashboardTimesheetAdmin} from './DashboardContents';
import {PageUnderDevelopment} from '../../global/_components/ComponentError';

const Dashboard = () => {
  const {webAccess} = useContext(AuthContext);

  let renderDashboard = <PageUnderDevelopment />;

  switch (webAccess) {
    case 'TIMESHEET_ADMINISTRATOR': {
      renderDashboard = <DashboardTimesheetAdmin />;
      break;
    }
  }

  return (
    <DashboardContextProvider>
      {renderDashboard}
    </DashboardContextProvider>
  );
};

export default Dashboard;
