import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs, {Dayjs} from 'dayjs';
import Calendar from 'react-calendar';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import {enqueueSnackbar} from 'notistack';

// Types
import {LeaveRequestDateProps, LeaveTypeProps} from '../../../../../../models/LeaveRequest';
import {ScheduleProps} from '../../../../../../models/Schedule';

// Context
import {HomeContext} from '../../_context/HomeContext';
import {AuthContext} from '../../../../../global/auth/context/AuthContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';

// APIs
import {
  getLeaveCalendar,
  getRequestLeave,
  setLeaveRequestDateStatus,
  submitRequestLeave,
} from '../../../../_api/mobile/home/timeclock/leave-calendar/leave-calendar';

// Utils
import {
  STATUS_ID_APPROVED,
  STATUS_ID_CANCELLED,
  STATUS_ID_PENDING,
} from '../../../../../../utils/constants';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

const LeaveCalendar = () => {
  // Variables
  const leaveDatesApproved: number[] = [];
  const leaveDatesPending: number[] = [];

  // Context
  const {updateCount, dateToday, setUpdateCount} = useContext(HomeContext);
  const {authUser} = useContext(AuthContext);

  // States
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Dayjs>(dateToday);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dateToday);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasEmployees, setHasEmployees] = useState<boolean>(true);
  const [isSelfManaged, setIsSelfManaged] = useState<boolean>(true);
  const [showStaffLeaveRequests, setShowStaffLeaveRequests] =
  useState<boolean>(false);
  const [leaveRequestDates, setLeaveRequestDates] = useState<
    LeaveRequestDateProps[]
  >([]);
  const [openRequestLeaveDialog, setOpenRequestLeaveDialog] = useState<boolean>(false);
  const [leaveTypes, setLeaveTypes] = useState<LeaveTypeProps[]>([]);
  const [userSchedules, setUserSchedules] = useState<ScheduleProps[]>([]);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState<number>(1);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(dayjs());
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs>(selectedStartDate.add(1, 'days'));
  const [proposedLeaveRequestDates, setProposedLeaveRequestDates] = useState<
    LeaveRequestDateProps[]
  >([]);
  

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getLeaveCalendar(currentDate, showStaffLeaveRequests);

      if (status === 200) {
        setLeaveRequestDates(data.data.leaveRequestDates);
        setHasEmployees(data.data.hasEmployees);
        setIsSelfManaged(data.data.isSelfManaged);
        if (!data.data.hasEmployees) {
          setShowStaffLeaveRequests(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
      setIsLoading(false);
    }
  }, [currentDate, showStaffLeaveRequests]);

  const fetchLeaveTypes = useCallback(async () => {
    try {
      const {status, data} = await getRequestLeave();

      if (status === 200) {
        setLeaveTypes(data.data.leaveTypes);
        setUserSchedules(data.data.userSchedules);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handleOpenRequestLeaveDialog = () => {
    setOpenRequestLeaveDialog(true);
  };

  const handleCloseRequestLeaveDialog = useCallback(() => {
    setOpenRequestLeaveDialog(false);
    setSelectedStartDate(dateToday);
    setSelectedEndDate(dateToday.add(1, 'days'));
    setProposedLeaveRequestDates([]);
    setSelectedLeaveTypeId(1);
  }, [dateToday]);

  const handleSubmitRequestLeave = useCallback(
    async () => {
      const selectedLeaveType = leaveTypes.find(indivLeaveType => {
        return selectedLeaveTypeId === indivLeaveType.id;
      });

      if (selectedLeaveType && proposedLeaveRequestDates.length) {
        setIsLoading(true);
        try {
          const {status} = await submitRequestLeave(
            selectedLeaveType,
            proposedLeaveRequestDates,
          );

          if (status === 201) {
            setUpdateCount(newUpdateCount => newUpdateCount + 1);
            enqueueSnackbar(
              'Leave request created, please wait for the admin\'s approval.',
              {
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
            handleCloseRequestLeaveDialog();
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        enqueueSnackbar(
          'Invalid Entries, please check for invalid values in the form.',
          {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );
      }
    },
    [
      selectedLeaveTypeId,
      proposedLeaveRequestDates,
      handleCloseRequestLeaveDialog,
      leaveTypes,
      setUpdateCount,
    ],
  );

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (updateCount) {
      refreshHandler();
    }
  }, [refreshHandler, updateCount]);

  useEffect(() => {
    if (openRequestLeaveDialog && !leaveTypes.length) {
      fetchLeaveTypes();
    }
  }, [openRequestLeaveDialog, leaveTypes, fetchLeaveTypes]);

  useEffect(() => {
    let newCurrentDate = dayjs(selectedStartDate);
    const currentDateDay = newCurrentDate.format('dddd');
    let newLeaveRequestDates: LeaveRequestDateProps[] = [];

    while (newCurrentDate.format('YYYY-MM-DD') !== selectedEndDate.format('YYYY-MM-DD')) {
      if (newLeaveRequestDates.length > 15) {
        break;
      }

      const userSchdule = userSchedules.find(indivUserSchedule => {
        if (indivUserSchedule.day === currentDateDay) {
          return indivUserSchedule;
        }

        return null;
      });
      if (userSchdule && userSchdule.clockIn) {
        newLeaveRequestDates.push({
          date: newCurrentDate.toISOString(),
          startTime: userSchdule.clockIn,
          endTime: userSchdule.clockOut,
          scheduledBreakMinutes: userSchdule.breakMinutes,
          scheduledClockIn: userSchdule.clockIn,
          scheduledClockOut: userSchdule.clockOut,
        });
      }
      newCurrentDate = newCurrentDate.add(1, 'day');
    }

    setProposedLeaveRequestDates(newLeaveRequestDates);
  }, [selectedStartDate, selectedEndDate, userSchedules]);

  return (
    <div className="p-2 md:p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
        <div className="flex flex-col md:flex-1 md:flex-row gap-1 md:items-center md:justify-between">
          <div className="flex flex-row gap-2">
            <Typography fontSize={20} fontWeight="bold">
              Leave Calendar
            </Typography>

            <button
              type="button"
              disabled={isRefreshing}
              onClick={refreshHandler}>
              {isRefreshing ? (
                <CircularProgress size={12} />
              ) : (
                <RefreshIcon color="primary" fontSize="small" />
              )}
            </button>
          </div>

          <Button disabled={isLoading || isSubmitting} onClick={handleOpenRequestLeaveDialog}>
            Request Leave
          </Button>
        </div>
      </div>

      {init ? (
        <div className="flex flex-row-reverse">
          <div
            className="flex flex-col gap-3 max-h-[500px] hover:overflow-y-scroll overflow-hidden flex-1 px-2">
            <Typography fontWeight="bold">
              Leave Requests:
            </Typography>

            {!isLoading ? (
              <Fragment>
                {!leaveRequestDates.length && (
                  <Typography className="text-gray-400">
                    No leave request for this month.
                  </Typography>
                )}
                
                {leaveRequestDates
                  .sort((currentLeaveRequestDate, nextLeaveRequestDate) => {
                    const currentLeaveRequestDateDateTime = dayjs(currentLeaveRequestDate.date);
                    const nextLeaveRequestDateDateTime = dayjs(nextLeaveRequestDate.date);
                    return nextLeaveRequestDateDateTime.diff(currentLeaveRequestDateDateTime);
                  })
                  .map((indivLeaveRequestDate, indivLeaveRequestDateIndex) => {
                    const leaveRequestDateDateTime = dayjs(indivLeaveRequestDate.date);
                    const scheduledClockIn = dayjs(indivLeaveRequestDate.scheduledClockIn, 'HH:mm:ss');
                    const scheduledClockOut = dayjs(indivLeaveRequestDate.scheduledClockOut, 'HH:mm:ss');
                    const startTime = dayjs(indivLeaveRequestDate.startTime, 'HH:mm:ss');
                    const endTime = dayjs(indivLeaveRequestDate.endTime, 'HH:mm:ss');
                    let leaveHours = endTime.diff(startTime, 'seconds') / 3600;
                    let leaveHasBreak = false;
                    if (leaveHours >= 4.5) {
                      leaveHours -= indivLeaveRequestDate.scheduledBreakMinutes / 60;
                      leaveHasBreak = true;
                    }
                    let statusColor = 'text-blue-500';
                    switch (indivLeaveRequestDate.status?.id) {
                      case STATUS_ID_APPROVED: {
                        statusColor = 'text-green-500';
                        if (!leaveDatesApproved.includes(leaveRequestDateDateTime.get('D'))) {
                          leaveDatesApproved.push(leaveRequestDateDateTime.get('D'));
                        }
                        break;
                      }
                      case STATUS_ID_CANCELLED: {
                        statusColor = 'text-red-500';
                        break;
                      }
                      default: {
                        if (!leaveDatesPending.includes(leaveRequestDateDateTime.get('D'))) {
                          leaveDatesPending.push(leaveRequestDateDateTime.get('D'));
                        }
                      }
                    }

                    let border = 'border';
                    if (selectedDate.get('D') === leaveRequestDateDateTime.get('D')) {
                      border += ' border-blue-600';
                    }

                    let showActionButtons = true;
                    if (
                      (indivLeaveRequestDate.user?.id === authUser?.id &&
                        !isSelfManaged) ||
                      indivLeaveRequestDate.status?.id !== STATUS_ID_PENDING
                    ) {
                      showActionButtons = false;
                    }

                    return (
                      <div
                        id={`leave-request-date-${indivLeaveRequestDate.id}`}
                        key={indivLeaveRequestDate.id}
                        className={`${border} rounded p-2 bg-[#ffffff] shadow`}>
                        <div className="border-b flex flex-row gap-1 mb-2">
                          <Typography variant="body1" fontWeight="bold">
                            Name:
                          </Typography>
                          <Typography variant="body1">
                            {indivLeaveRequestDate.user?.firstname} {indivLeaveRequestDate.user?.lastname}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Date:
                          </Typography>
                          <Typography variant="body2">
                            {leaveRequestDateDateTime.format('ddd. MMMM DD, YYYY')}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Schedule:
                          </Typography>
                          <Typography variant="body2">
                            {scheduledClockIn.format('hh:mm A')} - {scheduledClockOut.format('hh:mm A')}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Leave type:
                          </Typography>
                          <Typography variant="body2">
                            {indivLeaveRequestDate.leaveType?.name}{' '}
                            {Boolean(indivLeaveRequestDate.leaveType?.isPaidLeave) && (
                              <span className="text-green-400 font-bold">
                                (Paid)
                              </span>
                            )}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Time:
                          </Typography>
                          <Typography variant="body2">
                            {startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Leave total:
                          </Typography>
                          <Typography variant="body2">
                            {leaveHours.toFixed(2)} hours{' '}
                            {leaveHasBreak && (
                              <span className="text-gray-400">
                                ({indivLeaveRequestDate.scheduledBreakMinutes} minutes break)
                              </span>
                            )}
                          </Typography>
                        </div>

                        <div className="flex flex-row gap-1">
                          <Typography variant="body2" fontWeight="bold">
                            Status:
                          </Typography>
                          <Typography fontWeight="bold" variant="body2" className={statusColor}>
                            {indivLeaveRequestDate.status?.name}
                          </Typography>
                        </div>

                        {showActionButtons && (
                          <div className="flex flex-row justify-around mt-3">
                            <Button
                              disabled={isSubmitting}
                              variant="contained"
                              color="success"
                              onClick={async () => {
                                setIsSubmitting(true);
                                try {
                                  const {status} =
                                    await setLeaveRequestDateStatus(
                                      indivLeaveRequestDate,
                                      indivLeaveRequestDate.user!,
                                      5,
                                    );

                                  if (status === 200) {
                                    setUpdateCount(newUpdateCount => newUpdateCount + 1);
                                  }
                                } catch (err) {
                                  console.log(err);
                                } finally {
                                  setIsSubmitting(false);
                                }
                              }}>
                              Approve
                            </Button>

                            <Button
                              disabled={isSubmitting}
                              variant="contained"
                              color="error"
                              onClick={async () => {
                                setIsSubmitting(true);
                                try {
                                  const {status} =
                                    await setLeaveRequestDateStatus(
                                      indivLeaveRequestDate,
                                      indivLeaveRequestDate.user!,
                                      4,
                                    );

                                  if (status === 200) {
                                    setUpdateCount(newUpdateCount => newUpdateCount + 1);
                                  }
                                } catch (err) {
                                  console.log(err);
                                } finally {
                                  setIsSubmitting(false);
                                }
                              }}>
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Fragment>
            ) : (
              <LoadingFetchData />
            )}
            
          </div>

          <div className="flex flex-col">
            {hasEmployees && (
              <div className="flex flex-row items-center gap-3">
                <Typography fontWeight="bold">
                  All Staff:
                </Typography>
                <Switch
                  checked={showStaffLeaveRequests}
                  onChange={e => {
                    setIsLoading(true);
                    setShowStaffLeaveRequests(e.target.checked);
                  }}
                />
              </div>
            )}
            
            <Calendar
              value={selectedDate.toDate()}
              showNeighboringMonth={false}
              minDetail="month"
              calendarType="gregory"
              tileClassName={({date}) => {
                const classes = [];
                if (leaveDatesApproved.includes(date.getDate())) {
                  classes.push('text-red-600');
                  classes.push('!font-bold');
                } else if (leaveDatesPending.includes(date.getDate())) {
                  classes.push('text-blue-600');
                  classes.push('!font-bold');
                } else if ([0, 6].includes(date.getDay())) {
                  classes.push('!text-black');
                }

                return classes;
              }}
              onActiveStartDateChange={({activeStartDate}) => {
                setIsLoading(true);
                setCurrentDate(dayjs(activeStartDate));
              }}
              minDate={new Date('2023-01-01')}
              maxDate={new Date(dateToday.endOf('year').add(1, 'years').toDate())}
              onChange={date => {
                if (date) {
                  const newDate = dayjs(date.toLocaleString());
                  setSelectedDate(newDate);
                  let selectedLeaveRequestDateElementId: string = ''; 
                  leaveRequestDates.forEach(indivLeaveRequestDate => {
                    if (selectedLeaveRequestDateElementId) {
                      return;
                    }

                    const leaveRequestDateTime = dayjs(indivLeaveRequestDate.date);
                    if (leaveRequestDateTime.get('D') === newDate.get('D')) {
                      if (!selectedLeaveRequestDateElementId) {
                        selectedLeaveRequestDateElementId = `leave-request-date-${indivLeaveRequestDate.id}`;
                      }
                    }
                  });
                  
                  if (selectedLeaveRequestDateElementId) {
                    const selectedElement = document.getElementById(selectedLeaveRequestDateElementId);
                    if (selectedElement) {
                      selectedElement?.scrollIntoView({behavior: 'smooth'});
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      ) : (
        <LoadingFetchData />
      )}

      <Dialog
        maxWidth="sm"
        disableScrollLock={true}
        // onClose={handleCloseRequestLeaveDialog}
        open={openRequestLeaveDialog}>
        <DialogTitle>File a leave</DialogTitle>
        <DialogContent>
          {leaveTypes.length ? (
            <div className="grid gap-4">
              <div className="flex flex-col gap-3">
                <Typography fontWeight="bold">
                  Leave Type:
                </Typography>
                <Select
                  value={selectedLeaveTypeId}
                  onChange={e => {
                    setSelectedLeaveTypeId(e.target.value as number);
                  }}>
                  {leaveTypes.map(indivLeaveType => {
                    return (
                      <MenuItem key={indivLeaveType.id} value={indivLeaveType.id}>
                        {indivLeaveType.name}
                        {Boolean(indivLeaveType.isPaidLeave) && (
                          <span className="text-green-600 ml-1 font-bold">
                            (Paid)
                          </span>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div className="flex flex-col gap-3">
                <Typography fontWeight="bold">
                  Start date:
                </Typography>

                <DatePicker
                  format="ddd. MMM. DD, YYYY"
                  value={selectedStartDate}
                  onChange={newDate => {
                    if (newDate) {
                      setSelectedStartDate(newDate);
                    }
                  }}
                />
              </div>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={150}>
                        Leave dates
                      </TableCell>
                      <TableCell>
                        Start time
                      </TableCell>
                      <TableCell>
                        End Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {proposedLeaveRequestDates.length ? (
                      proposedLeaveRequestDates.map(
                        (indivLeaveRequestDate, indivLeaveRequestDateIndex) => {
                          const leaveRequestDate = dayjs(indivLeaveRequestDate.date);
                          const startTime = dayjs(
                            indivLeaveRequestDate.startTime,
                            'HH:mm:ss',
                          );
                          const endTime = dayjs(
                            indivLeaveRequestDate.endTime,
                            'HH:mm:ss',
                          );
                          let breakTime = indivLeaveRequestDate.scheduledBreakMinutes;
                          let timeDifference =
                            (endTime.unix() - startTime.unix()) / 3600;
                          const breakHours =
                            indivLeaveRequestDate.scheduledBreakMinutes / 60;
                          if (timeDifference > 4) {
                            if (timeDifference > 4 + breakHours) {
                              timeDifference -= breakHours;
                            } else {
                              // breakTime = (timeDifference - 4) * 60; // this is to calculate the specific time of break;
                              timeDifference = 4;
                            }
                          } else {
                            breakTime = 0;
                          }

                          let timeIsInvalid = false;
                          if (startTime.unix() > endTime.unix()) {
                            timeIsInvalid = true;
                          }

                          return (
                            <TableRow key={indivLeaveRequestDateIndex}>
                              <TableCell valign="top">
                                <Typography fontWeight="bold" variant="body2">
                                  {leaveRequestDate.format('ddd. MMM DD, YYYY')}
                                </Typography>
                                <Typography variant="body2">
                                  {timeDifference.toFixed(2)} Hours
                                </Typography>
                                <Typography variant="caption" className="text-gray-400">
                                  {breakTime.toFixed(0)} minutes break
                                </Typography>
                                {timeIsInvalid && (
                                  <Typography variant="caption" className="text-red-600 block">
                                    Invalid time
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell valign="top">
                                <TimePicker
                                  value={startTime}
                                  onChange={newTime => {
                                    if (newTime) {
                                      const newProposedLeaveRequestDates = [...proposedLeaveRequestDates];
                                      newProposedLeaveRequestDates[indivLeaveRequestDateIndex].startTime = newTime.format('HH:mm:ss');
                                      setProposedLeaveRequestDates(newProposedLeaveRequestDates);
                                      if (newTime.unix() > endTime.unix()) {
                                        enqueueSnackbar(
                                          'Invalid time, please check.',
                                          {
                                            autoHideDuration: 3000,
                                            variant: 'warning',
                                            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                          },
                                        );
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell valign="top">
                                <TimePicker
                                  value={endTime}
                                  onChange={newTime => {
                                    if (newTime) {
                                      const newProposedLeaveRequestDates = [...proposedLeaveRequestDates];
                                      newProposedLeaveRequestDates[indivLeaveRequestDateIndex].endTime = newTime.format('HH:mm:ss');
                                      setProposedLeaveRequestDates(newProposedLeaveRequestDates);
                                      if (newTime.unix() < startTime.unix()) {
                                        enqueueSnackbar(
                                          'Invalid time, please check.',
                                          {
                                            autoHideDuration: 3000,
                                            variant: 'warning',
                                            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                                          },
                                        );
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        },
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography fontWeight="bold" align="center" className="text-red-500">
                            Invalid date range
                          </Typography>
                        </TableCell>
                      </TableRow>
                      
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="flex flex-col gap-3">
                <Typography fontWeight="bold">
                  Back to work:
                </Typography>

                <DatePicker
                  format="ddd. MMM. DD, YYYY"
                  value={selectedEndDate}
                  onChange={newDate => {
                    if (newDate) {
                      setSelectedEndDate(newDate);
                    }
                  }}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2">
                  <Typography variant="body2">&bull;</Typography>
                  <Typography variant="body2">
                    Medical certificate required for more than 3 consecutive days of{' '}
                    sick leave.
                  </Typography>
                </div>
                <div className="flex flex-row gap-2">
                  <Typography variant="body2">&bull;</Typography>
                  <Typography variant="body2">Appointments more than two hours are paid leave.</Typography>
                </div>
                <div className="flex flex-row gap-2">
                  <Typography variant="body2">&bull;</Typography>
                  <Typography variant="body2">
                    Appointments less than two hours require an application.
                  </Typography>
                </div>
                <div className="flex flex-row gap-2">
                  <Typography variant="body2">&bull;</Typography>
                  <Typography variant="body2">
                    Annual leave requires three days notice where possible.
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <LoadingFetchData />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isLoading}
            color="error"
            onClick={handleCloseRequestLeaveDialog}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            color="success"
            onClick={handleSubmitRequestLeave}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeaveCalendar;
