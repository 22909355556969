import {createContext, useEffect, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';

interface DashboardProviderProps {
  children: React.ReactNode;
}

interface DashboardContextProps {
  dateToday: Dayjs;
  setDateToday: React.Dispatch<React.SetStateAction<Dayjs>>;
  selectedUserId?: string;
  setSelectedUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateCount: number;
}

const initialDashboardState = {
  dateToday: dayjs(),
  setDateToday: () => null,
  selectedUserId: undefined,
  setSelectedUserId: () => null,
  updateCount: 0,
};

export const DashboardContext = createContext<DashboardContextProps>(initialDashboardState);

export const DashboardContextProvider = ({children}: DashboardProviderProps) => {
  const dateTodayRef = useRef(dayjs());
  const [dateToday, setDateToday] = useState<Dayjs>(dateTodayRef.current);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [updateCount, setUpdateCount] = useState<number>(0);

  useEffect(() => {
    let secondsCounter = dateTodayRef.current.second();

    const interval = setInterval(() => {
      setDateToday(dayjs());

      // check fetch updates every 1 min
      if (secondsCounter === 59) {
        secondsCounter = 0;
        setUpdateCount(count => count + 1);
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        dateToday,
        setDateToday,
        selectedUserId,
        setSelectedUserId,
        updateCount,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
