import axios from 'axios';

// Types
import {CustomerServiceProps} from '../../../../../../models/CustomerService';

// Utils
import {API_URL} from '../../../../../../utils/urls';

const CUSTOMER_SERVICE_URL = `${API_URL}/customer-service`;

interface GetCustomerServiceResponse {
  data: {
    customerService: CustomerServiceProps[];
  };
}

export const getCustomerService = () => {
  return axios.get<GetCustomerServiceResponse>(CUSTOMER_SERVICE_URL);
};
