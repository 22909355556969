import {Outlet} from 'react-router-dom';

// Images
import DashboardBackgroundSVG from '../../../assets/svg/backgrounds/dashboard-background.svg';
import Header from '../../global/layout/header/Header';

export const Root = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${DashboardBackgroundSVG})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: 50,
        minHeight: '100vh',
      }}>
      <Header />
      <Outlet />
    </div>
  );
};

export default Root;
