import axios from 'axios';
import {API_URL} from '../../../../../../utils/urls';

// Types
import {UserProps} from '../../../../../../models/User';
import {ScheduleProps} from '../../../../../../models/Schedule';
import {RoleProps} from '../../../../../../models/Role';
import {UserForm} from '../../../../mobile/home/administrator/AdministratorContents';
import {RouteProps} from '../../../../../../models/Route';
import {ProductScheduleTypeProps} from '../../../../../../models/ProductSchedule';

// Constants
export const HOME_ADMINISTRATOR_API_URL =
  API_URL + '/mobile/home/administrator';

interface ResponseGetAdministratorScreenData {
  data: {
    users: UserProps[];
    roles: RoleProps[];
  };
}

export function getHomeAdministratorData() {
  return axios.get<ResponseGetAdministratorScreenData>(
    HOME_ADMINISTRATOR_API_URL,
  );
}

interface ResponseGetAdministratorUserScreenData {
  data: {
    user: UserProps;
    userSchedules: ScheduleProps[];
  };
}

export function getHomeAdministratorUserData(userId: UserProps['id']) {
  return axios.get<ResponseGetAdministratorUserScreenData>(
    HOME_ADMINISTRATOR_API_URL + `/${userId}`,
  );
}

interface ResponseLogoutAdministratorUser {
  data: {
    user: UserProps;
  };
}

export function logoutAdministratorUser(user: UserProps) {
  return axios.post<ResponseLogoutAdministratorUser>(
    HOME_ADMINISTRATOR_API_URL + `/${user.id}/logout`,
  );
}

interface ResponseSetAdministratorUserAccess {
  data: {
    user: UserProps;
  };
}

export function setAdministratorUserAccess(user: UserProps) {
  return axios.post<ResponseSetAdministratorUserAccess>(
    HOME_ADMINISTRATOR_API_URL + `/${user.id}/block`,
  );
}

interface ResponseUpdateUserSchedule {
  data: {
    userSchedule: ScheduleProps;
  };
}

export function updateUserSchedule(user: UserProps, userSchedule: ScheduleProps) {
  const requestBody = {
    id: userSchedule.id,
    clock_in: userSchedule.clockIn,
    clock_out: userSchedule.clockOut,
    break_minutes: userSchedule.breakMinutes,
    rest_day: userSchedule.isRestDay,
  };

  return axios.patch<ResponseUpdateUserSchedule>(
    HOME_ADMINISTRATOR_API_URL + `/${user.id}/schedule`,
    requestBody,
  );
}

interface ResponseSubmitUserForm {
  message: string;
  data: {
    user: UserProps;
  };
}

export function submitUserForm(user: UserForm) {
  const requestBody = {
    id: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    country_code: user.countryCode,
    mobile_number: user.mobileNumber,
    superior: user.superior,
    role: user.role,
    staff_credit: user.staffCredit,
  };

  return axios.post<ResponseSubmitUserForm>(
    HOME_ADMINISTRATOR_API_URL,
    requestBody,
  );
}

interface ResponseGetUserRoutes {
  message: string;
  data: {
    userRoutes: RouteProps[];
    routes: RouteProps[];
  };
}

export function getUserRoutes(userId: UserProps['id']) {
  return axios.get<ResponseGetUserRoutes>(
    `${HOME_ADMINISTRATOR_API_URL}/${userId}/home-navigation`,
  );
}

interface ResponseUpdateUserRoute {
  message: string;
  data: {
    userRoute: RouteProps;
  };
}

export function updateUserRoute(userId: UserProps['id'], route: RouteProps) {
  const requestBody = {id: route.id};

  return axios.patch<ResponseUpdateUserRoute>(
    `${HOME_ADMINISTRATOR_API_URL}/${userId}/home-navigation`,
    requestBody,
  );
}

interface ResponseGetProductScheduleAdminScreenData {
  message: string;
  data: {
    productScheduleTypes: ProductScheduleTypeProps[];
  };
}

export function getProductScheduleAdminScreenData() {
  return axios.get<ResponseGetProductScheduleAdminScreenData>(
    `${HOME_ADMINISTRATOR_API_URL}/product-schedule`,
  );
}

