import {Fragment, useCallback, useContext, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {TimePicker} from '@mui/x-date-pickers';

// Context
import {HomeContext} from '../mobile/home/_context/HomeContext';

// Types
import {
  IntegratedTimesheetLogProps,
  TimesheetProps,
} from '../../../models/Timesheet';
import {UserProps} from '../../../models/User';

// Components
import {LoadingFetchData} from '../../global/_components/Loading';

// Icons
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

// APIs
import {
  submitAdjustLoggedTime,
} from '../_api/mobile/home/timeclock/my-hours/my-hours';
import { enqueueSnackbar } from 'notistack';

interface UserTimesheetsTableProps {
  timesheets: TimesheetProps[];
  userId: UserProps['id'];
  isLoading: boolean;
}

interface TimesheetDataProps {
  id: number;
  date: Dayjs;
  hours: number;
  logs: IntegratedTimesheetLogProps[];
}

const UserTimesheetsTable = ({
  timesheets,
  userId,
  isLoading,
}: UserTimesheetsTableProps) => {
  const [openChangeTimesheetLogDialog, setOpenChangeTimesheetLogDialog]
    = useState<boolean>(false);
  const [selectedTimesheetLogs, setSelectedTimesheetLogs]
    = useState<IntegratedTimesheetLogProps>();
  const [selectedTimesheet, setSelectedTimesheet]
    = useState<TimesheetProps>();

  // Context
  const {setUpdateCount} = useContext(HomeContext);

  // Hooks
  const mdScreen = useMediaQuery('(min-width:768px)');

  // Variables
  const timeStringFormat = 'hh:mm A';
  let timesheetsIsEmpty = true;
  const timesheetData: TimesheetDataProps[] = [];
  timesheets.forEach(indivTimesheet => {
    const timesheetDate = dayjs(indivTimesheet.createdAt);
    let renderedHours = 0;
    let clockInTimestamp = 0;
    const logs: IntegratedTimesheetLogProps[] = [];
    const clockIn = dayjs(
      indivTimesheet.scheduledClockIn,
      'HH:mm:ss',
    );
    const clockOut = dayjs(
      indivTimesheet.scheduledClockOut,
      'HH:mm:ss',
    );
    const breakMinutes = indivTimesheet.scheduledBreakMinutes;
    const scheduledWorkHours =
      clockOut.diff(clockIn, 'seconds') / 3600;
    const breakHours = breakMinutes / 60;

    indivTimesheet.timesheetLogs.forEach(indivTimesheetLog => {
      const time = dayjs(indivTimesheetLog.time, 'HH:mm:ss');
      switch (indivTimesheetLog.type) {
        case 'CLOCK-IN': {
          clockInTimestamp = time.unix();
          logs.push({clockIn: indivTimesheetLog});
          break;
        }
        case 'CLOCK-OUT': {
          let clockedHours = (time.unix() - clockInTimestamp) / 3600;

          if (indivTimesheet.scheduledClockIn) {
            if (scheduledWorkHours === clockedHours) {
              clockedHours -= breakHours;
            }
          }

          renderedHours += clockedHours;
          // totalHours += clockedHours;
          logs[logs.length - 1].clockOut = indivTimesheetLog;
          break;
        }
      }
    });

    timesheetData.push({
      id: indivTimesheet.id,
      date: timesheetDate,
      hours: renderedHours,
      logs,
    });
  });

  // Handlers
  const handleCloseTimesheetLogDialog = useCallback(
    () => {
      setOpenChangeTimesheetLogDialog(false);
      setSelectedTimesheetLogs(undefined);
      setSelectedTimesheet(undefined);
    }, [],
  );

  const handleSubmitTimesheetLogChange = useCallback(
    async () => {
      if (selectedTimesheet) {
        if (selectedTimesheetLogs) {
          const clockIn = dayjs(selectedTimesheetLogs.clockIn.time, 'HH:mm:ss');
          const clockOut = dayjs(selectedTimesheetLogs.clockOut!.time, 'HH:mm:ss');

          if (clockIn.unix() <= clockOut.unix()) {
            const {status} = await submitAdjustLoggedTime(
              selectedTimesheet,
              selectedTimesheetLogs,
              clockIn,
              clockOut,
            );

            console.log(status);
            if (status === 201) {
              setUpdateCount(newCount => newCount + 1);
              handleCloseTimesheetLogDialog();

              enqueueSnackbar(
                'Change request submitted, please wait for the manager\'s approval.',
                {
                  autoHideDuration: 3000,
                  variant: 'success',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            }
          }
        } else {
          //
        }
      }
    },
    [selectedTimesheet, selectedTimesheetLogs, setUpdateCount, handleCloseTimesheetLogDialog],
  );

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="md:w-[200px] lg:w-auto xl:md:w-[200px]">
                Date
              </TableCell>
              <TableCell
                className="md:w-[250px] lg:w-auto xl:md:w-[250px]">
                Logs
              </TableCell>
              {Boolean(mdScreen) && (
                <TableCell>
                  Remarks
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Fragment>
                {timesheets
                  .map(indivTimesheet => {
                    timesheetsIsEmpty = false;
                    const timesheet = timesheetData.find(indivTimesheetDatum => {
                      return indivTimesheetDatum.id === indivTimesheet.id;
                    })!;

                    return (
                      <TableRow key={indivTimesheet.id} sx={{verticalAlign: 'top'}}>
                        <TableCell>
                          <div className="grid gap-1">
                            <Typography variant="inherit" fontWeight="bold">
                              {timesheet.date.format('ddd. MMMM DD, YYYY')}
                            </Typography>
                            <Typography variant="inherit">
                              <span className="font-bold">Sub-total: </span>
                              {timesheet.hours.toFixed(2)}
                              <span> Hours</span>
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex flex-col gap-1">
                            {timesheet.logs.map((indivLog, indivLogIndex) => {
                              const loggedClockIn = dayjs(indivLog.clockIn.time, 'HH:mm:ss');
                              const loggedClockOut = dayjs(indivLog.clockOut?.time, 'HH:mm:ss');
                              const clockInString = loggedClockIn.format(timeStringFormat);
                              const clockOutString = indivLog.clockOut
                                ? loggedClockOut.format(timeStringFormat)
                                : 'clocked-in';

                              return (
                                <div key={indivLogIndex} className="flex flex-row gap-2">
                                  <Typography variant="inherit">
                                    &bull;
                                  </Typography>
                                  <div className="flex flex-row items-center gap-1">
                                    <Typography variant="inherit">
                                      {`${clockInString} - ${clockOutString}`}
                                    </Typography>

                                    <button
                                      type="button"
                                      className="text-blue-600"
                                      onClick={() => {
                                        setSelectedTimesheet(indivTimesheet);
                                        const newLog = {...indivLog};
                                        if (!indivLog.clockOut) {
                                          newLog.clockOut = {
                                            id: 0,
                                            time: loggedClockIn.format('HH:mm:ss'),
                                            type: 'CLOCK-OUT',
                                            timesheetLogChanges: [],
                                          };
                                        }
                                        setSelectedTimesheetLogs(newLog);
                                        setOpenChangeTimesheetLogDialog(true);
                                      }}>
                                      <ManageHistoryIcon fontSize="small" />
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </TableCell>
                        {Boolean(mdScreen) && (
                          <TableCell>
                            <div className="flex flex-col gap-2">
                              {indivTimesheet.timesheetNotes
                                .sort((currentTimesheetNote, nextTimesheetNote) => {
                                  const currentTimesheetNoteDateTime = dayjs(currentTimesheetNote.createdAt);
                                  const nextTimesheetNoteDateTime = dayjs(nextTimesheetNote.createdAt);
                                  return nextTimesheetNoteDateTime.diff(currentTimesheetNoteDateTime);
                                })
                                .map(indivTimesheetNote => {
                                  const timesheetNoteDateTime = dayjs(indivTimesheetNote.createdAt);

                                  return (
                                    <div key={indivTimesheetNote.id} className="flex flex-col gap-1">
                                      <Typography variant="inherit" fontWeight="bold">
                                        {timesheetNoteDateTime.format('ddd. MMMM DD, YYYY @ hh:mm A')}
                                      </Typography>
                                      <Typography whiteSpace="break-spaces" variant="inherit">
                                        {indivTimesheetNote.comment}
                                      </Typography>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                }

                {Boolean(timesheetsIsEmpty) && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="caption" color="gray">
                        {userId ? 'No logs yet.' : 'Please select a user.'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <LoadingFetchData />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        maxWidth="xs"
        disableScrollLock={true}
        onClose={handleCloseTimesheetLogDialog}
        open={openChangeTimesheetLogDialog}>
        <DialogTitle>Change Log</DialogTitle>
        {selectedTimesheetLogs ? (
          <DialogContent>
            <div className="grid gap-4">
              <div className="flex flex-row gap-3">
                <Typography fontWeight="bold">
                  Date:
                </Typography>

                <Typography>
                  {dayjs(selectedTimesheet?.createdAt).format('ddd. MMM. DD, YYYY')}
                </Typography>
              </div>

              <div className="flex flex-row gap-3 items-center justify-between">
                <Typography fontWeight="bold">
                  CLOCK-IN:
                </Typography>

                <TimePicker
                  value={dayjs(selectedTimesheetLogs.clockIn.time, 'HH:mm:ss')}
                  onChange={newValue => {
                    if (newValue) {
                      const newSelectedTimesheetLogs = {...selectedTimesheetLogs};
                      newSelectedTimesheetLogs.clockIn.time = newValue.format('HH:mm:ss');
                      setSelectedTimesheetLogs(newSelectedTimesheetLogs);
                    }
                  }}
                />
              </div>

              <div className="flex flex-row gap-3 items-center justify-between">
                <Typography fontWeight="bold">
                  CLOCK-OUT:
                </Typography>
                <div className="flex flex-col">
                  <TimePicker
                    value={dayjs(selectedTimesheetLogs.clockOut?.time || selectedTimesheetLogs.clockIn.time, 'HH:mm:ss')}
                    onChange={newValue => {
                      if (newValue) {
                        const newSelectedTimesheetLogs = {...selectedTimesheetLogs};
                        newSelectedTimesheetLogs.clockOut!.time = newValue.format('HH:mm:ss');
                        setSelectedTimesheetLogs(newSelectedTimesheetLogs);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>
                Please select a log.
              </DialogContentText>
            </DialogContent>
          )
        }

        <DialogActions>
          <Button
            color="error"
            onClick={handleCloseTimesheetLogDialog}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={handleSubmitTimesheetLogChange}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UserTimesheetsTable;
