import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-calendar/dist/Calendar.css';
import 'ckeditor5/ckeditor5.css';

// Components
import AuthInit from './app/global/auth/AuthInit';

// Routes
import AppRoutes from './routes/AppRoutes';

const App = () => {
  return (
    <AuthInit>
      <AppRoutes />
    </AuthInit>
  );
};

export default App;
