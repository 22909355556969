import axios from 'axios';
// Utils
import {API_URL} from '../../../../../../utils/urls';

// Types
import {
  StaffHandbookViewLogType,
  StaffHandbookProps,
  StaffHandbookViewLogProps,
} from '../../../../../../models/StaffHandbook';

// Constants
export const STAFF_HANDBOOK_API_URL = API_URL + '/mobile/home/staff-handbook';

interface ResponseSubmitStaffHandbookForm {
  message?: string;
  data: {
    staffHandbook: StaffHandbookProps;
  };
}

export type StaffHandbookFormProps = Omit<StaffHandbookProps, 'sort' | 'indent' | 'isHeader' | 'isSeen'>;

export function submitStaffHandbookForm(
  staffHandbook: StaffHandbookFormProps,
) {
  const requestBody = staffHandbook;

  return axios.post<ResponseSubmitStaffHandbookForm>(
    STAFF_HANDBOOK_API_URL,
    requestBody,
  );
}

interface ResponsegetStaffHandbooksListData {
  message?: string;
  data: {
    isAdmin: boolean;
    staffHandbooks: StaffHandbookProps[];
  };
}

export function getStaffHandbooksListData() {
  return axios.get<ResponsegetStaffHandbooksListData>(STAFF_HANDBOOK_API_URL);
}

interface ResponseGetStaffHandbookData {
  message?: string;
  data: {
    isAdmin: boolean;
    staffHandbook: StaffHandbookProps;
    staffHandbookViewLogs: StaffHandbookViewLogProps[];
  };
}

export function getStaffHandbookData(staffHandbook: StaffHandbookProps) {
  return axios.get<ResponseGetStaffHandbookData>(
    STAFF_HANDBOOK_API_URL + '/' + staffHandbook.id,
  );
}

interface ResponseLogStaffHandbookView {
  message?: string;
}

export function logStaffHandbookView(
  staffHandbook: StaffHandbookProps,
  type: StaffHandbookViewLogType,
) {
  const requestBody = {type};

  return axios.put<ResponseLogStaffHandbookView>(
    STAFF_HANDBOOK_API_URL + '/' + staffHandbook.id,
    requestBody,
  );
}

interface ResponseDeleteStaffHandbook {
  message?: string;
  data: {
    staffHandbook: StaffHandbookProps;
  };
}

export function deleteStaffHandbook(staffHandbook: StaffHandbookFormProps) {
  return axios.delete<ResponseDeleteStaffHandbook>(
    STAFF_HANDBOOK_API_URL + '/' + staffHandbook.id,
  );
}

interface ResponseSortStaffHandbooks {
  message?: string;
  data: {
    staffHandbooks: StaffHandbookProps[];
  };
}

export function sortStaffHandbookData(staffHandbooks: StaffHandbookProps[]) {
  const requestBody: {staff_handbooks: {id: number}[]} = {
    staff_handbooks: [],
  };

  staffHandbooks.forEach(indivStaffHandbook => {
    requestBody.staff_handbooks.push({id: indivStaffHandbook.id!});
  });

  return axios.patch<ResponseSortStaffHandbooks>(
    STAFF_HANDBOOK_API_URL + '/sort',
    requestBody,
  );
}

interface ResponseUpdateStaffHandbook {
  message?: string;
  data: {
    staffHandbook: StaffHandbookProps;
  };
}

export function updateStaffHandbook(staffHandbook: StaffHandbookProps) {
  const requestBody = {
    ...staffHandbook,
    is_header: staffHandbook.isHeader,
  };

  return axios.patch<ResponseUpdateStaffHandbook>(
    STAFF_HANDBOOK_API_URL + '/' + staffHandbook.id,
    requestBody,
  );
}