import axios from 'axios';

// Utils
import { API_URL } from '../../../../../utils/urls';

// Types
import {ProductScheduleProps, PurchaseOrderProps} from '../../../../../models/ProductSchedule';

const PRODUCT_SCHEDULE_URL = `${API_URL}/product-schedule`;

interface GetProductScheduleUserScreenResponse {
  data: {
    purchaseOrders: PurchaseOrderProps[];
    unassignedPurchaseOrders: PurchaseOrderProps[];
    productSchedule: ProductScheduleProps;
  };
}

export const getProductScheduleUserScreen = () => {
  return axios.get<GetProductScheduleUserScreenResponse>(PRODUCT_SCHEDULE_URL);
};

interface SetPurchaseOrderStatusResponse {
  data: {
    purchaseOrder: PurchaseOrderProps;
  };
}

export const setPurchaseOrderStatus = (
  productSchedule: ProductScheduleProps,
  purchaseOrder: PurchaseOrderProps,
) => {
  const requestBody = {
    purchase_order_id: purchaseOrder.id,
  };

  return axios.patch<SetPurchaseOrderStatusResponse>(
    PRODUCT_SCHEDULE_URL + '/move',
    requestBody,
  );
};


