import {createContext, useEffect, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';

interface ProductScheduleProviderProps {
  children: React.ReactNode;
}

interface ProductScheduleContextProps {
  dateToday: Dayjs;
  setDateToday: React.Dispatch<React.SetStateAction<Dayjs>>;
  updateCount: number;
  setUpdateCount: React.Dispatch<React.SetStateAction<number>>;
}

const initialState = {
  dateToday: dayjs(),
  setDateToday: () => null,
  updateCount: 0,
  setUpdateCount: () => null,
};

export const ProductScheduleContext = createContext<ProductScheduleContextProps>(initialState);

export const ProductScheduleContextProvider = ({children}: ProductScheduleProviderProps) => {
  const dateTodayRef = useRef(dayjs());
  const [dateToday, setDateToday] = useState<Dayjs>(dateTodayRef.current);
  const [updateCount, setUpdateCount] = useState<number>(0);

  useEffect(() => {
    let secondsCounter = dateTodayRef.current.second();

    const interval = setInterval(() => {
      setDateToday(dayjs());

      // check fetch updates every 1 min
      if (secondsCounter === 59) {
        secondsCounter = 0;
        setUpdateCount(count => count + 1);
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ProductScheduleContext.Provider
      value={{
        dateToday,
        setDateToday,
        updateCount,
        setUpdateCount,
      }}>
      {children}
    </ProductScheduleContext.Provider>
  );
};

export default ProductScheduleContextProvider;
