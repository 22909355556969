import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {DndContext, DragEndEvent, useDroppable} from '@dnd-kit/core';

// Types
import {
  LabourVariablesProps,
  OverheadsProps,
  ProductProps,
  RawMaterialProps,
} from '../../../../../models/Product';

// Context
import {CostingsContext} from './_context/CostingsContext';

// Components
import {LoadingFetchData} from '../../../../global/_components/Loading';
import Products from './_components/Products';
import SelectedProduct from './_components/SelectedProduct';
import RawMaterials from './_components/RawMaterials';

// APIs
import {
  addProductRawMaterial,
  createProduct,
  getCostingsScreenData,
} from '../../../_api/mobile/home/costings/costings';

const CostingsContents = () => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductProps>();
  const [labourVariables, setLabourVariables] = useState<LabourVariablesProps>();
  const [overheads, setOverheads] = useState<OverheadsProps>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [openCreateProductDialog, setOpenCreateProductDialog] = useState<boolean>(false);
  const [productCode, setProductCode] = useState<ProductProps['code']>('');
  const [productName, setProductName] = useState<ProductProps['name']>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [rawMaterials, setRawMaterials] = useState<RawMaterialProps[]>([]);

  // Context
  const {products, setProducts} = useContext(CostingsContext);
  
  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getCostingsScreenData();

      if (status === 200) {
        setProducts(data.data.products);
        setLabourVariables(data.data.labourVariables);
        setOverheads(data.data.overheads);
        setIsAdmin(data.data.isAdmin);
        if (data.data.products.length && !selectedProduct) {
          setSelectedProduct(data.data.products[0]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedProduct, setProducts]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handleCloseCreateProductDialog = () => {
    setOpenCreateProductDialog(false);
    setProductCode('');
    setProductName('');
  };

  const handleCreateProduct = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const {status, data} = await createProduct(productCode, productName);

      if (status === 201) {
        const newProducts = [...products];
        newProducts.push(data.data.product);
        setProducts(newProducts);
        handleCloseCreateProductDialog();

        enqueueSnackbar(
          'Changes applied.',
          {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
          },
        );
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong, please contact your admin.',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [productCode, productName, products, setProducts]);

  const handleClickAddProduct = () => {
    setOpenCreateProductDialog(!openCreateProductDialog);
  };

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    try {
      if (event.over) {
        if (event.over.id) {
          if (selectedProduct) {
            const selectedProductId = parseInt(event.over.id.toString());
            const activeRawMaterialId = parseInt(event.active.id.toString());
            const existingMaterial = selectedProduct.rawMaterials.find(indivRawMaterial => {
              if (indivRawMaterial.id === activeRawMaterialId) {
                return indivRawMaterial;
              }

              return null;
            });

            if (!existingMaterial) {
              const {status, data} = await addProductRawMaterial(
                selectedProductId,
                activeRawMaterialId,
              );
            
              if (status === 201) {
                const newSelectedProduct = {...selectedProduct};
                newSelectedProduct.rawMaterials.push(data.data.rawMaterial);
                setSelectedProduct(newSelectedProduct);
              }
            } else {
              enqueueSnackbar(
                `${existingMaterial.name} (${existingMaterial.code}) already exist.`,
                {
                  autoHideDuration: 3000,
                  variant: 'warning',
                  anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                },
              );
            }
          }
        } else {
          enqueueSnackbar(
            'Please select a product.',
            {
              autoHideDuration: 3000,
              variant: 'warning',
              anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            },
          );
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        'Something went wrong, please contact your admin',
        {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
        },
      );
    }
  }, [selectedProduct]);

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);


  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <Fragment>
      <DndContext onDragEnd={handleDragEnd}>
        <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
          <div className="grid grid-cols-4 gap-2">
            <div className="bg-[#d7fed5] col-span-1 border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
              <Products
                isRefreshing={isRefreshing}
                refreshHandler={refreshHandler}
                isAdmin={isAdmin}
                products={products}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                handleClickAddProduct={handleClickAddProduct}
              />
            </div>

            
            <DroppableComponent selectedProductId={selectedProduct?.id}>
              <SelectedProduct
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                labourVariables={labourVariables}
                overheads={overheads}
                isAdmin={isAdmin}
              />
            </DroppableComponent>
            
          </div>

          <div className="bg-[#fdfcd0] border shadow rounded-lg">
            <RawMaterials
              isAdmin={isAdmin}
              rawMaterials={rawMaterials}
              setRawMaterials={setRawMaterials}
            />
          </div>
        </div>
      </DndContext>

      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleCloseCreateProductDialog}
        open={openCreateProductDialog}>
        <DialogTitle>Create product</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-3">
            <TextField
              variant="standard"
              fullWidth
              value={productCode}
              autoComplete="off"
              autoCorrect="off"
              placeholder="Input the code"
              label="Code"
              onChange={e => {
                setProductCode(e.target.value);
              }}
            />

            <TextField
              variant="standard"
              fullWidth
              value={productName}
              autoComplete="off"
              autoCorrect="off"
              label="Description"
              placeholder="Input the code"
              onChange={e => {
                setProductName(e.target.value);
              }}
            />
          </div>
          
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={handleCloseCreateProductDialog}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            onClick={handleCreateProduct}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

interface DnDProps {
  children: React.ReactNode;
  selectedProductId?: number;
}

const DroppableComponent = ({children, selectedProductId}: DnDProps) => {
  const {isOver, setNodeRef} = useDroppable({
    id: selectedProductId ?? 0,
  });
  const style = {
    border: isOver && selectedProductId ? '2px solid green' : undefined,
  };
  
  
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="bg-[#d3e4fd] col-span-3 border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
      {children}
    </div>
  );
};

export default CostingsContents;
