import axios from 'axios';
// Utils
import {API_URL} from '../../../../../utils/urls';

// Constants
export const USER_ACCESS_API_URL = API_URL + '/user-access';

interface ResponseSwitchUserAccess {
  data: {
    message: string;
  };
}

export function switchUserAccess() {
  return axios.post<ResponseSwitchUserAccess>(USER_ACCESS_API_URL + '/switch');
}
