import {Link, useLocation} from 'react-router-dom';
import {Button} from '@mui/material';

// Icons
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

export const LoginTypeButtons = ({disabled = false}: {disabled: boolean}) => {
  const location = useLocation();

  return (
    <div className="flex gap-3 mt-3">
      <Button
        className="flex-1"
        disabled={disabled}
        variant={location.pathname.includes('login') ? 'contained' : 'text'}
        color={location.pathname.includes('login') ? 'success' : 'inherit'}>
        <Link to="/auth/login">
          <EmailIcon className="mr-2" />
          Use email
        </Link>
      </Button>

      <Button
        className="flex-1"
        disabled={disabled}
        variant={location.pathname.includes('mobile') ? 'contained' : 'text'}
        color={location.pathname.includes('mobile') ? 'success' : 'inherit'}>
        <Link to="/auth/mobile">
          <PhoneIcon className="mr-2" />
          Use Mobile
        </Link>
      </Button>
    </div>
  );
};

