import {useContext} from 'react';
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

// Types
import {RouteProps} from '../models/Route';

// Components
import Root from '../app/pages/root/Root';
import Auth from '../app/pages/auth/Auth';
import Login from '../app/pages/auth/Login';
import Mobile from '../app/pages/auth/Mobile';
import ForgotPassword from '../app/pages/auth/ForgotPassword';

// Fallback Components
import PageNotFound from '../app/pages/error/PageNotFound';

// Context
import {AuthContext} from '../app/global/auth/context/AuthContext';

// Controllers
import {RenderRouteElement} from '../controllers/routes/RoutesControllers';

const AppRoutes = () => {
  const {authUser, routes} = useContext(AuthContext);

  const renderRoutesRecursion = (routesQuery: RouteProps[], parentRoute?: RouteProps['route']) => {
    const newRoutes: RouteObject[] = routesQuery.map(indivRoute => {
      const path = parentRoute ? `${parentRoute}/${indivRoute.route}` : `/${indivRoute.route}`;
      const children = indivRoute.subRoutes.length
        ? [
          {
            index: true,
            element: <Navigate to={`${path}/${indivRoute.subRoutes[0].route}`} replace />,
          },
          ...renderRoutesRecursion(indivRoute.subRoutes, path),
        ] : undefined;

      const route: RouteObject = {
        path,
        element: RenderRouteElement(indivRoute.route),
        children,
      };

      return route;
    });

    return newRoutes;
  };
  
  const renderedRoutes = renderRoutesRecursion(routes);
  const router = createBrowserRouter([
    {
      loader: async () => {
        if (!authUser) {
          return redirect('/auth');
        }

        return null;
      },
      path: '/',
      element: <Root />,
      errorElement: !authUser
        ? <Navigate to="/auth/login" replace />
        : <PageNotFound />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.length ? routes[0].route : '/'} replace />,
        },
        ...renderedRoutes,
      ],
    },
    {
      loader: async () => {
        if (authUser) {
          return redirect('/');
        }

        return null;
      },
      path: '/auth',
      element: <Auth />,
      errorElement: <Navigate to="/auth/login" replace />,
      children: [
        {
          index: true,
          element: <Navigate to="/auth/login" replace />,
        },
        {
          path: '/auth/login',
          element: <Login />,
        },
        {
          path: '/auth/mobile',
          element: <Mobile />,
        },
        {
          path: '/auth/forgot-password',
          element: <ForgotPassword />,
        },
      ],
    },
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default AppRoutes;
