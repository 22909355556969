import axios from 'axios';
// Utils
import {API_URL} from '../../../../../../utils/urls';
// import {GeolocationResponse} from '@react-native-community/geolocation';
import {Dayjs} from 'dayjs';

// Types
import type {
  TimesheetProps,
  TimesheetLogProps,
  TimesheetLogChangeProps,
  TimesheetNoteProps,
} from '../../../../../../models/Timesheet';
import {ManagerTabProps} from '../../../../../../models/Employee';

// Constants
export const TIMECLOCK_API_URL = API_URL + '/mobile/home/timeclock';

interface ResponseGetTimeclock {
  message?: string;
  data: {
    clockedIn: boolean;
    hasEmployees: boolean;
    timesheet: TimesheetProps;
    timesheetLogs: TimesheetLogProps[];
    managerTasks: ManagerTabProps[];
    managerTasksCount: number;
    timesheetBoardCount: number;
  };
}

export function getTimeclock(
  screenOption?: string,
  // geoLocationData?: GeolocationResponse,
  taskStatusId?: number,
) {
  const params = {
    // lat: geoLocationData?.coords.latitude,
    // lng: geoLocationData?.coords.longitude,
    task_status_id: taskStatusId,
    screen_tab: screenOption,
  };

  return axios.get<ResponseGetTimeclock>(TIMECLOCK_API_URL, {params});
}

interface ResponseLogTimeclock {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLog: TimesheetLogProps;
    timesheetNote: TimesheetNoteProps;
  };
}

export function logTimeclock(
  // geoLocationData?: GeolocationResponse,
) {
  const requestBody = {};

  // if (geoLocationData) {
  //   requestBody = {
  //     lat: geoLocationData.coords.latitude,
  //     lng: geoLocationData.coords.longitude,
  //   };
  // }

  return axios.post<ResponseLogTimeclock>(TIMECLOCK_API_URL, requestBody);
}

interface ResponseGetTimesheetLogChangeRequest {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLog: TimesheetLogProps;
    timesheetLogChanges: TimesheetLogChangeProps[];
  };
}

export function getTimesheetLogChangeRequest(
  timesheet: TimesheetProps,
  timesheetLog: TimesheetLogProps,
) {
  return axios.get<ResponseGetTimesheetLogChangeRequest>(
    TIMECLOCK_API_URL +
      '/' +
      timesheet.id +
      '/timesheet-logs/' +
      timesheetLog.id +
      '/changes',
  );
}

interface ResponseSubmitChangeTimesheetLogRequest {
  message?: string;
  data: {
    timesheet: TimesheetProps;
    timesheetLog: TimesheetLogProps;
    timesheetLogChange: TimesheetLogChangeProps;
  };
}

export function submitChangeTimesheetLogRequest(
  timesheet: TimesheetProps,
  timesheetLog: TimesheetLogProps,
  time: Dayjs,
) {
  const requestBody = {
    timesheet_log_id: timesheetLog.id,
    to_time: time.format('HH:mm:ss'),
  };

  return axios.post<ResponseSubmitChangeTimesheetLogRequest>(
    TIMECLOCK_API_URL +
      '/' +
      timesheet.id +
      '/timesheet-logs/' +
      timesheetLog.id +
      '/changes',
    requestBody,
  );
}
