import {useContext, useState} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {Button, IconButton, InputAdornment, TextField} from '@mui/material';
import {Link} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';
import {isAxiosError} from 'axios';

// Types
import {AuthenticationProps} from '../../../models/Auth';

// Images
import FabricarLogo from '../../../assets/images/logos/fabricar-logo.png';

// APIs
import {getUserByToken, login} from './_api/auth';

// Context
import {AuthContext} from '../../global/auth/context/AuthContext';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Components
import {LoginTypeButtons} from './_components/LoginTypeButtons';

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Context
  const {saveAuth, setAuthUser, setWebAccess} = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const initialValues: AuthenticationProps = {
    // email: 'raven@styxmill.com',
    // password: 'Password@123',
    email: '',
    password: '',
  };

  // Handlers
  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="lg:flex justify-end">
      <div className="lg:w-1/2 p-5 lg:p-10">
        <div className="border rounded-xl shadow-md bg-[#ffffff]/[0.7] min-h-[90vh] h-full p-5 xl:w-[80%]">
          <img
            className="object-contain w-[50%] lg:w-[25%] md:w-[30%] block mx-auto mb-5"
            src={FabricarLogo}
            alt="fabricar-logo"
          />

          <h1 className="text-center text-[30px] font-bold text-[#2c63ba] mb-5">
            Hello
          </h1>

          <h1 className="font-thin text-[30px] text-center mb-10">
            Let&apos;s get started
          </h1>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (
              values: AuthenticationProps,
              {setErrors}
            ) => {
              try {
                const {status, data: {data}} = await login(values);
          
                if (status === 200) {
                  saveAuth(data);
                  const {status: userStatus, data: userData} = await getUserByToken(data.apiToken);
          
                  if (userStatus === 200) {
                    setAuthUser(userData.data.user);
                    setWebAccess(userData.data.webAccess);
                  }
                }
              } catch (err) {
                let message = 'Something went wrong, please contact your administrator.'
          
                if (isAxiosError(err)) {
                  const response = err.response;
          
                  if (response) {
                    message = response.data.message;
                    switch (response.status) {
                      case 401: {
                        setErrors({
                          email: 'Invalid email',
                          password: 'Invalid password',
                        });
                        break;
                      }
                    }
                  }
                }
          
                enqueueSnackbar(
                  message,
                  {
                    autoHideDuration: 3000,
                    variant: 'error',
                    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
                  },
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <Form className="md:mx-10 lg:mx-4">
                <div className="mb-5">
                  <TextField
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    placeholder="Email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={Boolean(touched.email && errors.email) && errors.email}
                  />
                </div>

                <div className="mb-5">
                  <TextField
                    type={showPassword ? 'default' : 'password'}
                    fullWidth
                    variant="outlined"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Password"
                    placeholder="Password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={Boolean(touched.password && errors.password) && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={showPasswordClickHandler}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <div className="flex justify-end">
                    <Link
                      to="/auth/forgot-password"
                      className="p-3 text-[#2c63ba] font-bold">
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  className="p-3 block bg-[#50C878]! font-bold text-[#ffffff] rounded-full w-full">
                  Login
                </Button>

                <LoginTypeButtons
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
