import {Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

// Images
import WebConstruction from '../../../assets/images/pictures/web-construction.png';

export const PageUnderDevelopment = () => {
  const navigate = useNavigate();

  const goBackClickHandler = () => {
    navigate(-1);
  };

  return (
    <div className="bg-[#ffffff] justify-center p-10 shadow-lg rounded border mx-5">
      <img
        src={WebConstruction}
        alt="page-under-construction"
        className="md:w-1/2 lg:w-[20%] mx-auto mb-5"
      />

      <div className="mb-5">
        <Typography className="text-center">
          This page is under development, please bear with us.
        </Typography>
      </div>

      <div className="flex justify-center">
        <Button variant="contained" onClick={goBackClickHandler}>
          Go Back
        </Button>
      </div>
    </div>
  );
};
