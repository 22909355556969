import axios from 'axios';
import {API_URL} from '../../../../../../utils/urls';

// Types
import {
  RawMaterialFormProps,
  LabourVariablesProps,
  OverheadsProps,
  ProductProps,
  ProductRawMaterialProps,
  RawMaterialProps,
} from '../../../../../../models/Product';
import {CurrencyProps} from '../../../../../../models/Currency';

// Constants
export const COSTINGS_API_URL = API_URL + '/mobile/home/costings';

interface ResponseGeCostingsScreenData {
  data: {
    products: ProductProps[];
    labourVariables: LabourVariablesProps;
    overheads: OverheadsProps;
    isAdmin: boolean;
  };
}

export function getCostingsScreenData() {
  return axios.get<ResponseGeCostingsScreenData>(COSTINGS_API_URL);
}

interface ResponseGetProduct {
  data: {
    product: ProductProps;
    labourVariables: LabourVariablesProps;
    rawMaterials: RawMaterialProps[];
    overheads: OverheadsProps;
  };
}

export function getProduct(productId: number) {
  return axios.get<ResponseGetProduct>(COSTINGS_API_URL + '/' + productId);
}

interface ResponseCreateProduct {
  message: string;
  data: {
    product: ProductProps;
  };
}

export const createProduct = (
  code: ProductProps['code'],
  name: ProductProps['name'],
) => {
  const requestBody = {code, name};

  return axios.post<ResponseCreateProduct>(COSTINGS_API_URL + '/create-product', requestBody);
}

interface ResponseUpdateProduct {
  message: string;
  data: {
    product: ProductProps;
  };
}

export const updateProduct = (
  product: ProductProps,
) => {
  const requestBody = {
    code: product.code,
    name: product.name,
  };

  return axios.patch<ResponseUpdateProduct>(COSTINGS_API_URL + '/' + product.id, requestBody);
}

interface ResponseDuplicateProduct {
  message: string;
  data: {
    product: ProductProps;
  };
}

export const duplicateProduct = (
  product: ProductProps,
) => {
  return axios.post<ResponseDuplicateProduct>(COSTINGS_API_URL + '/' + product.id + '/duplicate');
}

interface ResponseGetRawMaterial {
  data: {
    rawMaterials: RawMaterialProps[];
    currencies: CurrencyProps[];
  };
}

export const getRawMaterials = () => {
  return axios.get<ResponseGetRawMaterial>(COSTINGS_API_URL + '/raw-materials');
}

interface ResponseAddRawMaterial {
  data: {
    rawMaterial: ProductRawMaterialProps;
  };
}

export const addProductRawMaterial = (
  productId: number,
  rawMaterialId: number,
) => {
  const requestBody = {
    raw_material_id: rawMaterialId,
  };

  return axios.put<ResponseAddRawMaterial>(
    `${COSTINGS_API_URL}/${productId}/raw-materials`,
    requestBody,
  );
}

interface ResponseUpdateRawMaterial {
  message?: string;
  data: {
    rawMaterial: ProductRawMaterialProps;
  };
}

export const updateProductRawMaterial = (
  productId: number,
  rawMaterialId: number,
  qty: number,
) => {
  const requestBody = {
    raw_material_id: rawMaterialId,
    qty,
  };

  return axios.patch<ResponseUpdateRawMaterial>(
    `${COSTINGS_API_URL}/${productId}/raw-materials`,
    requestBody,
  );
}

interface ResponseDeleteRawMaterial {
  message?: string;
  data: {
    rawMaterial: RawMaterialProps;
  };
}

export const deleteProductRawMaterial = (
  productId: number,
  rawMaterialId: number,
) => {
  return axios.delete<ResponseDeleteRawMaterial>(
    `${COSTINGS_API_URL}/${productId}/raw-materials/${rawMaterialId}`,
  );
}

interface ResponseUpdateLabour {
  message?: string;
  data: {
    product: ProductProps;
  };
}

export const updateProductLabour = (
  productId: number,
  cuttingTime: number,
  sewingTime: number,
  seamSealTime: number,
  warehouseTime: number,
) => {
  const requestBody = {
    cutting_time: cuttingTime,    
    sewing_time: sewingTime,    
    seam_seal_time: seamSealTime,    
    warehouse_time: warehouseTime,    
  };

  return axios.patch<ResponseUpdateLabour>(
    `${COSTINGS_API_URL}/${productId}/labour`,
    requestBody,
  );
}

interface ResponseSubmitRawMaterialForm {
  message: string;
  data: {
    rawMaterial: RawMaterialProps;
  };
}

export const submitRawMaterialForm = (rawMaterial: RawMaterialFormProps) => {
  const requestBody = {
    id: rawMaterial.id,
    code: rawMaterial.code,
    name: rawMaterial.name,
    cost: rawMaterial.cost,
    currency_id: rawMaterial.currencyId,
    rate: rawMaterial.rate,
    fx_freight: rawMaterial.fxFreight,
    fx_net_price: rawMaterial.fxNetPrice,
    supplier: rawMaterial.supplier,
    is_local_data: rawMaterial.isLocalData,
    is_cost_computed: rawMaterial.isCostComputed,
    material_usage_supplier_code: rawMaterial.materialUsageSupplierCode,
    material_usage_trigger_qty: rawMaterial.materialUsageTriggerQty,
    material_usage_order_qty: rawMaterial.materialUsageOrderQty,
    material_usage_lead_time: rawMaterial.materialUsageLeadTime,
    material_usage_lead_time_unit: rawMaterial.materialUsageLeadTimeUnit,
    material_usage_kanban: rawMaterial.materialUsageKanban,
  };

  return axios.post<ResponseSubmitRawMaterialForm>(COSTINGS_API_URL + '/raw-materials', requestBody);
}

interface ResponseGenerateProductReportEmail {
  message: string;
  data: {
    rawMaterial: RawMaterialProps;
  };
}

export const generateProductReportEmail = (rawMaterial: ProductProps, email: string) => {
  const requestBody = {email};

  return axios.post<ResponseGenerateProductReportEmail>(`${COSTINGS_API_URL}/${rawMaterial.id}/report`, requestBody);
}
