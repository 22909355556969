import axios from 'axios';
// Utils
import {API_URL} from '../../../../../../../utils/urls';
import dayjs from 'dayjs';

// Types
import {TimesheetBoardProps} from '../../../../../../../models/Timesheet';

interface ResponseGetTimesheetBoard {
  data: {
    timesheetBoard: TimesheetBoardProps[];
  };
}

export function getTimesheetBoard(date = dayjs()) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  };

  return axios.get<ResponseGetTimesheetBoard>(
    API_URL + '/mobile/home/timeclock/timesheet-board',
    {params},
  );
}
