// Context
import AdministratorContextProvider from './_context/AdministratorContext';

// Components
import AdministratorContent from './AdministratorContents';

const Administrator = () => {
  return (
    <AdministratorContextProvider>
      <AdministratorContent />
    </AdministratorContextProvider>
  );
};

export default Administrator;
