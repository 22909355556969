import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {enqueueSnackbar} from 'notistack';

// Types
import {NotificationPropsContent} from '../../../../models/Notification';
import {IntegratedTimesheetLogProps} from '../../../../models/Timesheet';

// Context
import {AuthContext} from '../../../global/auth/context/AuthContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import EventIcon from '@mui/icons-material/Event';
import ChatIcon from '@mui/icons-material/Chat';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

// Components
import {LoadingFetchData} from '../../../global/_components/Loading';

// Utils
import {
  STATUS_ID_APPROVED,
  STATUS_ID_CANCELLED,
  STATUS_ID_COMPLETED,
} from '../../../../utils/constants';
import {diffForHumans} from '../../../../utils/utils';

// APIs
import {getNotifications} from '../../_api/mobile/notifications/notifications';

// Styles
import {ColorPalette} from '../../../../styles/GeneralStyles';

const Notifications = () => {
  const [init, setInit] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationPropsContent[]>([]);
  const [seletectedNotification, setSelectedNotification] = useState<NotificationPropsContent>();

  // Context
  const {authUser, setNotificationsCount} = useContext(AuthContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getNotifications();

      if (status === 200) {
        setNotifications(data.data.notifications);
        setNotificationsCount(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [setNotificationsCount]);

  const refreshHandler = useCallback(async () => {
    setRefreshing(true);
    try {
      await fetchData(false);
    } finally {
      setRefreshing(false);
    }
  }, [fetchData]);

  useEffect(() => {
    setInit(false);
    fetchData(true);
  }, [fetchData]);

  if (!init) {
    return <LoadingFetchData />;
  }

  return (
    <div className="bg-[#ffffff]/[0.8] md:mx-10 mx-3 p-2 md:p-5 shadow-lg rounded-md border grid gap-2">
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2">
        <div className="bg-[#ffffff] border shadow rounded-lg max-h-[1000px] overflow-hidden hover:overflow-y-scroll">
          <div className="p-2 md:p-5">
            <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
              <div className="flex flex-row gap-2">
                <Typography fontSize={20} fontWeight="bold">
                  Notifications
                </Typography>

                {Boolean(authUser?.id) && (
                  <button
                    type="button"
                    disabled={refreshing}
                    onClick={refreshHandler}>
                    {refreshing ? (
                      <CircularProgress size={12} />
                    ) : (
                      <RefreshIcon color="primary" fontSize="small" />
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-3">
              {notifications.map(indivNotification => {
                let selectedClass = indivNotification.id === seletectedNotification?.id
                  ? 'border-green-500 border'
                  : 'hover:border-green-500 border';

                return (
                  <div
                    key={indivNotification.id}
                    className={`rounded cursor-pointer ${selectedClass}`}
                    onClick={() => {
                      if ([1, 7].includes(indivNotification.notificationTypeId)) {
                        setSelectedNotification(indivNotification);
                      } else {
                        enqueueSnackbar(
                          'Notification cannot be displayed.',
                          {
                            autoHideDuration: 3000,
                            variant: 'warning',
                            anchorOrigin: {
                              horizontal: 'right',
                              vertical: 'bottom',
                            },
                          },
                        );
                      }
                    }}>
                    <NotificationComponent notification={indivNotification} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="bg-[#ffffff] h-full lg:col-span-3 border shadow rounded-lg max-h-[1000px]">
          <div className="p-2 md:p-5">
            {seletectedNotification ? (
              <Fragment>
                <div className="flex flex-row md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
                  <div className="flex flex-row gap-2">
                    <Typography fontSize={20} fontWeight="bold">
                      {seletectedNotification.title}
                    </Typography>
                  </div>
                </div>

                <SelectedNotificationComponent notification={seletectedNotification} />
              </Fragment>
            ) : (
              <Typography className="text-gray-500">
                Please select a notification
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface NotificationComponentProps {
  notification: NotificationPropsContent;
}

const NotificationComponent = ({notification}: NotificationComponentProps) => {
  const notificationDateTime = dayjs(notification.createdAt);
  const notificationDiffForHumans =
    diffForHumans(notificationDateTime);

  let statusColor = 'text-primary-100';

  switch (notification.notificationTypeId) {
    case 1:
    case 2:
    case 7: {
      switch (notification.content.status?.id) {
        case STATUS_ID_COMPLETED:
        case STATUS_ID_APPROVED: {
          statusColor = 'text-green-500';
          break;
        }
        case STATUS_ID_CANCELLED: {
          statusColor = 'text-red-100';
          break;
        }
      }
    }
  }

  switch (notification.notificationTypeId) {
    // Timesheet log change
    case 1: {
      const timesheetDateTime = dayjs(
        notification.timesheet.createdAt,
      );
      const fromTime = dayjs(
        notification.content.fromTime,
        'HH:mm:ss',
      );
      const toTime = dayjs(
        notification.content.toTime,
        'HH:mm:ss',
      );

      return (
        <div className="mb-3 rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row gap-2">
            <Typography color="#35b535" fontSize={40}>
              <AvTimerIcon fontSize='inherit' />
            </Typography>

            <div className="flex flex-col gap-1 flex-1">
              <div className="border-b-[0.5px] pb-2">
                <div className="flex flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant='caption' className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>

              <div className="flex flex-row gap-3">
                <Typography className="mx-1 leading-6">&bull;</Typography>
                <div>
                  {notification.content.fromTime ? (
                    <Typography className="leading-6">
                      <span>
                        Change from{' '}
                        <span className="font-bold">
                          {fromTime.format('hh:mm A ')}
                        </span>
                      </span>
                      <span>
                        to{' '}
                        <span className="font-bold">
                          {toTime.format('hh:mm A ')}
                        </span>
                      </span>
                    </Typography>
                  ) : (
                    <Typography>
                      Clock-out @{' '}
                      <span className="font-bold">
                        {toTime.format('hh:mm A ')}
                      </span>
                    </Typography>
                  )}

                  <span>
                    for
                    <span className="font-bold">
                      {timesheetDateTime.format(
                        ' ddd MMM DD, YYYY. ',
                      )}
                    </span>
                  </span>
                </div>
              </div>

              <Typography>
                <span className="font-bold">Status: </span>
                <span className={statusColor}>
                  {notification.content.status.name}
                </span>
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // Leave request date
    case 2: {
      const leaveRequestDate = dayjs(
        notification.content.date,
      );
      const startTime = dayjs(
        notification.content.startTime,
        'HH:mm:ss',
      );
      const endTime = dayjs(
        notification.content.endTime,
        'HH:mm:ss',
      );

      return (
        <div className="rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography color={ColorPalette.Secondary} fontSize={40}>
                <EventIcon fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex flex-1 flex-col gap-1">
              <div className="border-b-[0.5px] pb-2">
                <div className="flex flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant="caption" className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>

              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">Type: </Typography>
                <Typography>
                  {notification.content.leaveType?.name}
                </Typography>
              </div>

              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">Date: </Typography>
                <Typography>
                  {leaveRequestDate.format('ddd MMM. DD, YYYY')}
                </Typography>
              </div>

              <div className="flex flex-row gap-2">
                <Typography fontWeight="bold">Time: </Typography>
                <Typography>
                  {startTime.format('hh:mm A')}
                  {' - '}
                  {endTime.format('hh:mm A')}
                </Typography>
              </div>

              <Typography className="flex flex-row gap-2">
                <Typography fontWeight="bold">Status: </Typography>
                <Typography className={statusColor}>
                  {notification.content.status?.name}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // Chat
    case 3: {
      return (
        <div className="rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography fontSize={40}>
                <ChatIcon htmlColor="#35b535" fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex flex-col flex-1">
              <div className="border-b-[0.5px] pb-2">
                <div className="flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant='caption' className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>
              <Typography className="leading-6">
                {notification.description}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // App Update
    case 4: {
      return (
        <div className="rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography fontSize={40}>
                <SystemUpdateIcon htmlColor={ColorPalette.Primary} fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex flex-col flex-1">
              <div className="border-b-[0.5px] mb-3">
                <div className="flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant="caption" className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>
              <Typography className="leading-6 whitespace-pre-wrap">
                {notification.content.remarks}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // Missing logs
    case 5: {
      return (
        <div className="mb-3 rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography fontSize={40}>
                <AvTimerIcon htmlColor="#cd5c5c" fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex flex-col flex-1">
              <div className="border-b-[0.5px] mb-3">
                <div className="flex flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant="caption" className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>
              <Typography className="leading-6">
                {notification.description}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // Staff Assignment
    case 6: {
      return (
        <div className="rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography fontSize={40}>
                <AssignmentIndIcon fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex-1">
              <div className="border-b-[0.5px] mb-3">
                <div className="flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant='caption' className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>
              <Typography className="leading-6">
                {notification.description}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    // Safety Report
    case 7: {
      return (
        <div className="rounded">
          <div className="bg-[#ffffff] rounded p-3 flex flex-row">
            <div className="w-[50px]">
              <Typography fontSize={40}>
                <MedicalServicesIcon htmlColor="#8b8000" fontSize='inherit' />
              </Typography>
            </div>

            <div className="flex flex-col flex-1">
              <div className="border-b-[0.5px] mb-3">
                <div className="flex-row justify-between">
                  <Typography fontWeight="bold">
                    {notification.title}
                  </Typography>

                  {!notification.isSeen && (
                    <div className="w-[10px] h-[10px] bg-primary-100 rounded-full" />
                  )}
                </div>
                <Typography variant="caption" className="text-gray-500">
                  {notificationDiffForHumans}
                </Typography>
              </div>

              <div className="mb-2">
                <Typography fontWeight="bold">Report: </Typography>
                <Typography>
                  {notification.content.report}
                </Typography>
              </div>

              <div className="flex flex-row gap-1">
                <Typography fontWeight="bold">Status: </Typography>
                <Typography className={statusColor}>
                  {notification.content.status.id ===
                  STATUS_ID_COMPLETED
                    ? 'Resolved'
                    : notification.content.status.name}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

const SelectedNotificationComponent = ({notification}: NotificationComponentProps) => {
  switch (notification.notificationTypeId) {
    case 1: {
      let currentTimesheetLogsTotal = 0;
      const timesheetDateTime = dayjs(notification.timesheet.createdAt);
      const fromTime = dayjs(
        notification.content.fromTime,
        'HH:mm:ss',
      );
      const toTime = dayjs(
        notification.content.toTime,
        'HH:mm:ss',
      );
      const timeStringFormat = 'hh:mm A';
      let integratedTimesheetLogs: IntegratedTimesheetLogProps[] = [];

      notification.timesheet.timesheetLogs.forEach(indivTimesheetLog => {
        switch (indivTimesheetLog.type) {
          case 'CLOCK-IN': {
            integratedTimesheetLogs.push({clockIn: indivTimesheetLog});
            break;
          }
          case 'CLOCK-OUT': {
            const latestTimesheetLog =
              integratedTimesheetLogs[integratedTimesheetLogs.length - 1];
            latestTimesheetLog.clockOut = indivTimesheetLog;
            break;
          }
        }
      });

      return (
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-2">
            <Typography className="mx-1 leading-6">&bull;</Typography>
            <div>
              {notification.content.fromTime ? (
                <span className="leading-6">
                  <span>
                    Change from{' '}
                    <span className="font-bold">
                      {fromTime.format('hh:mm A ')}
                    </span>
                  </span>
                  <span>
                    to{' '}
                    <span className="font-bold">
                      {toTime.format('hh:mm A ')}
                    </span>
                  </span>
                </span>
              ) : (
                <span>
                  Clock-out @{' '}
                  <span className="font-bold">
                    {toTime.format('hh:mm A ')}
                  </span>
                </span>
              )}

              <span>
                for
                <span className="font-bold">
                  {timesheetDateTime.format(
                    ' dddd MMMM DD, YYYY. ',
                  )}
                </span>
              </span>
            </div>
          </div>

          <div className="flex flex-row gap-2">
            <Typography fontWeight="bold">
              Date:
            </Typography>
            <Typography>
              {timesheetDateTime.format('dddd MMMM DD, YYYY')}
            </Typography>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Clock-in
                  </TableCell>
                  <TableCell>
                    Clock-out
                  </TableCell>
                  <TableCell>
                    No. of hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integratedTimesheetLogs.map(indivTimesheetLog => {
                  const clockIn = dayjs(indivTimesheetLog.clockIn.time, 'HH:mm:ss');
                  const clockOut = dayjs(
                    indivTimesheetLog.clockOut?.time,
                    'HH:mm:ss',
                  );
                  const clockedHours = (clockOut.unix() - clockIn.unix()) / 3600;
                  if (indivTimesheetLog.clockOut) {
                    currentTimesheetLogsTotal += clockedHours;
                  }
                  const clockInString = clockIn.format(timeStringFormat);
                  const clockOutString = indivTimesheetLog.clockOut
                    ? clockOut.format(timeStringFormat)
                    : '-';

                  return (
                    <Fragment>
                      <TableRow>
                        <TableCell>
                          {clockInString}
                        </TableCell>
                        <TableCell>
                          {clockOutString}
                        </TableCell>
                        <TableCell>
                          {clockedHours.toFixed(2)} hours
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell>
                          <span className="font-bold">
                            Total: {currentTimesheetLogsTotal.toFixed(2)} hrs
                          </span>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }

    case 7: {
      const safetyReport = notification.content;
      const safetyReportResponses = safetyReport.safetyReportResponses ?? [];
      const safetyReportDateTime = dayjs(safetyReport.createdAt);
      let statusText = safetyReport.status?.name;
      let statusTextColor = 'text-blue-500';

      switch (safetyReport.status?.id) {
        case STATUS_ID_COMPLETED: {
          statusText = 'Resolved';
          statusTextColor = 'text-green-500';
          break;
        }
        case STATUS_ID_CANCELLED: {
          statusTextColor = 'text-red-500';
          break;
        }
      }

      return (
        <div className="p-2 md:p-5">
          <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3">
            <div className="flex flex-row gap-2">
              <Typography fontSize={20} fontWeight="bold">
                Incident report responses
              </Typography>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-2">
              <Typography fontWeight="bold">
                Date:
              </Typography>
              <Typography>
                {safetyReportDateTime.format('ddd MMM. DD, YYYY - hh:mm A')}
              </Typography>
            </div>

            <div className="flex flex-row gap-2">
              <Typography fontWeight="bold">
                Status:
              </Typography>
              <Typography className={`!font-bold ${statusTextColor}`}>
                {statusText}
              </Typography>
            </div>

            <div className="flex flex-row gap-2">
              <Typography fontWeight="bold">
                Sender:
              </Typography>
              <Typography>
                {safetyReport.user.firstname} {safetyReport.user.lastname}
              </Typography>
            </div>

            <div className="border-b pb-3">
              <div className="flex flex-col gap-2">
                <Typography fontWeight="bold">
                  Report:
                </Typography>
                <Typography>
                  {safetyReport.report}
                </Typography>
              </div>
            </div>
            
            <Typography fontWeight="bold">
              Comments:
            </Typography>

            {safetyReportResponses.length ? safetyReportResponses.map(indivResponse => {
              const safetResponseDateTime = dayjs(indivResponse.createdAt);

              return (
                <div key={indivResponse.id}>
                  <Typography>
                    {indivResponse.response}
                  </Typography>
                  <Typography variant="caption" className="text-gray-500">
                    {indivResponse.user?.firstname} {indivResponse.user?.lastname} - {safetResponseDateTime.format('ddd MMM. DD, YYYY @ hh:mm A')}
                  </Typography>
                </div>
              );
            }) : (
              <Typography variant="caption" className="text-gray-500">
                No response yet.
              </Typography>
            )}
          </div>
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default Notifications;
