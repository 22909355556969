import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {enqueueSnackbar} from 'notistack';

// Types
import {UserFormType} from '../AdministratorContents';

// Context
import {AuthContext} from '../../../../../global/auth/context/AuthContext';
import {AdministratorContext} from '../_context/AdministratorContext';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WorkIcon from '@mui/icons-material/Work';

// Components
import {LoadingFetchData} from '../../../../../global/_components/Loading';

// APIs
import {
  getHomeAdministratorUserData,
  logoutAdministratorUser,
  setAdministratorUserAccess,
} from '../../../../_api/mobile/home/administrator/administrator';

// Utils
import {diffForHumans} from '../../../../../../utils/utils';

type AccessEventType = 'logout' | 'block';
interface UserProfileComponentProps {
  handleToggleUserFormDialog: (type: UserFormType) => void;
}

const UserProfile = ({handleToggleUserFormDialog}: UserProfileComponentProps) => {
  const [init, setInit] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [accessEventType, setAccessEventType] = useState<AccessEventType>('logout');
  const [openUserAccessDialog, setOpenUserAccessDialog] = useState<boolean>(false);

  // Context
  const {authUser} = useContext(AuthContext);
  const {
    selectedUserId,
    users,
    setUsers,
    user,
    setUser,
  } = useContext(AdministratorContext);

  // Handlers
  const fetchData = useCallback(async (initialize: boolean) => {
    try {
      const {status, data} = await getHomeAdministratorUserData(selectedUserId!);

      switch (status) {
        case 200: {
          setUser(data.data.user);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (initialize) {
        setInit(true);
      }
    }
  }, [selectedUserId, setUser]);

  const refreshHandler = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchData(false);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchData]);

  const handleOpenUserAccessDialog = (eventType: AccessEventType) => {
    setAccessEventType(eventType);
    setOpenUserAccessDialog(true);
  };

  const handleCloseUserAccessDialog = () => {
    setOpenUserAccessDialog(false);
    setAccessEventType('logout');
  };

  const handleSubmitUserAccessEvent = async () => {
    if (user) {
      setIsSubmitting(true);
      try {
        const {status, data} = accessEventType === 'block'
          ? await setAdministratorUserAccess(user)
          : await logoutAdministratorUser(user);

        switch (status) {
          case 200: {
            const newUser = data.data.user;
            setUser(newUser);
            const newUsers = [...users];
            const userIndex = newUsers.findIndex(indivUser => indivUser.id === newUser.id);
            newUsers.splice(userIndex, 1, newUser);
            setUsers(newUsers);
            setOpenUserAccessDialog(false);

            enqueueSnackbar(
              'Changes applied.',
              {
                autoHideDuration: 3000,
                variant: 'warning',
                anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
              },
            );
            break;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (user?.id !== selectedUserId) {
      setInit(false);
    }
  }, [user, selectedUserId]);

  if (!init) {
    return <LoadingFetchData />;
  }

  const statusColor = user?.lastActive
    ? 'text-green-500'
    : 'text-red-500';
  const statusText = user?.lastActive
    ? 'Active'
    : 'Logged-out';

  return (
    <Fragment>
      <div className="p-2 md:p-5">
        <div className="flex flex-col md:flex-row justify-between md:items-center border-b border-[#000000] pb-1 mb-3 gap-3">
          <div className="flex flex-row gap-2">
            {user ? (
              <Typography fontSize={20} fontWeight="bold">
                <PersonIcon fontSize="inherit" />
                {' '}
                {`${user.firstname} ${user.lastname}`}
              </Typography>
            ) : (
              <Typography fontSize={20} fontWeight="bold">
                User Profile
              </Typography>
            )}
            
            {Boolean(authUser?.id) && (
              <button
                type="button"
                disabled={isRefreshing}
                onClick={refreshHandler}>
                {isRefreshing ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon color="primary" fontSize="small" />
                )}
              </button>
            )}
          </div>
        </div>

        {user ? (
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col gap-3">
              <Typography>
                <WorkIcon fontSize="medium" />
                {' '}
                {user.role}
              </Typography>

              <Typography>
                <EmailIcon fontSize="medium" />
                {' '}
                {user.email}
              </Typography>

              <Typography>
                <PhoneIcon fontSize="medium" />
                {' '}
                {`+${user.mobileNumber}`}
              </Typography>

              <Typography>
                <LocalAtmIcon fontSize="medium" />
                {' Staff Credit: '}
                {`$ ${user.staffCredit.toFixed(2)}`}
              </Typography>

              <Typography>
                <SupervisorAccountIcon fontSize="medium" />
                <span className="font-bold"> Reports to: </span>
                {user.superior}
              </Typography>


              <Typography>
                <SupervisorAccountIcon fontSize="medium" />
                <span className="font-bold"> Last activity: </span>
                {user.lastActive
                  ? diffForHumans(dayjs(user.lastActive!))
                  : ''}
                <span className={statusColor}>
                  {' '}
                  ({user.isBlocked ? 'User Blocked' : statusText})
                </span>
              </Typography>
            </div>

            <div className="border rounded-lg p-2 flex flex-col gap-3 justify-center">
              <Button
                variant="contained"
                onClick={() => {
                  handleToggleUserFormDialog('edit');
                }}>
                Edit
              </Button>

              <Button
                variant="contained"
                color={user.isBlocked ? 'primary' : 'error'}
                onClick={() => {
                  handleOpenUserAccessDialog('block');
                }}>
                {user.isBlocked ? 'Unblock User' : 'Block & Logout'}
              </Button>

              <Button
                color="error"
                onClick={() => {
                  handleOpenUserAccessDialog('logout');
                }}>
                Logout
              </Button>
            </div>
          </div>
          
        ) : (
          <Typography>
            Invalid User.
          </Typography>
        )}
      </div>

      <Dialog
        fullWidth
        disableScrollLock={true}
        onClose={handleCloseUserAccessDialog}
        open={openUserAccessDialog}>
        <DialogTitle>User Access</DialogTitle>
        <DialogContent>
          Set {user?.firstname} {user?.lastname} access
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={handleCloseUserAccessDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isSubmitting}
            color={accessEventType === 'block' && user?.isBlocked ? 'primary' : 'error'}
            onClick={handleSubmitUserAccessEvent}>
            {accessEventType === 'logout'
              ? 'Logout'
              : accessEventType === 'block' && user?.isBlocked
              ? 'Unblock User'
              : 'Block and Logout'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UserProfile;
